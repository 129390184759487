import React from 'react';
import block from 'bem-cn-lite';
import {JupytSpecletOptions} from './types';
import type {DeepPartial, FormApi} from '@gravity-ui/dialog-fields';
import {
    DialogField,
    DialogTabField,
    YTDFDialog,
} from '@ytsaurus-ui-platform/src/ui/components/Dialog';
import './JupytSpecletDialog.scss';

type FormValues = JupytSpecletOptions;

export type JupytSpecletDialogField = DialogTabField<DialogField<FormValues>>;

export type JupytSpecletDialogInitialValues = DeepPartial<FormValues>;

export type JupytSpecletDialogProps = {
    visible: boolean;
    onClose: () => void;
    onApply: (form: FormApi<FormValues, DeepPartial<FormValues>>) => Promise<
        | {
              validationErrors?: Record<string, any>;
          }
        | undefined
        | void
    >;
    fields: JupytSpecletDialogField[];
    initialValues: JupytSpecletDialogInitialValues;
    title: string;
};

const b = block('jupyt-speclet-dialog');

export const JupytSpecletDialog: React.FC<JupytSpecletDialogProps> = (
    props: JupytSpecletDialogProps,
) => {
    const {visible, onClose, onApply, fields, initialValues, title} = props;

    const headerProps = React.useMemo(() => {
        return {
            title,
        };
    }, [title]);

    return (
        <YTDFDialog<FormValues>
            size="l"
            visible={visible}
            onClose={onClose}
            className={b()}
            // @ts-expect-error
            onAdd={onApply}
            fields={fields}
            // @ts-expect-error
            initialValues={initialValues}
            headerProps={headerProps}
        />
    );
};
