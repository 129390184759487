export type LinkBuilder = (segment: string) => string;

export enum DockerMediaType {
    /** OCI media type */
    OCI_MANIFEST = 'application/vnd.oci.image.manifest.v1+json',
    DOCKER_MANIFEST = 'application/vnd.docker.distribution.manifest.v2+json',
    DOCKER_MANIFEST_LIST = 'application/vnd.docker.distribution.manifest.list.v2+json',
    OCI_INDEX = 'application/vnd.oci.image.index.v1+json',

    /** Config media type */
    OCI_CONFIG = 'application/vnd.oci.image.config.v1+json',
    DOCKER_CONFIG = 'application/vnd.docker.container.image.v1+json',

    /** Layer media type */
    OCI_LAYER = 'application/vnd.oci.image.layer.v1.tar+gzip',
    OCI_LAYER_NONDISTRIBUTABLE = 'application/vnd.oci.image.layer.nondistributable.v1.tar+gzip',
    DOCKER_LAYER = 'application/vnd.docker.image.rootfs.diff.tar.gzip',
    DOCKER_LAYER_NONDISTRIBUTABLE = 'application/vnd.docker.image.rootfs.foreign.diff.tar.gzip',

    /** attestation media type */
    ATTESTATION_MANIFEST = 'application/vnd.in-toto.attestation.v1',
    SIGNATURE_MANIFEST = 'application/vnd.docker.container.image.v1+json',

    /** Other docker media types */
    PLAINTEXT_LAYER = 'application/vnd.oci.image.layer.v1.tar',
    PLAINTEXT_LAYER_NONDISTRIBUTABLE = 'application/vnd.oci.image.layer.nondistributable.v1.tar',
}
