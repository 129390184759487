import type {BlueprintType} from '../types/blueprint';

export const DEFAULT_BLUEPRINT: BlueprintType = {
    content: [],
    files: [],
    registry_auth: [],
    docker_params: {
        image: {
            name: '',
            registry: '',
        },
        build_args: '',
    },
    build_params: {
        cpu_limit: 0,
        memory_limit: 0,
    },
};
