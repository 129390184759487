import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {SecretListItem} from 'components/SecretsList/SecretsList';

export type SecretItem = {
    name: string;
    value: string;
};

type DelegationsInitialState = {
    initialSecrets: SecretItem[];
    secrets: SecretListItem[];
};

const initialState: DelegationsInitialState = {
    initialSecrets: [],
    secrets: [],
};

export const secretsSlice = createSlice({
    name: 'secretStore.delegations-slice',
    initialState,
    reducers: {
        setInitialSecrets: (state, action: PayloadAction<{secrets: SecretItem[]}>) => {
            state.initialSecrets = action.payload.secrets;
        },
        setSecrets: (state, action: PayloadAction<{secrets: SecretListItem[]}>) => {
            state.secrets = action.payload.secrets;
        },
    },
});
