import {SecretListItem} from 'components/SecretsList/SecretsList';
import React, {ComponentProps, useCallback, useEffect, useState} from 'react';
import {SecretStoreTabComponent} from 'features/SecretStore/components/SecretStoreTabComponent/SecretStoreTabComponent';
import {useTractoDispatch} from '../../../../store/tracto-dispatch';
import {
    loadSecretsFromCypress,
    saveSecretsToCypress,
} from 'features/SecretStore/store/actions/secrets';
import {useSelector} from 'react-redux';
import {selectIsSaveDisabled, selectSecrets} from 'features/SecretStore/store/selectors/secrets';
import {secretsSlice} from 'features/SecretStore/store/slices/secretsSlice';
import {delegationsSlice} from 'features/SecretStore/store/slices/delegationsSlice';
import {loadDelegationsFromCypress} from 'features/SecretStore/store/actions/delegations';
import {selectIsDelegationAvailable} from 'features/SecretStore/store/selectors/delegations';
import {useHotkey} from 'hooks/useHotkey';

export const SecretStoreTabContainer: React.FC = () => {
    const dispatch = useTractoDispatch();
    const secrets = useSelector(selectSecrets);
    const isDelegationsAvailable = useSelector(selectIsDelegationAvailable);
    const [loading, setLoading] = useState(true);
    const [isSecretsSavingInProgress, setIsSaving] = useState(false);
    const isSaveDisabled = useSelector(selectIsSaveDisabled);

    useEffect(() => {
        (async () => {
            await Promise.all([
                dispatch(loadSecretsFromCypress()),
                isDelegationsAvailable ? dispatch(loadDelegationsFromCypress()) : Promise.resolve(),
            ]);
            setLoading(false);
        })();
    }, [isDelegationsAvailable]);

    const onUpdate = useCallback((value: SecretListItem[]) => {
        dispatch(secretsSlice.actions.setSecrets({secrets: value}));
    }, []);

    const onSaveClick = useCallback(async () => {
        if (isSaveDisabled) {
            return;
        }

        setIsSaving(true);
        try {
            await dispatch(saveSecretsToCypress());
        } finally {
            setIsSaving(false);
        }
    }, [isSaveDisabled]);

    useHotkey({
        keys: 'control+s,command+s',
        handler: (event) => {
            event.preventDefault();

            onSaveClick();
        },
    });

    const onCreateDelegationClick = useCallback(() => {
        dispatch(delegationsSlice.actions.showAddDelegationDialog());
    }, []);

    const props: ComponentProps<typeof SecretStoreTabComponent> = {
        value: secrets,
        loading,
        onUpdate,
        onSaveClick,
        onCreateDelegationClick,
        isDelegationsAvailable,
        isSecretsSavingInProgress,
        isSaveDisabled,
    };

    return <SecretStoreTabComponent {...props} />;
};
