import React from 'react';

import {withDefaultPoolTree} from '../../../../../hoc/withDefaultPoolTree';
import type {JupytSpecletUIFormConfig} from '../../../api/jupyt';
import type {
    JupytSpecletDialogContainerFieldFilters,
    JupytSpecletDialogContainerProps,
} from './types';
import type {JupytSpecletOptions} from '../../../components/Dialogs/JupytSpecletDialog/types';
import {useJupytSpecletDialogContainer} from './hooks/use-jupyt-speclet-dialog-container';
import {JupytSpecletDialog} from '../../../components/Dialogs/JupytSpecletDialog/JupytSpecletDialog';
import {selectJupyterSecretsList} from '../../../store/selectors/secrets';
import {useSelector} from 'react-redux';
import {selectDockerImageInitialValue} from './helpers/dialog-config-selectors';
import type {DeepPartial, FormApi} from '@gravity-ui/dialog-fields';
import {prepareFields} from './helpers/fields';
import _reduce from 'lodash/reduce';
import _isEqual from 'lodash/isEqual';
import {jupytOptionsEdit} from '../../../store/actions/jupyt/options';
import {useTractoDispatch} from '../../../../../store/tracto-dispatch';
import {loadJupytSecrets, setJupytSecrets} from '../../../store/actions/jupyt/secrets';
import {applyWithValidation} from './helpers/apply-validator';

type JupytSpecletEditDialogProps = JupytSpecletDialogContainerProps & {
    speclet: Record<string, any> & JupytSpecletUIFormConfig;
    alias: string;
};

const JupytSpecletEditDialogComponent: React.FC<JupytSpecletEditDialogProps> = (
    props: JupytSpecletEditDialogProps,
) => {
    const {speclet, defaultPoolTree, dialogConfig, onApply, alias} = props;

    const dispatch = useTractoDispatch();
    const secrets = useSelector(selectJupyterSecretsList);

    const initialGeneralOptions: Pick<
        JupytSpecletOptions['general'],
        'pool_trees' | 'jupyter_docker_image' | 'secrets' | 'yt_token'
    > = React.useMemo(() => {
        return {
            pool_trees: [defaultPoolTree],
            jupyter_docker_image: selectDockerImageInitialValue(
                dialogConfig,
                speclet.tracto_ui_form_config,
            ),
            secrets,
            yt_token: '',
            active: true,
        };
    }, [speclet.tracto_ui_form_config, defaultPoolTree, secrets, dialogConfig]);

    const fieldFilters: JupytSpecletDialogContainerFieldFilters = React.useMemo(() => {
        return {general: {alias: true, active: true}};
    }, []);

    const {visible, onClose, fields, initialValues, registry, username} =
        useJupytSpecletDialogContainer({
            dialogConfig,
            initialGeneralOptions,
            type: 'edit',
            fieldFilters,
        });

    const handleApply = React.useCallback(
        (form: FormApi<JupytSpecletOptions, DeepPartial<JupytSpecletOptions>>) => {
            const values = prepareFields(form.getState().values, {username, registry});

            const specletOptions = values.speclet_options;

            const diff = _reduce(
                specletOptions,
                (acc, value, key) => {
                    const oldValue = speclet[key as keyof typeof speclet];
                    const newValue = value;

                    if (!_isEqual(oldValue, newValue)) {
                        if (newValue !== undefined && newValue !== null && newValue !== '') {
                            acc[key] = newValue;
                        } else {
                            acc[key] = undefined;
                        }
                    }

                    return acc;
                },
                {} as Record<string, any>,
            );

            return applyWithValidation(values.speclet_options, () =>
                dispatch(jupytOptionsEdit({alias, options: diff}))
                    .unwrap()
                    .then(() => dispatch(setJupytSecrets({alias, secrets: values.secrets})))
                    .then(() => dispatch(loadJupytSecrets({alias})))
                    .then(onClose)
                    .then(onApply),
            );
        },
        [onApply, registry, username, speclet, alias, onClose],
    );

    return (
        <JupytSpecletDialog
            title="Edit speclet"
            visible={visible}
            onClose={onClose}
            fields={fields}
            onApply={handleApply}
            initialValues={initialValues}
        />
    );
};

export const JupytSpecletEditDialogContainer = withDefaultPoolTree(JupytSpecletEditDialogComponent);
