import * as React from 'react';
import block from 'bem-cn-lite';
import {Icon} from '@gravity-ui/uikit';
import folderSvg from '@gravity-ui/icons/svgs/folders.svg';
import filesSvg from '@gravity-ui/icons/svgs/file.svg';
import ElementsTable from '@ytsaurus-ui-platform/src/ui/components/ElementsTable/ElementsTable';
import {LEVEL_OFFSET} from '@ytsaurus-ui-platform/src/ui/components/StatisticTable';

import {bytesToSize, modeToString} from '../../utils';

import './TractoRegistryInspectorTree.scss';

type ColumnName = 'Permission' | 'size' | 'UID:GID' | 'name';
type VisibleColumns = Array<ColumnName>;

const b = block('tracto-registry-inspector-tree');

const prepareTableProps = ({visibleColumns}: {visibleColumns: VisibleColumns}) => {
    const columns = visibleColumns.reduce(
        (ret, col) => {
            ret[col] = {
                sort: false,
                align: 'left',
            };

            return ret;
        },
        {
            name: {
                sort: false,
                align: 'left',
            },
        } as Record<ColumnName | 'name', {sort: boolean; align: 'left' | 'right'}>,
    );

    return {
        theme: 'light',
        size: 's',
        striped: false,
        computeKey(item: any) {
            return item.name;
        },
        tree: true,
        columns: {
            sets: {
                default: {
                    items: ['Permission', 'UID:GID', 'size', 'name'],
                },
            },
            items: columns,
            mode: 'default',
        },
    };
};

const ExpandedItemCell: React.FC<{item: any; itemState: any; toggleItemState: any}> = ({
    item,
    itemState,
    toggleItemState,
}) => {
    const offsetStyle = React.useMemo(() => {
        return {paddingLeft: (item?.level || 0) * LEVEL_OFFSET};
    }, [item.level]);

    const toggleItemAndTreeState = React.useCallback(() => {
        if (!itemState.empty) {
            toggleItemState();
        }
    }, [itemState, toggleItemState]);

    if (item.isLeafNode) {
        const linkName = item.attributes?.value?.linkname;
        const name = item?.attributes?.name;

        return (
            <span className={b('node')} style={offsetStyle}>
                <Icon className={b('icon')} data={filesSvg} size={12} />
                {linkName ? (
                    <>
                        <span>{name}</span>
                        <span className={b('symlink')}>{` --> ${linkName}`}</span>
                    </>
                ) : (
                    <span>{`${name}`}</span>
                )}
            </span>
        );
    } else {
        return (
            <span
                className={b('node', {click: true})}
                style={offsetStyle}
                onClick={toggleItemAndTreeState}
            >
                <Icon className={b('icon')} data={folderSvg} size={12} />
                <span>{item?.attributes?.name}</span>
            </span>
        );
    }
};

const templates = {
    name(item: any, _: any, toggleItemState: any, itemState: any) {
        return (
            <ExpandedItemCell item={item} toggleItemState={toggleItemState} itemState={itemState} />
        );
    },
    __default__(item: any, columnName: ColumnName) {
        switch (columnName) {
            case 'size': {
                const size = item.attributes?.value?.size;

                if (size > 0) {
                    return <span>{bytesToSize(size)}</span>;
                }

                return 0;
            }
        }

        if (item.isLeafNode) {
            switch (columnName) {
                case 'UID:GID': {
                    const uid = item.attributes?.value?.uid;
                    const gid = item.attributes?.value?.gid;

                    return (
                        <span>
                            {uid}:{gid}
                        </span>
                    );
                }
                case 'Permission': {
                    return <span>{modeToString(item.attributes?.value?.mode)}</span>;
                }

                default: {
                    return <span>{item.name}</span>;
                }
            }
        }

        return null;
    },
};

interface TractoRegistryInspectorTreeProps {
    tree: any[];
}

export const TractoRegistryInspectorTree: React.FC<TractoRegistryInspectorTreeProps> = (props) => {
    return (
        <div className={b()}>
            <ElementsTable
                {...prepareTableProps({
                    visibleColumns: ['Permission', 'UID:GID', 'size', 'name'],
                })}
                items={props.tree}
                templates={templates}
                treeState={'mixed'}
            />
        </div>
    );
};
