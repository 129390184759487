import {useJupyterCellCommonDropdown} from '../../../JupyterCellControls/JupyterCellDropdown/use-common-dropdown';
import React, {useMemo} from 'react';
import {ChevronsCollapseUpRight, ChevronsExpandUpRight} from '@gravity-ui/icons';
import {notebookSlice} from '../../../../store/slices/notebook';
import {useTractoDispatch} from '../../../../../../store/tracto-dispatch';
import type {TractoNotebookCodeCell} from '../../../../types/version';
import type {BoundDropdownWithHotkeysItem} from '../../../../../../libs/hotkeys';
import type {JUPYTER_HOTKEYS} from '../../../../constants/hotkeys';

type UseJupyterPythonCellDropdownArgs = {
    cellId: TractoNotebookCodeCell['id'];
    options: {
        hasOutput: boolean;
        hiddenOutput: boolean;
        hiddenInput: boolean;
    };
};

export const useJupyterPythonCellDropdown = (
    args: UseJupyterPythonCellDropdownArgs,
): BoundDropdownWithHotkeysItem<typeof JUPYTER_HOTKEYS>[] => {
    const dispatch = useTractoDispatch();

    const {cellId, options} = args;

    const {hiddenInput, hiddenOutput, hasOutput} = options;

    const commonDropdownItems = useJupyterCellCommonDropdown({cellId});

    return useMemo((): BoundDropdownWithHotkeysItem<typeof JUPYTER_HOTKEYS>[] => {
        return [
            {
                group: true,
                label: 'Visibility',
                items: [
                    {
                        id: 'show-hide-input',
                        text: hiddenInput ? 'Show cell code' : 'Hide cell code',
                        iconStart: hiddenInput ? (
                            <ChevronsExpandUpRight />
                        ) : (
                            <ChevronsCollapseUpRight />
                        ),
                        action: () => {
                            dispatch(
                                notebookSlice.actions.setCellView({
                                    cellId,
                                    update: {
                                        type: 'python',
                                        payload: {hidden_input: !hiddenInput},
                                    },
                                }),
                            );
                        },
                    },
                    {
                        id: 'show-hide-output',
                        text: hiddenOutput ? 'Show cell output' : 'Hide cell output',
                        hidden: !hasOutput,
                        iconStart: hiddenOutput ? (
                            <ChevronsExpandUpRight />
                        ) : (
                            <ChevronsCollapseUpRight />
                        ),
                        action: () => {
                            dispatch(
                                notebookSlice.actions.setCellView({
                                    cellId,
                                    update: {
                                        type: 'python',
                                        payload: {hidden_output: !hiddenOutput},
                                    },
                                }),
                            );
                        },
                    },
                ],
            },
            ...commonDropdownItems,
        ];
    }, [commonDropdownItems, hasOutput, hiddenOutput, hiddenInput, cellId]);
};
