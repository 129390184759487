import React from 'react';
import {JupyterCellControlsLayout} from '../../../Layout/JupyterCellControlsLayout/JupyterCellControlsLayout';
import {useJupyterCellCommonDropdown} from '../../../JupyterCellControls/JupyterCellDropdown/use-common-dropdown';
import {JupyterCellCommonControlsContainer} from '../../../../containers/JupyterCellCommonControlsContainer/JupyterCellCommonControlsContainer';
import {SQLCellLeftControls} from './SQLCellLeftControls';

type SQLCellControlsProps = {
    cellId: string;
    cellIndex: number;
};

export const SQLCellControls: React.FC<SQLCellControlsProps> = React.memo(
    (props: SQLCellControlsProps) => {
        const {cellId, cellIndex} = props;

        const dropdownItems = useJupyterCellCommonDropdown({cellId});

        const renderLeftControls = React.useCallback(() => {
            return <SQLCellLeftControls cellId={cellId} />;
        }, [cellId]);

        const renderRightControls = React.useCallback(() => {
            return (
                <JupyterCellCommonControlsContainer
                    cellIndex={cellIndex}
                    dropdownItems={dropdownItems}
                />
            );
        }, [dropdownItems, cellIndex]);
        return (
            <JupyterCellControlsLayout
                renderLeftControls={renderLeftControls}
                renderRightControls={renderRightControls}
            />
        );
    },
);
