export const getAnchorHashCellId = (cellId: string) => {
    return `id_${cellId}`;
};

export const getAnchorHashCellIdSelector = (cellId: string) => {
    return `#${getAnchorHashCellId(cellId)}`;
};

export const getCellLink = (cellId: string) => {
    return new URL(
        `${window.location.pathname}${window.location.search}#${cellId}`,
        window.location.origin,
    ).toString();
};
