import React from 'react';
import {produce} from 'immer';
import {Icon} from '@gravity-ui/uikit';
import {LogoDocker} from '@gravity-ui/icons';
import {ExtraTab} from '@ytsaurus-ui-platform/src/ui/UIFactory';
import {Tab as NavigationTab} from '@ytsaurus-ui-platform/src/ui/constants/navigation';
import type {Node} from '@ytsaurus-ui-platform/src/ui/utils/navigation/content/map-nodes/node';
import type {RootState} from '@ytsaurus-ui-platform/src/ui/store/reducers';

import {isEnvTrue} from '../../../shared/utils/env';
import type {TractoRootState} from '../../store/reducers';
import {getTractoConfigData} from '../../config/ui-config';
import {TractoRegistryTab} from './components/TractoRegistryTab/TractoRegistryTab';
import {getTractoClusterUiConfig} from '../../store/selectors/tracto-cluster-ui-config';
import {TractoRegistryInspectorTab} from './components/TractoRegistryInspectorTab/TractoRegistryInspectorTab';

const IS_DOCKER_IMAGE_ATTRIBUTE = 'is_docker_image';

const isTractoRegistryNode = (attributes: Record<string, string | boolean> | undefined) =>
    Boolean(attributes?.[IS_DOCKER_IMAGE_ATTRIBUTE]);

export const TRACTO_REGISTRY_TAB: ExtraTab = {
    title: 'Registry',
    value: 'extra_image',
    text: 'Image',
    component: TractoRegistryTab,
    position: {before: NavigationTab.CONTENT},
    isSupported: (attributes) => isTractoRegistryNode(attributes),
    additionalAttributes: [IS_DOCKER_IMAGE_ATTRIBUTE],
    urlMapping: {
        params: {
            digest: {
                stateKey: 'tracto.tractoRegistry.image.searchParams.digest',
            },
        },
        getPreparedState(_state: RootState, {query: _query}: {query: RootState}) {
            const state = _state as TractoRootState;
            const query = _query as TractoRootState;

            return produce(state, (draft) => {
                if (
                    draft.tracto.tractoRegistry.image.searchParams.digest !==
                    query.tracto.tractoRegistry.image.searchParams.digest
                ) {
                    draft.tracto.tractoRegistry.image.searchParams.digest =
                        query.tracto.tractoRegistry.image.searchParams.digest;
                }
            });
        },
    },
};

export const TRACTO_REGISTRY_INSPECTOR_TAB: ExtraTab = {
    title: 'Inspector',
    value: 'extra_image_inspector',
    text: 'Inspector',
    component: TractoRegistryInspectorTab,
    position: {before: NavigationTab.CONTENT},
    isSupported: (attributes) => {
        return (
            isEnvTrue(getTractoConfigData().env.SHOW_TRACTO_REGISTRY_INSPECTOR) &&
            Boolean(
                getTractoClusterUiConfig(window.store.getState())?.registry_inspector_base_url,
            ) &&
            isTractoRegistryNode(attributes)
        );
    },
    additionalAttributes: [IS_DOCKER_IMAGE_ATTRIBUTE],
};

export const TRACTO_REGISTRY_NAVIGATION_MAP_NODE_SETTINGS = {
    additionalAttributes: ['key'],
    renderNodeIcon: (item: Node) => {
        if (isTractoRegistryNode(item.$attributes)) {
            return <Icon data={LogoDocker} size={14} />;
        }

        return null;
    },
};
