import * as React from 'react';
import {useSelector} from 'react-redux';
import {getCluster} from '@ytsaurus-ui-platform/src/ui/store/selectors/global';

import {fetchTags} from '../store/slices/tags';
import {selectify} from '../../../utils/select';
import {fetchImage} from '../store/slices/image';
import {inspectorSlice} from '../store/slices/inspector';
import {getSelectedManifestDigest} from '../store/selectors/inspector';
import {useTractoDispatch} from '../../../store/tracto-dispatch';
import {getRepository, getTags, isImageLoading} from '../store/selectors/repositories';

const useImageTagSelector = () => {
    const dispatch = useTractoDispatch();
    const tags = useSelector(getTags);
    const digest = useSelector(getSelectedManifestDigest);

    const options = tags?.reduce(
        (ret, item) => {
            const tag = item.tag;

            return ret.concat(
                item.manifests?.map((item) => {
                    return selectify({
                        content: `${tag} ${item.config.os}/${item.config.architecture}`,
                        value: item?.digest,
                    });
                }),
            );
        },
        [] as ReturnType<typeof selectify>[],
    );

    return {
        value: digest ? [digest] : [],
        onChange: (value: string[]) =>
            dispatch(inspectorSlice.actions.selectDigest({digest: value[0]})),
        options: options,
    };
};

export const useRegistryInspector = () => {
    const dispatch = useTractoDispatch();
    const cluster = useSelector(getCluster);
    const repository = useSelector(getRepository);
    const isLoading = useSelector(isImageLoading);

    const imageTagSelector = useImageTagSelector();

    React.useEffect(() => {
        dispatch(
            fetchImage({
                cluster,
                repository,
            }),
        )
            .unwrap()
            .then((image) => dispatch(fetchTags({cluster, image})));

        return () => {
            dispatch(inspectorSlice.actions.reset());
        };
    }, [dispatch, repository, cluster]);

    return {
        isLoading,
        repository,
        imageTagSelector,
    };
};
