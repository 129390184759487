import type {AxiosInstance} from 'axios';
import type {
    CreateRunRequestArgs,
    CreateRunSuccessfulResponse,
    GetLabelsRequestArgs,
    GetLabelsSuccessfulResponse,
    GetRunRequestArgs,
    GetRunSuccessfulResponse,
    ListRunsRequestArgs,
    ListRunsSuccessfulResponse,
    RestartRunRequestArgs,
    RestartRunSuccessfulResponse,
    StopRunRequestArgs,
    StopRunSuccessfulResponse,
} from './types';
import type {CommonRequestApiArgs} from '../types';

export class RunsApi {
    private client: AxiosInstance;

    constructor(client: AxiosInstance) {
        this.client = client;
    }

    async listRuns(
        args: ListRunsRequestArgs & CommonRequestApiArgs,
    ): Promise<ListRunsSuccessfulResponse> {
        const {workflowPath, limit, filter, cancelToken, labels} = args;

        const params: Record<string, any> = {workflow_path: workflowPath};

        if (limit) {
            params.limit = limit;
        }

        if (filter) {
            params.start_dt = filter.start;
            params.end_dt = filter.end;
        }

        if (labels && labels.length) {
            params.labels = labels.join(',');
        }

        const result = await this.client.get<ListRunsSuccessfulResponse>('/api/v1/get_runs', {
            params,
            cancelToken,
        });

        return result.data;
    }

    async getRun(
        args: GetRunRequestArgs & CommonRequestApiArgs,
    ): Promise<GetRunSuccessfulResponse> {
        const {runId, workflowPath, cancelToken} = args;

        const result = await this.client.get<GetRunSuccessfulResponse>(`/api/v1/get_run`, {
            params: {
                run_id: runId,
                workflow_path: workflowPath,
            },
            cancelToken,
        });

        return result.data;
    }

    async createRun(args: CreateRunRequestArgs): Promise<CreateRunSuccessfulResponse> {
        const {workflowPath, workflow, params, labels} = args;

        const result = await this.client.post<CreateRunSuccessfulResponse>(`/api/v1/create_run`, {
            workflow_path: workflowPath,
            workflow,
            params,
            labels,
        });

        return result.data;
    }

    async stopRun(args: StopRunRequestArgs): Promise<StopRunSuccessfulResponse> {
        const {runId, workflowPath} = args;

        const result = await this.client.post<StopRunSuccessfulResponse>(`/api/v1/stop_run`, {
            run_id: runId,
            workflow_path: workflowPath,
        });

        return result.data;
    }

    async restartRun(args: RestartRunRequestArgs): Promise<RestartRunSuccessfulResponse> {
        const {runId, workflowPath, restartSteps, restartAll} = args;

        const result = await this.client.post<RestartRunSuccessfulResponse>(`/api/v1/restart_run`, {
            run_id: runId,
            workflow_path: workflowPath,
            restart_steps: restartSteps,
            restart_all: restartAll,
        });

        return result.data;
    }

    async getLabels(args: GetLabelsRequestArgs): Promise<GetLabelsSuccessfulResponse> {
        const {workflowPath} = args;

        const params = {
            workflow_path: workflowPath,
        };

        const result = await this.client.get<GetLabelsSuccessfulResponse>(`/api/v1/get_labels`, {
            params,
        });

        return result.data;
    }
}
