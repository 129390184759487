import {WaitForDefaultPoolTree} from '@ytsaurus-ui-platform/src/ui/hooks/global-pool-trees';
import {loadPoolTreesIfNotLoaded} from '@ytsaurus-ui-platform/src/ui/store/actions/global';
import React from 'react';
import {useDispatch} from 'react-redux';

export function withDefaultPoolTree<P extends {defaultPoolTree: string}>(
    WrappedComponent: React.ComponentType<P>,
) {
    const displayName = `withDefaultPoolTree(${WrappedComponent.displayName || WrappedComponent.name})`;

    const Component = function (props: Omit<P, 'defaultPoolTree'>) {
        const dispatch = useDispatch();

        React.useEffect(() => {
            dispatch(loadPoolTreesIfNotLoaded());
        }, []);

        return (
            <WaitForDefaultPoolTree>
                {({defaultPoolTree}) => (
                    <WrappedComponent {...(props as any)} defaultPoolTree={defaultPoolTree} />
                )}
            </WaitForDefaultPoolTree>
        );
    };

    Component.displayName = displayName;

    return Component;
}
