import React from 'react';

import {useSelector} from 'react-redux';
import {selectCurrentRun, selectCurrentRunLoading} from '../../store/selectors/runs';
import {buildGraphFromWorkflow} from '../../utils/graph';
import {Flex, Icon, Text} from '@gravity-ui/uikit';
import {GraphContainer} from '../GraphContainer/GraphContainer';
import {NodesLeft} from '@gravity-ui/icons';
import {NavigationTabLoader} from 'components/NavigationTabLoader/NavigationTabLoader';
import block from 'bem-cn-lite';
import './WorkflowExecutionGraphContainer.scss';
import {WorkflowExecutionGraphToolbar} from './WorkflowExecutionGraphToolbar/WorkflowExecutionGraphToolbar';
import type {GraphCustomizableEvents} from '../GraphContainer/type';
import {useTractoDispatch} from '../../../../store/tracto-dispatch';
import {runsSlice} from '../../store/slices/runs';
import {isPersistentWorkflowRunStatus} from '../../utils/workflow';
import {BlockConnection} from '@gravity-ui/graph';

const b = block('workflow-execution-graph');

export const WorkflowExecutionGraphContainer = () => {
    const dispatch = useTractoDispatch();

    const workflowExecution = useSelector(selectCurrentRun);
    const loading = useSelector(selectCurrentRunLoading);

    const canSelectWorkflow =
        workflowExecution && isPersistentWorkflowRunStatus(workflowExecution.orcApiRun.stage);

    const getData = React.useCallback(() => {
        if (!workflowExecution) {
            throw new Error('Cannot show graph without workflow');
        }

        return buildGraphFromWorkflow({
            workflow: workflowExecution.orcApiRun.workflow,
            workflowStepsStateMap: workflowExecution.workflowStepsStateMap,
        });
    }, [workflowExecution]);

    const events: GraphCustomizableEvents = React.useMemo(() => {
        const eventsMap: GraphCustomizableEvents = {
            'blocks-selection-change': (event) => {
                dispatch(
                    runsSlice.actions.setCurrentRunSelectedSteps({
                        steps: event.detail.list as string[],
                    }),
                );
            },
            click: (event) => {
                if (!canSelectWorkflow) {
                    event.preventDefault();
                }

                const view = event.detail.target;

                if (view instanceof BlockConnection) {
                    view.context.graph.rootStore.blocksList.resetSelection();
                    event.preventDefault();
                    return;
                }
            },
        };

        return eventsMap;
    }, [canSelectWorkflow]);

    if (loading) {
        return <NavigationTabLoader />;
    }

    if (!workflowExecution) {
        return (
            <Flex
                alignItems="center"
                justifyContent="center"
                direction="column"
                width="100%"
                height="100%"
                gap="10"
            >
                <Text variant="body-2">Select workflow run from the table</Text>
                <Icon data={NodesLeft} size={60} />
            </Flex>
        );
    }

    return (
        <div className={b()}>
            <WorkflowExecutionGraphToolbar />
            <GraphContainer events={events} getData={getData} />
        </div>
    );
};
