import React from 'react';
import type {JupyterCellCommonProps} from '../types';
import {JupyterCellLayout} from '../../Layout/JupyterCellLayout/JupyterCellLayout';
import {PythonCellControls} from './elements/PythonCellControls';
import {getCellView} from '../../../utils/cell/view';
import {PythonCellSource} from './elements/PythonCellSource';
import {JupyterCellOverlay} from '../../JupyterCellOverlay/JupyterCellOverlay';
import type {OverlayType} from '../../JupyterCellOverlay/types';
import {JupyterCellOutput} from '../../JupyterCellOutput/JupyterCellOutput';
import type {TractoNotebookCodeCell} from '../../../types/version';
import {useJupyterCellEffects} from '../../../containers/JupyterCellContainer/hooks/use-jupyter-cell-effects';
import {getLayoutType} from '../../Layout/JupyterCellLayout/helpers';

export type JupyterPythonCellProps = JupyterCellCommonProps<TractoNotebookCodeCell> & {
    isDirty: boolean;
    isExecuted: boolean;
    onShowClick: (type: OverlayType) => void;
};

export const JupyterPythonCell: React.FC<JupyterPythonCellProps> = (
    props: JupyterPythonCellProps,
) => {
    const {
        onClick,
        isDirty,
        isFocused,
        isEditable,
        isExecuted,
        cell,
        onSourceChange,
        onEditorFocus,
        index,
        onShowClick,
        onEditorBlur,
    } = props;

    const {cellRef, editorRef} = useJupyterCellEffects({
        isFocused,
        isEditable,
        cell,
        cellIndex: index,
    });

    const view = getCellView(cell, 'python');
    const hasOutput = cell.outputs.length > 0;

    const renderControls = React.useCallback(() => {
        return (
            <PythonCellControls
                cellId={cell.id}
                cellIndex={index}
                hasOutput={hasOutput}
                hiddenInput={Boolean(view.hidden_input)}
                hiddenOutput={Boolean(view.hidden_output)}
            />
        );
    }, [cell.id, index, view.hidden_input, view.hidden_output, hasOutput]);

    const renderSource = React.useCallback(() => {
        return view.hidden_input ? (
            <JupyterCellOverlay type="code" onShowClick={onShowClick} />
        ) : (
            <PythonCellSource
                editorRef={editorRef}
                onChange={onSourceChange}
                cell={cell}
                isDirty={isDirty}
                isFocused={isFocused}
                isExecuted={isExecuted}
                isEditable={isEditable}
                onEditorFocus={onEditorFocus}
                onEditorBlur={onEditorBlur}
            />
        );
    }, [
        onSourceChange,
        cell,
        isDirty,
        isFocused,
        onEditorFocus,
        onShowClick,
        isExecuted,
        isEditable,
        onEditorBlur,
    ]);

    const renderOutput = React.useCallback(() => {
        if (!hasOutput) {
            return null;
        }

        return view.hidden_output ? (
            <JupyterCellOverlay type="output" onShowClick={onShowClick} />
        ) : (
            <JupyterCellOutput outputs={cell.outputs} />
        );
    }, [hasOutput, onShowClick, cell.outputs, view.hidden_output]);

    return (
        <JupyterCellLayout
            cellId={cell.id}
            layout={getLayoutType({isFocused, isEditable, isExecuted})}
            renderControls={renderControls}
            renderSource={renderSource}
            renderOutput={renderOutput}
            ref={cellRef}
            onClick={onClick}
        />
    );
};
