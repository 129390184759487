import React from 'react';
import {Flex, Text} from '@gravity-ui/uikit';
import './JupyterCellOverlay.scss';
import block from 'bem-cn-lite';

import type {OverlayType} from './types';

const b = block('jupyter-cell-overlay');

type JupyterCellOverlayProps = {
    type: OverlayType;
    onShowClick: (type: OverlayType) => void;
};

export const JupyterCellOverlay: React.FC<JupyterCellOverlayProps> = (
    props: JupyterCellOverlayProps,
) => {
    const {type, onShowClick} = props;

    const handleShowClick = React.useCallback(() => {
        onShowClick(type);
    }, [type]);

    return (
        <Flex width="100%" height="41px" gap="2" justifyContent="center" alignItems="center">
            <Text variant="body-2">This {type} has been hidden.</Text>
            <Text onClick={handleShowClick} className={b('show-it')} variant="body-2">
                Show it.
            </Text>
        </Flex>
    );
};
