import * as React from 'react';
import block from 'bem-cn-lite';
import moment from 'moment/moment';
import {Button, Card, Icon, Label, Link, Switch, Table, Text} from '@gravity-ui/uikit';
import {TrashBin} from '@gravity-ui/icons';
import {ConfirmDialog} from '@gravity-ui/components';
import ClipboardButton from '@ytsaurus-ui-platform/src/ui/components/ClipboardButton/ClipboardButton';
import {getTagsTableData} from '../../store/selectors/repositories';

import './TractoRegistryTagsList.scss';

const b = block('tracto-registry-tags-list');

type Col = ReturnType<typeof getTagsTableData>[0];

interface TractoRegistryTagsListProps {
    tags: Col[];
    tractoRegistryUrl: string;
    repository: string;
    onRowClick: (params: {tag: string; digest: string}) => void;
    onDeleteImage: (tag: string) => void;
    isPublic: boolean;
    onAccessToggle: (value: boolean) => void;
}

const ConfirmDeleteImageDialog: React.FC<{onConfirm: () => void}> = (props) => {
    const [open, setOpen] = React.useState(false);

    return (
        <span onClick={(e) => e.stopPropagation()}>
            <Button
                size="s"
                view="flat"
                onClick={() => {
                    setOpen(true);
                }}
            >
                <Icon data={TrashBin} />
            </Button>
            <ConfirmDialog
                title="Delete Docker image"
                message="You are deleting the Docker image"
                textButtonApply="Delete"
                textButtonCancel="Cancel"
                onClickButtonApply={() => {
                    props.onConfirm();
                    setOpen(false);
                }}
                onClickButtonCancel={() => {
                    setOpen(false);
                }}
                onClose={() => setOpen(false)}
                open={open}
            />
        </span>
    );
};

export const TractoRegistryTagsList: React.FC<TractoRegistryTagsListProps> = ({
    tags,
    tractoRegistryUrl,
    repository,
    onRowClick,
    onDeleteImage,
    isPublic,
    onAccessToggle,
}) => {
    return (
        <div className={b()}>
            <h2 className={b('header')}>
                <span>
                    {repository}
                    &nbsp;
                    <ClipboardButton
                        view={'flat-secondary'}
                        text={`${tractoRegistryUrl}/${repository}`}
                    />
                </span>
                <Switch
                    onUpdate={onAccessToggle}
                    checked={isPublic}
                    title="Enable to make the Docker image accessible to everyone"
                    size="m"
                >
                    Public access
                </Switch>
            </h2>
            <h3>Tags</h3>
            <div className={b('tags')}>
                {tags.map((tag) => {
                    return (
                        <Card className={b('tag')} size={'l'}>
                            <div className={b('row-title')}>
                                <div>
                                    <Label
                                        type="copy"
                                        theme="normal"
                                        className={b('row-label')}
                                        copyText={`${tractoRegistryUrl}/${repository}:${tag.tag}`}
                                    >
                                        {tag.tag}
                                    </Label>
                                    <div className={b('row-title-created')}>
                                        <Text>
                                            {tag.created
                                                ? moment(tag.created).format('DD/MM/YYYY HH:mm:ss')
                                                : ''}
                                        </Text>
                                    </div>
                                </div>
                                <div>
                                    <ConfirmDeleteImageDialog
                                        onConfirm={() => {
                                            onDeleteImage(tag.tag);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={b('row-content')}>
                                <Table
                                    width="max"
                                    data={tag.manifests}
                                    columns={[
                                        {
                                            id: 'shortDigest',
                                            name: 'Digest',
                                            template(item) {
                                                return (
                                                    <>
                                                        <Link
                                                            href=""
                                                            onClick={(e) => {
                                                                e.preventDefault();

                                                                onRowClick?.({
                                                                    tag: tag.tag,
                                                                    digest: item.digest,
                                                                });
                                                            }}
                                                        >
                                                            {item.shortDigest}
                                                        </Link>
                                                        &nbsp;
                                                        <ClipboardButton
                                                            view={'flat-secondary'}
                                                            text={`${tractoRegistryUrl}/${repository}:${tag.tag}@${item.digest}`}
                                                        />
                                                    </>
                                                );
                                            },
                                        },
                                        {
                                            id: 'osArch',
                                            name: 'OS/Arch',
                                        },
                                        {
                                            id: 'size',
                                            name: 'Compressed size',
                                            align: 'right',
                                        },
                                    ]}
                                />
                            </div>
                        </Card>
                    );
                })}
            </div>
        </div>
    );
};
