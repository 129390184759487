import type {
    TractoNotebookCell,
    TractoNotebookCellMarkdownMetadata,
    TractoNotebookCellPythonMetadata,
    TractoNotebookCellSQLMetadata,
} from '../../types/version';
import {NotebookCellType, SQL_CELL_TYPES} from '../../constants/cell';

export const isSQLMetadata = (
    metadata: TractoNotebookCell['metadata'],
): metadata is TractoNotebookCellSQLMetadata => {
    return metadata.tracto?.view_cell_type
        ? SQL_CELL_TYPES.has(metadata.tracto?.view_cell_type)
        : false;
};

export const isPythonMetadata = (
    metadata: TractoNotebookCell['metadata'],
): metadata is TractoNotebookCellPythonMetadata => {
    return metadata.tracto?.view_cell_type === NotebookCellType.CODE;
};

export const isMarkdownMetadata = (
    metadata: TractoNotebookCell['metadata'],
): metadata is TractoNotebookCellMarkdownMetadata => {
    return metadata?.tracto?.view_cell_type === NotebookCellType.MARKDOWN;
};

export const getPythonCellMetadata = (
    cell: TractoNotebookCell,
): TractoNotebookCellPythonMetadata => {
    if (isPythonMetadata(cell.metadata)) {
        return cell.metadata;
    }

    throw new Error(
        `Called for not Code cell. Cell view type is ${cell.metadata.tracto?.view_cell_type ?? 'Unknown'}`,
    );
};

export const getSQLCellMetadata = (cell: TractoNotebookCell): TractoNotebookCellSQLMetadata => {
    if (isSQLMetadata(cell.metadata)) {
        return cell.metadata;
    }

    throw new Error(
        `Called for not SQL cell. Cell view type is ${cell.metadata.tracto?.view_cell_type ?? 'Unknown'}`,
    );
};

export const getMarkdownCellMetadata = (
    cell: TractoNotebookCell,
): TractoNotebookCellMarkdownMetadata => {
    if (isMarkdownMetadata(cell.metadata)) {
        return cell.metadata;
    }

    throw new Error(
        `Called for not Markdown cell. Cell view type is ${cell.metadata.tracto?.view_cell_type ?? 'Unknown'}`,
    );
};

export const getExecutableCellMetadata = (
    cell: TractoNotebookCell,
): TractoNotebookCellPythonMetadata | TractoNotebookCellSQLMetadata => {
    if (isSQLMetadata(cell.metadata) || isPythonMetadata(cell.metadata)) {
        return cell.metadata;
    }

    throw new Error(
        `Called for not Executable cell. Cell view type is ${cell.metadata.tracto?.view_cell_type ?? 'Unknown'}`,
    );
};

type GetCellViewReturnType<T extends 'sql' | 'python' | 'markdown' | 'common' | 'executable'> =
    T extends 'sql'
        ? TractoNotebookCellSQLMetadata['tracto']
        : T extends 'python'
          ? TractoNotebookCellPythonMetadata['tracto']
          : T extends 'markdown'
            ? TractoNotebookCellMarkdownMetadata['tracto']
            : T extends 'common'
              ?
                    | TractoNotebookCellSQLMetadata['tracto']
                    | TractoNotebookCellPythonMetadata['tracto']
                    | TractoNotebookCellMarkdownMetadata['tracto']
              : T extends 'executable'
                ?
                      | TractoNotebookCellSQLMetadata['tracto']
                      | TractoNotebookCellPythonMetadata['tracto']
                : never;

export const getCellView = <T extends 'sql' | 'python' | 'markdown' | 'common' | 'executable'>(
    cell: TractoNotebookCell,
    type: T,
): GetCellViewReturnType<T> => {
    switch (type) {
        case 'sql':
            return getSQLCellMetadata(cell).tracto as GetCellViewReturnType<T>;
        case 'python':
            return getPythonCellMetadata(cell).tracto as GetCellViewReturnType<T>;
        case 'markdown':
            return getMarkdownCellMetadata(cell).tracto as GetCellViewReturnType<T>;
        case 'executable':
            return getExecutableCellMetadata(cell).tracto as GetCellViewReturnType<T>;
        case 'common':
            return cell.metadata.tracto as GetCellViewReturnType<T>;
        default:
            return null as never;
    }
};

export const getCellSQLConfig = (cell: TractoNotebookCell) => {
    return getCellView(cell, 'sql').sql;
};

export const isSQLCellType = (cellType: NotebookCellType | undefined) => {
    return Boolean(cellType && SQL_CELL_TYPES.has(cellType));
};
