import React from 'react';
import {CanvasBlock, type TBlock} from '@gravity-ui/graph';
import type {WorkflowStepState, WorkflowStepType} from '../../../types/workflow';
import {WorkflowStepContainer} from '../../../containers/WorkflowStepContainer/WorkflowStepContainer';

type WorkflowTBlockMeta = {
    step: WorkflowStepType;
    state: WorkflowStepState | undefined;
};

export type WorkflowTBlock = Omit<TBlock, 'meta'> & {meta: WorkflowTBlockMeta};

export class WorkflowBlock extends CanvasBlock<WorkflowTBlock> {
    get id() {
        return this.state.id as string;
    }

    get selected() {
        return this.state.selected;
    }

    renderHTML() {
        return (
            <WorkflowStepContainer
                graph={this.context.graph}
                block={this.connectedState.$state.value}
            />
        );
    }

    protected renderStroke() {
        this.context.ctx.strokeStyle = 'rgba(0,0,0,0)';
        this.context.ctx.strokeRect(
            this.state.x,
            this.state.y,
            this.state.width,
            this.state.height,
        );
    }

    protected renderBody(ctx: CanvasRenderingContext2D) {
        ctx.fillStyle = 'rgba(0,0,0,0)';

        ctx.fillRect(this.state.x, this.state.y, this.state.width, this.state.height);
        this.renderStroke();
    }
}
