import type {Graph} from '@gravity-ui/graph';
import {WorkflowStepComponent} from 'features/Orchestracto/components/WorkflowStepComponent/WorkflowStepComponent';
import {stepDetailsModalSlice} from 'features/Orchestracto/store/slices/modals/step-details-modal';
import React, {useCallback, useMemo} from 'react';
import {useDispatch} from 'react-redux';

import type {WorkflowTBlock} from '../../libs/graph/canvas/WorkflowBlock';

type WorkflowStepContainerProps = {
    graph: Graph;
    block: WorkflowTBlock;
};

export const WorkflowStepContainer: React.FC<WorkflowStepContainerProps> = ({graph, block}) => {
    const dispatch = useDispatch();

    const meta = useMemo(() => {
        return block.meta;
    }, [block.meta]);

    const step = meta.step;
    const workflowState = meta.state;

    const onShowDetailsClick = useCallback(() => {
        dispatch(stepDetailsModalSlice.actions.open({step}));
    }, [step]);

    if (!step) {
        return null;
    }

    return (
        <WorkflowStepComponent
            graph={graph}
            block={block}
            step={step}
            workflowState={workflowState}
            onShowDetailsClick={onShowDetailsClick}
        />
    );
};
