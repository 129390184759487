import React from 'react';
import {ytApiV3} from '@ytsaurus-ui-platform/src/ui/rum/rum-wrap-api';
import {TractoThunkDispatch} from '../../../../store/tracto-dispatch';
import {TractoRootState} from 'store/reducers';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';
import {workflowSlice} from '../slices/workflow';
import {createTractoAsyncThunk} from '../../../../store/tracto-async-thunk';
import {showErrorInModal} from '@ytsaurus-ui-platform/src/ui/store/actions/navigation/modals/path-editing-popup';
import {getDefaultWorkflowContent} from '../../utils/workflow';
import {wrapApiPromiseByToaster} from '@ytsaurus-ui-platform/src/ui/utils/utils';
import {selectParsedWorkflow} from '../selectors/workflow';
import Link from '@ytsaurus-ui-platform/src/ui/components/Link/Link';
import {OrchestractoApi} from '../../api';
import {selectCurrentRun, selectCurrentRunSelectedSteps} from '../selectors/runs';
import {loadWorkflowRunsFormCypress} from './runs';
import {getAppBrowserHistory} from '@ytsaurus-ui-platform/src/ui/store/window-store';
import {getWorkflowRunUrl} from 'features/Orchestracto/utils/runs/url';
import {loadWorkflowRunState} from './runs/run';

export const loadWorkflowFromCypress = () => {
    return async (dispatch: TractoThunkDispatch, getState: () => TractoRootState) => {
        const path = getPath(getState());

        const workflow = await ytApiV3.get({path});

        dispatch(workflowSlice.actions.setWorkflowFromCypress({workflow}));
    };
};

export const saveWorkflowInCypress = () => {
    return async (dispatch: TractoThunkDispatch, getState: () => TractoRootState) => {
        const path = getPath(getState());
        const workflow = selectParsedWorkflow(getState());

        if (workflow) {
            await wrapApiPromiseByToaster(
                OrchestractoApi.api.workflow.updateWorkflow({workflow, workflowPath: path}),
                {
                    toasterName: 'save-workflow-in-cypress',
                    skipSuccessToast: true,
                    errorTitle: 'Failed to save workflow',
                    errorContent: (error) => {
                        const code = error.code ? `[code ${error.code}]` : '';
                        const message = error.message;

                        return [code, message].filter(Boolean).join(' ');
                    },
                },
            );

            await dispatch(loadWorkflowFromCypress());
        }
    };
};

export const validateWorkflow = createTractoAsyncThunk<
    void,
    undefined,
    {rejectValue: {failed: boolean; error: any}}
>('orchestracto.store.validate-workflow', async (_, thunkAPI) => {
    const workflowContent = selectParsedWorkflow(thunkAPI.getState());

    if (!workflowContent) {
        return undefined;
    }

    try {
        await wrapApiPromiseByToaster(
            OrchestractoApi.api.workflow.validateWorkflow({
                workflow: workflowContent,
            }),
            {
                toasterName: 'validate-workflow',
                skipSuccessToast: true,
                errorTitle: 'Failed to validate workflow',
                errorContent: (error: any) => {
                    const code = error.code ? `[code ${error.code}]` : '';
                    const message = error.message;

                    return [code, message].filter(Boolean).join(' ');
                },
            },
        );
    } catch (e: any) {
        return thunkAPI.rejectWithValue({failed: true, error: e});
    }

    return undefined;
});

export const dispatchWorkflowActionWithValidation = createTractoAsyncThunk<void, () => any>(
    'orchestracto.store.dispatch-workflow-action-with-validation',
    async (action, thunkAPI) => {
        const result = await thunkAPI.dispatch(validateWorkflow());

        if (result.payload?.failed) {
            return;
        }

        action();
    },
);

export const createWorkflow = createTractoAsyncThunk<boolean, {path: string}>(
    'orchestracto.actions.create-workflow',
    async ({path}, thunkAPI) => {
        try {
            await OrchestractoApi.api.workflow.updateWorkflow({
                workflow: getDefaultWorkflowContent(),
                workflowPath: path,
            });
        } catch (error) {
            const action = showErrorInModal(error);

            thunkAPI.dispatch(action);

            return thunkAPI.rejectWithValue(false);
        }

        return true;
    },
);

export const userRunOneTimeRunWorkflow = createTractoAsyncThunk<
    void,
    {params?: Record<string, any>; labels?: string[]}
>('orchestracto.actions.one-time-run-workflow', async ({params, labels}, thunkAPI) => {
    const workflowContent = selectParsedWorkflow(thunkAPI.getState());

    if (!workflowContent) {
        return;
    }

    const path = getPath(thunkAPI.getState());

    const getSuccessContent = (params: {path: string; runId: string}) => {
        const url = getWorkflowRunUrl(params);

        return <Link url={url}>Please visit runs tab for more details</Link>;
    };

    const toasterParams = {
        toasterName: 'one-time-run-workflow',
        successTitle: 'Workflow was started',
        errorTitle: 'Failed to run workflow',
        errorContent: (error: any) => {
            const code = error.code ? `[code ${error.code}]` : '';
            const message = error.message;

            return [code, message].filter(Boolean).join(' ');
        },
    };

    await wrapApiPromiseByToaster(
        OrchestractoApi.api.runs.createRun({
            workflowPath: path,
            workflow: workflowContent,
            params,
            labels,
        }),
        {
            ...toasterParams,
            successContent: (result) => {
                return getSuccessContent({path, runId: result.run_id});
            },
        },
    );
});

export const userRestartWorkflowRun = createTractoAsyncThunk<void, {restartAll: boolean}>(
    'orchestracto.actions.restart-workflow-run',
    async ({restartAll}, thunkAPI) => {
        const currentRun = selectCurrentRun(thunkAPI.getState());
        const restartSteps = selectCurrentRunSelectedSteps(thunkAPI.getState());

        if (!currentRun) {
            return;
        }

        const path = getPath(thunkAPI.getState());

        const toasterParams = {
            toasterName: 'restart-workflow',
            successTitle: 'Workflow was restarted',
            errorTitle: 'Failed to restart workflow',
            errorContent: (error: any) => {
                const code = error.code ? `[code ${error.code}]` : '';
                const message = error.message;

                return [code, message].filter(Boolean).join(' ');
            },
        };

        const {run_id} = await wrapApiPromiseByToaster(
            OrchestractoApi.api.runs.restartRun({
                workflowPath: path,
                runId: currentRun.orcApiRun.run_id,
                restartAll: restartSteps.length > 0 ? undefined : restartAll,
                restartSteps,
            }),
            {
                ...toasterParams,
            },
        );

        await thunkAPI.dispatch(loadWorkflowRunsFormCypress());

        const url = getWorkflowRunUrl({path, runId: run_id});

        const history = getAppBrowserHistory();

        history.push(url);
    },
);

export const resetWorkflowState = createTractoAsyncThunk(
    'orchestracto.actions.reset-workflow-state',
    (_, thunkAPI) => {
        thunkAPI.dispatch(workflowSlice.actions.reset());
    },
);

export const userStopWorkflowRun = createTractoAsyncThunk<void, undefined>(
    'orchestracto.actions.user-cancel-workflow-run',
    async (_, thunkAPI) => {
        const currentRun = selectCurrentRun(thunkAPI.getState());

        if (!currentRun) {
            return;
        }

        const path = getPath(thunkAPI.getState());

        const toasterParams = {
            toasterName: 'stop-workflow',
            successTitle: 'Workflow was stopped',
            errorTitle: 'Failed to stop workflow',
            errorContent: (error: any) => {
                const code = error.code ? `[code ${error.code}]` : '';
                const message = error.message;

                return [code, message].filter(Boolean).join(' ');
            },
        };

        await wrapApiPromiseByToaster(
            OrchestractoApi.api.runs.stopRun({
                workflowPath: path,
                runId: currentRun.orcApiRun.run_id,
            }),
            {
                ...toasterParams,
            },
        );

        thunkAPI.dispatch(loadWorkflowRunsFormCypress());
        thunkAPI.dispatch(loadWorkflowRunState({runId: currentRun.orcApiRun.run_id}));
    },
);
