import {isSQLMetadata} from '../../../../../utils/cell/view';
import {NotebookSQLHelper} from '../../../../../utils/sql/helper';
import {generateHashFromSource} from '../../../../../utils/cell/hash';
import type {TractoNotebookCell} from '../../../../../types/version';
import cloneDeep from 'lodash/cloneDeep';

export const mapCellWithHashValidation = async (
    cell: TractoNotebookCell,
): Promise<TractoNotebookCell> => {
    if (isSQLMetadata(cell.metadata)) {
        const {sql, source_hash} = cell.metadata.tracto;

        const source = NotebookSQLHelper.getQuery(sql.template_id, sql.variables);

        return generateHashFromSource(source).then((hash) => {
            if (source_hash && source_hash !== hash) {
                const copy = cloneDeep(cell);

                copy.source = source;
                copy.metadata.tracto.source_hash = hash;

                return copy;
            }

            return cell;
        });
    } else {
        return cell;
    }
};
