import {useEffect, useMemo} from 'react';
import type {IDisposable} from 'monaco-editor';

export const useMonacoExtensions = (getExtensions: () => Array<() => IDisposable | void>) => {
    const extensions = useMemo(() => getExtensions(), [getExtensions]);

    useEffect(() => {
        const unsubscribe = extensions.map((ext) => ext());

        return () => {
            unsubscribe.map((unsub) => (unsub ? unsub.dispose() : undefined));
        };
    }, [extensions]);
};
