import {OrchestarctoLogEntry} from 'features/Orchestracto/api/logs/types';
import React from 'react';
import block from 'bem-cn-lite';
import './OrchestarctoLogComponent.scss';
import {Loader, Text} from '@gravity-ui/uikit';

const b = block('orchestarcto-log');

type OrchestarctoLogComponentProps = {
    log: OrchestarctoLogEntry[];
};

export const OrchestarctoLogComponent: React.FC<OrchestarctoLogComponentProps> = ({log}) => {
    let content;

    if (log.length) {
        content = log.map((item, index) => {
            const text = `${item.levelname} ${item.timestamp} ${item.message}`;

            return (
                <Text className={b('log-entry')} variant="code-2" key={index}>
                    {text}
                </Text>
            );
        });
    } else {
        content = (
            <div className={b('loader-wrapper')}>
                <Loader />
            </div>
        );
    }

    return (
        <div className={b()}>
            <div className={b('content')}>{content}</div>
        </div>
    );
};
