import React from 'react';
import type {ExtraTab} from '@ytsaurus-ui-platform/src/ui/UIFactory';
import {Tab as NavigationTab} from '@ytsaurus-ui-platform/src/ui/constants/navigation/index';
import {Icon} from '@gravity-ui/uikit';
import {Key} from '@gravity-ui/icons';
import type {Node} from '@ytsaurus-ui-platform/src/ui/utils/navigation/content/map-nodes/node';
import {SecretStoreTabContainer} from 'features/SecretStore/containers/SecretStoreTabContainer/SecretStoreTabContainer';

const TRACTO_TYPE_ATTRIBUTE = 'tracto_type';

const SECRET_STORE_TYPE = 'secret_store';

export const SECRET_STORE_NAVIGATION_TAB: ExtraTab = {
    value: `extra_secret`,
    text: 'Secret store',
    title: 'Go to secret store interface',
    component: SecretStoreTabContainer,
    position: {before: NavigationTab.CONTENT},
    isSupported: (attributes) => {
        return attributes[TRACTO_TYPE_ATTRIBUTE] === SECRET_STORE_TYPE;
    },
    additionalAttributes: [TRACTO_TYPE_ATTRIBUTE],
};

export const SECRET_STORE_NAVIGATION_MAP_NODE_SETTINGS = {
    additionalAttributes: [TRACTO_TYPE_ATTRIBUTE],
    renderNodeIcon: (item: Node) => {
        if (item.$attributes?.[TRACTO_TYPE_ATTRIBUTE] === SECRET_STORE_TYPE) {
            return <Icon data={Key} size={14} />;
        }

        return null;
    },
};

export * from 'features/SecretStore/containers/SecretStoreCreateDialog/SecretStoreCreateDialog';
