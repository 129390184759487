import {makeGet} from '@ytsaurus-ui-platform/src/ui/utils/batch';
import {USE_CACHE} from '@ytsaurus-ui-platform/src/shared/constants/yt-api';
import {ytApiV4} from '@ytsaurus-ui-platform/src/ui/rum/rum-wrap-api';

import type {prepareFields} from './fields';
import {
    extractBatchV4Values,
    splitBatchResults,
    wrapApiPromiseByToaster,
} from '@ytsaurus-ui-platform/src/ui/utils/utils';
import {isAxiosError} from 'axios';

type SpecletType = ReturnType<typeof prepareFields>['speclet_options'];

const validatePoolInPoolTrees = async (speclet: SpecletType) => {
    const {pool_trees, pool} = speclet;

    if (pool_trees && pool) {
        const requests = pool_trees.map((poolTreeName) =>
            makeGet(`//sys/scheduler/orchid/scheduler/pool_trees/${poolTreeName}/pools`),
        );

        const response = await ytApiV4.executeBatch({requests, ...USE_CACHE});

        const {results, error} = splitBatchResults(
            response.results,
            'Failed to fetch pools for pool trees',
        );

        if (error) {
            throw error;
        }

        const poolTreesWithoutPool = pool_trees.filter((_, index) => {
            const {value} = results[index];
            return !value || !value[pool];
        });

        if (poolTreesWithoutPool.length > 0) {
            throw new Error(
                `The pool "${pool}" is not found in the following pool trees: ${poolTreesWithoutPool.join(', ')}`,
            );
        }
    }
};

const hasAvailableResource = ({
    limits,
    usage,
    resourceKey,
    requiredAmount,
}: {
    limits: Record<string, number>;
    usage: Record<string, number>;
    resourceKey: string;
    requiredAmount?: number;
}) => {
    if (!requiredAmount) {
        return true;
    }

    const avalableResource = limits[resourceKey];
    const usedResource = usage[resourceKey];

    return (
        avalableResource !== undefined &&
        usedResource !== undefined &&
        avalableResource - usedResource >= requiredAmount
    );
};

const validateResourcesInPoolTrees = async (speclet: SpecletType) => {
    const {pool_trees, gpu, cpu, memory, pool} = speclet;

    if (pool_trees && pool && (gpu || cpu || memory)) {
        const requests = pool_trees.flatMap((poolTreeName) => {
            const poolTreePath = `//sys/scheduler/orchid/scheduler/pool_trees/${poolTreeName}`;
            return [
                makeGet(`${poolTreePath}/pools/${pool}/resource_limits`),
                makeGet(`${poolTreePath}/pools/${pool}/resource_usage`),
            ];
        });

        const response = await ytApiV4.executeBatch({requests, ...USE_CACHE});
        const successResponses = response.results.filter((resultItem) => !resultItem.error);
        const extracted = extractBatchV4Values({results: successResponses}, requests);
        const {results, error} = splitBatchResults(extracted.results, 'Failed to fetch pool tree');

        if (error) {
            throw error;
        }

        const hasSufficientResources = pool_trees.some((_, index) => {
            const limits = results[index * 2];
            const usage = results[index * 2 + 1];

            return (
                hasAvailableResource({limits, usage, resourceKey: 'gpu', requiredAmount: gpu}) &&
                hasAvailableResource({limits, usage, resourceKey: 'cpu', requiredAmount: cpu}) &&
                hasAvailableResource({
                    limits,
                    usage,
                    resourceKey: 'user_memory',
                    requiredAmount: memory,
                })
            );
        });

        if (!hasSufficientResources) {
            const requestedResources = [
                gpu && `${gpu} GPU(s)`,
                cpu && `${cpu} CPU(s)`,
                memory && `${memory} memory`,
            ].filter(Boolean);

            throw new Error(
                `Insufficient resources available. You requested ${requestedResources.join(', ')}, but none of the selected pool trees (${pool_trees.join(', ')}) have enough resources available.`,
            );
        }
    }
};

export const validateOnApply = (speclet: SpecletType) => {
    return validatePoolInPoolTrees(speclet).then(() => validateResourcesInPoolTrees(speclet));
};

export const applyWithValidation = async (speclet: SpecletType, cb: () => any) => {
    return wrapApiPromiseByToaster(validateOnApply(speclet).then(cb), {
        skipSuccessToast: true,
        toasterName: 'JupytSpecletDialog',
        errorTitle: 'Validation failed',
        errorContent(e: any) {
            let errorMessage = 'Error while validating speclet';

            if (isAxiosError(e)) {
                errorMessage = e.response?.data.message;
            } else if (e.message) {
                errorMessage = e.message;
            }

            return errorMessage;
        },
    });
};
