import type {TractoThunkDispatch} from '../../../store/tracto-dispatch';
import {notebookSlice} from '../store/slices/notebook';

const PATH_HOME_PREFIX = '//';
const JUPYTER_PATH_PREFIX = '/tracto';

export const getJupyterContentManagerPath = (path: string) => {
    return `${JUPYTER_PATH_PREFIX}/${path.replace(PATH_HOME_PREFIX, '')}`;
};

export const getParentFolderPath = (path: string) => {
    return `${PATH_HOME_PREFIX}${path.replace(PATH_HOME_PREFIX, '').split('/').slice(0, -1).join('/')}`;
};

export const getJupyterNotebookPathWithExtension = (path: string) => {
    if (path.endsWith('.ipynb')) {
        return path;
    }

    return `${path}.ipynb`;
};

export const generateNotebookCypressId = () => crypto.randomUUID();

export const withFocusBeforeCellAction = (
    dispatch: TractoThunkDispatch,
    cellId: string,
    cb: (...args: any) => void,
) => {
    return (...args: any) => {
        dispatch(notebookSlice.actions.setFocusedCellById({cellId}));

        return cb(...args);
    };
};
