import type {
    TractoNotebookCellMarkdownMetadata,
    TractoNotebookCellPythonMetadata,
    TractoNotebookCellSQLMetadata,
    TractoNotebookCodeCell,
    TractoNotebookMarkdownCell,
} from '../../types/version';
import type {MultilineString} from '@jupyterlab/nbformat';

type CommonPayload = {
    cellId: string;
    source: MultilineString;
};

type CodeCellPayload = CommonPayload & {
    metadata: TractoNotebookCellSQLMetadata | TractoNotebookCellPythonMetadata;
};

export const getCodeCell = ({
    cellId,
    source,
    metadata,
}: CodeCellPayload): TractoNotebookCodeCell => {
    return {
        cell_type: 'code',
        id: cellId,
        source,
        metadata,
        outputs: [],
        execution_count: null,
    };
};

type MarkdownCellPaylaod = CommonPayload & {metadata: TractoNotebookCellMarkdownMetadata};

export const getMarkdownCell = ({
    cellId,
    source,
    metadata,
}: MarkdownCellPaylaod): TractoNotebookMarkdownCell => {
    return {
        cell_type: 'markdown',
        id: cellId,
        source,
        metadata,
    };
};
