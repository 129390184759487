import {HookGraphParams} from '@gravity-ui/graph';
import {WorkflowBlock} from './canvas/WorkflowBlock';

const RENDER_NODE_AS_REACT_COMPONENT = [1, 1, 0];

export const workflowConfig: HookGraphParams = {
    settings: {
        blockComponents: {
            block: WorkflowBlock,
        },
    },
    viewConfiguration: {
        constants: {
            block: {
                SCALES: RENDER_NODE_AS_REACT_COMPONENT,
            },
        },
        colors: {
            block: {
                background: 'red',
            },
        },
    },
};
