import React, {type MutableRefObject} from 'react';
import cn from 'bem-cn-lite';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import 'monaco-editor/esm/vs/basic-languages/python/python.contribution';
import 'monaco-editor/esm/vs/basic-languages/dockerfile/dockerfile.contribution';
import '@ytsaurus-ui-platform/src/ui/libs/monaco-yql-languages/monaco.contribution';
import './TractoMonacoEditor.scss';
import {RealTheme, withThemeValue} from '@gravity-ui/uikit';
import {TRACTO_EDITOR_DARK_THEME, TRACTO_EDITOR_LIGHT_THEME} from './themes/themes.contribution';
import type {MonacoLanguage} from '@ytsaurus-ui-platform/src/ui/constants/monaco';
import {TractoMonacoEditorQa} from '../../../shared/qa/common';

export type TractoEditorLanguage = 'markdown' | 'python' | 'json' | 'dockerfile' | MonacoLanguage;

const IMAGE_MIME_TYPES = new Set([
    'image/bmp',
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
]);

const block = cn('tracto-monaco-editor');

const THEMES = {
    dark: TRACTO_EDITOR_DARK_THEME,
    'dark-hc': 'hc-black',
    light: TRACTO_EDITOR_LIGHT_THEME,
    'light-hc': 'hc-light',
};

const lineNumbersConfig = {
    lineNumbersMinChars: 4,
    lineDecorationsWidth: 5,
};

export interface TractoMonacoEditorComponentProps {
    className?: string;
    value: string;
    language?: TractoEditorLanguage;
    onChange: (value: string) => void;
    onBlur?: () => void;
    onImagePaste?: (value: {name: string; type: string; base64: string}) => void;
    onValidate?: (markers: monaco.editor.IMarker[]) => void;
    editorRef?: MutableRefObject<monaco.editor.IStandaloneCodeEditor | undefined>;
    readOnly?: boolean;
    themeValue: RealTheme;
    adjustToContent?: boolean;
    onEditorFocus?: React.FocusEventHandler<HTMLDivElement>;
}

class TractoMonacoEditorComponent extends React.Component<TractoMonacoEditorComponentProps> {
    private ref = React.createRef<HTMLDivElement>();
    private model = monaco.editor.createModel('', this.props.language);
    private editor?: monaco.editor.IStandaloneCodeEditor;
    private onContentSizeChange?: monaco.IDisposable;
    private onDidChangeMarkers?: monaco.IDisposable;
    private disableSearchKeybinding?: monaco.IDisposable;
    private silent = false;

    componentDidMount() {
        const {
            editorRef,
            readOnly,
            themeValue,
            language,
            adjustToContent = true,
            onValidate,
        } = this.props;

        this.model.setValue(this.props.value);
        const container = this.ref.current!;
        const editor = (this.editor = monaco.editor.create(container, {
            model: this.model,
            renderLineHighlight: 'all',
            renderLineHighlightOnlyWhenFocus: true,
            colorDecorators: false,
            automaticLayout: true,
            scrollBeyondLastLine: false,
            fixedOverflowWidgets: true,
            readOnly,
            pasteAs: {
                enabled: false,
            },
            minimap: {
                enabled: false,
            },
            overviewRulerLanes: 0,
            overviewRulerBorder: false,
            wordBasedSuggestions: false,
            padding: {
                top: 5,
                bottom: 5,
            },
            theme: THEMES[themeValue as 'light' | 'light-hc' | 'dark' | 'dark-hc'],
            scrollbar: {
                alwaysConsumeMouseWheel: false,
            },
            fontSize: 14,
            renderWhitespace: 'boundary',
            wordWrap: language === 'markdown' ? 'on' : 'off',
            ...lineNumbersConfig,
        }));

        this.disableSearchKeybinding = monaco.editor.addKeybindingRule({
            // eslint-disable-next-line no-bitwise
            keybinding: monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyF,
            command: null,
        });

        this.onDidChangeMarkers = monaco.editor.onDidChangeMarkers((uris) => {
            const editorUri = this.model.uri;

            if (editorUri && onValidate) {
                const currentEditorHasMarkerChanges = uris.find(
                    (uri) => uri.path === editorUri.path,
                );
                if (currentEditorHasMarkerChanges) {
                    const markers = monaco.editor.getModelMarkers({
                        resource: editorUri,
                    });

                    onValidate(markers);
                }
            }
        });

        this.model.onDidChangeContent(this.onContentChanged);
        this.editor.onDidBlurEditorText(this.onBlurEditorText);
        window.addEventListener('paste', this.onPaste);

        if (editorRef) {
            editorRef.current = this.editor;
        }

        if (adjustToContent) {
            this.onContentSizeChange = editor.onDidContentSizeChange(() => {
                const contentHeight = editor.getContentHeight();
                const contentWidth = container.clientWidth;
                editor.layout({width: contentWidth, height: contentHeight});
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<TractoMonacoEditorComponentProps>): void {
        const {themeValue, value, readOnly, language} = this.props;
        const options: monaco.editor.IStandaloneEditorConstructionOptions = {};

        if (prevProps.themeValue !== themeValue) {
            options.theme = THEMES[themeValue as 'light' | 'light-hc' | 'dark' | 'dark-hc'];
        }

        if (value !== this.model.getValue()) {
            this.silent = true;
            this.model.setValue(value);
            this.silent = false;
        }
        if (language !== prevProps.language) {
            this.model = monaco.editor.createModel(this.model.getValue(), this.props.language);
            this.model.onDidChangeContent(this.onContentChanged); // the new model needs to re-specify the callback
            this.editor?.setModel(this.model);
        }
        if (readOnly !== prevProps.readOnly) {
            this.editor?.updateOptions({readOnly});
        }

        this.editor?.updateOptions(options);
    }

    componentWillUnmount() {
        this.editor?.getModel()?.dispose();
        this.editor?.dispose();
        this.onContentSizeChange?.dispose();
        this.onDidChangeMarkers?.dispose();
        this.disableSearchKeybinding?.dispose();
        window.removeEventListener('paste', this.onPaste);
    }

    render() {
        const {className, onEditorFocus} = this.props;

        return (
            <div
                onFocus={onEditorFocus}
                onClick={this.onEditorClick}
                className={block(null, className)}
            >
                <div
                    data-qa={TractoMonacoEditorQa.Root}
                    ref={this.ref}
                    className={block('editor')}
                />
            </div>
        );
    }

    private onEditorClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
    };

    private onContentChanged = () => {
        if (this.silent) {
            return;
        }
        const {onChange} = this.props;
        const value = this.model.getValue();
        onChange(value);
    };

    private onBlurEditorText = () => {
        if (document.hasFocus()) {
            this.props.onBlur?.();
        }
    };

    private onPaste = (clipboardEvent: ClipboardEvent) => {
        if (
            !this.editor?.hasTextFocus() ||
            !this.props.onImagePaste ||
            !clipboardEvent.clipboardData
        ) {
            return;
        }

        const file = Array.from(clipboardEvent.clipboardData.items)
            .find((item) => item.kind == 'file' && IMAGE_MIME_TYPES.has(item.type))
            ?.getAsFile();

        if (!file) {
            return;
        }

        const uuid = crypto.randomUUID();

        const attachmentName = `${uuid}`;

        this.editor.trigger('myapp', 'undo', {});
        this.editor.trigger('keyboard', 'type', {
            text: `![${file.name}](attachment:${attachmentName})`,
        });

        const fileReader = new FileReader();

        fileReader.onloadend = () => {
            this.props.onImagePaste?.({
                name: attachmentName,
                type: file.type,
                base64: String(fileReader.result).split(',')[1],
            });
        };

        fileReader.readAsDataURL(file);
    };
}

export default withThemeValue(TractoMonacoEditorComponent);
