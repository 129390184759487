import type {TractoNotebookCell, UnrecognizedTractoNotebookCell} from '../../../../types/version';
import {isExternalNotebookCell} from './guards/is-external-notebook-cell';
import {mapExternalNotebookCellToV1} from './v1';
import {mapCellWithHashValidation} from './helpers/hash';

export const mapNotebookCellToLatestVersion = async (
    unrecognizedCell: UnrecognizedTractoNotebookCell,
): Promise<TractoNotebookCell> => {
    let cell = unrecognizedCell;

    if (isExternalNotebookCell(cell)) {
        cell = mapExternalNotebookCellToV1(cell);
    }

    return mapCellWithHashValidation(cell as TractoNotebookCell);
};
