import type {TemplateView} from '../../../../types/sql/view';
import React, {useCallback} from 'react';
import {TextInput} from '@gravity-ui/uikit';

type BasicSQLControlProps = {
    required: boolean;
    defaultValue: string;
    onChange: (variable: string, value: string) => void;
    id: string;
    view: TemplateView;
    title: string;
    value: string;
    onControlClick: (event: React.MouseEvent<HTMLElement>) => void;
};

export const BasicSQLControl: React.FC<BasicSQLControlProps> = (props: BasicSQLControlProps) => {
    const {defaultValue, onChange, id, view, title, value, onControlClick} = props;

    const handleChange = useCallback(
        (changedValue: string) => {
            onChange(id, changedValue);
        },
        [id],
    );

    switch (view) {
        case 'input':
            return (
                <div onClick={onControlClick}>
                    <TextInput
                        value={value}
                        label={title}
                        size="s"
                        defaultValue={defaultValue}
                        onUpdate={handleChange}
                    />
                </div>
            );
        default:
            return null;
    }
};
