import {Dialog, Text} from '@gravity-ui/uikit';
import Yson from '@ytsaurus-ui-platform/src/ui/components/Yson/Yson';
import {selectModalStepDetails} from 'features/Orchestracto/store/selectors/modals';
import {stepDetailsModalSlice} from 'features/Orchestracto/store/slices/modals/step-details-modal';
import React, {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import block from 'bem-cn-lite';
import './StepDetailsModal.scss';
import MetaTable from '@ytsaurus-ui-platform/src/ui/components/MetaTable/MetaTable';
import {selectCurrentRunWorkflowStepsStateMap} from 'features/Orchestracto/store/selectors/runs';
import {getCluster} from '@ytsaurus-ui-platform/src/ui/store/selectors/global';
import {Page} from '@ytsaurus-ui-platform/src/ui/constants';
import Link from '@ytsaurus-ui-platform/src/ui/components/Link/Link';
import {OrchestarctoLogContainer} from '../OrchestarctoLogContainer/OrchestarctoLogContainer';

const b = block('step-details-modal');

export const StepDetailsModal: React.FC = () => {
    const step = useSelector(selectModalStepDetails);
    const currentRunWorkflowStepsStateMap = useSelector(selectCurrentRunWorkflowStepsStateMap);
    const dispatch = useDispatch();
    const cluster = useSelector(getCluster);
    const stepData = step && currentRunWorkflowStepsStateMap?.[step.step_id];

    const onClose = useCallback(() => {
        dispatch(stepDetailsModalSlice.actions.close());
    }, []);

    const items = useMemo(() => {
        const items = [
            {
                key: 'Task params',
                value: <Yson value={step?.task_params} />,
            },
            {
                key: 'Secrets',
                value: <Yson value={step?.secrets} />,
            },
            {
                key: 'Args',
                value: <Yson value={step?.args} />,
            },
            {
                key: 'Outputs',
                value: <Yson value={step?.outputs} />,
            },
        ];

        const {outputs, info} = stepData || {};

        if (outputs) {
            items.push({
                key: 'Output values',
                value: <Yson value={outputs} />,
            });
        }

        const operationId = info?.yt_operation_id;

        if (operationId) {
            const url = `/${cluster}/${Page.OPERATIONS}/${operationId}`;
            items.unshift({
                key: 'YT operation id',
                value: (
                    <Text variant="code-1">
                        <Link url={url}>{operationId}</Link>
                    </Text>
                ),
            });
        }

        return items;
    }, [step, stepData]);

    const visible = Boolean(step);

    if (!visible) {
        return null;
    }

    let logsSection = null;

    if (stepData) {
        logsSection = (
            <div className={b('section')}>
                <Text variant="subheader-2" className={b('section-header')}>
                    Logs
                </Text>
                <div>
                    <OrchestarctoLogContainer />
                </div>
            </div>
        );
    }

    return (
        <Dialog open={true} onClose={onClose} size="l" className={b()}>
            <Dialog.Header caption={`${step?.step_id}`} />
            <Dialog.Body>
                <div className={b('section')}>
                    <Text variant="subheader-2" className={b('section-header')}>
                        Details
                    </Text>
                    <MetaTable items={items} rowGap={4} />
                </div>
                {logsSection}
            </Dialog.Body>
        </Dialog>
    );
};
