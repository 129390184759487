import React from 'react';
import block from 'bem-cn-lite';

import 'highlight.js/styles/github.css';

import {JupyterCellQa} from '../../../../../../shared/qa';
import './JupyterCellContentLayout.scss';
import {Flex} from '@gravity-ui/uikit';

const b = block('jupyter-cell-content-layout');

export type JupyterCellContentLayoutProps = React.PropsWithChildren<{
    renderHeaderContent?: () => React.ReactNode;
}>;

export function JupyterCellContentLayout({
    children,
    renderHeaderContent,
}: JupyterCellContentLayoutProps) {
    return (
        <Flex qa={JupyterCellQa.JupyterCellSource} className={b()}>
            <div data-qa={JupyterCellQa.JupyterCellSourceHeader} className={b('header')}>
                {renderHeaderContent?.()}
            </div>
            <div className={b('content')}>{children}</div>
        </Flex>
    );
}
