import {useSelector} from 'react-redux';
import {YTDFDialog} from '@ytsaurus-ui-platform/src/ui/components/Dialog';
import React from 'react';
import {FormApi} from '@gravity-ui/dialog-fields';
import {useTractoDispatch} from '../../../../store/tracto-dispatch';
import {selectModalCreateDocumentState} from 'features/Navigation/store/selectors/modals/modal-create-document';
import {modalCreateDocumentSlice} from 'features/Navigation/store/slices/modals/modal-create-document';
import {createBlueprint} from 'features/ImageBuilder/store/actions/api/blueprints';
import {updateView} from '@ytsaurus-ui-platform/src/ui/store/actions/navigation';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';
import {getBlueprintIdFromPath} from '../../../../utils/blueprint';
import {normalizePath} from 'utils/path';

type FormValues = {
    documentName: string;
    documentPath: string;
};

export const BlueprintCreateDialog = () => {
    const dispatch = useTractoDispatch();
    const state = useSelector(selectModalCreateDocumentState);
    const path = useSelector(getPath);

    const handleClose = React.useCallback(() => {
        dispatch(modalCreateDocumentSlice.actions.close());
    }, []);

    const handleIsApplyDisabled = React.useCallback(() => {
        return false;
    }, []);

    const handleAdd = React.useCallback(async (formApi: FormApi<FormValues>) => {
        const {documentName, documentPath} = formApi.getState().values;

        const path = `${normalizePath(documentPath)}${documentName}`;

        await dispatch(
            createBlueprint({
                blueprint_id: getBlueprintIdFromPath(path),
            }),
        );

        await dispatch(modalCreateDocumentSlice.actions.close());

        await dispatch(updateView());
    }, []);

    return (
        <YTDFDialog<FormValues>
            visible={state.documentType === 'blueprint'}
            headerProps={{title: 'Create blueprint'}}
            onClose={handleClose}
            fields={[
                {
                    type: 'text',
                    name: 'documentName',
                    caption: 'Name',
                    required: true,
                    extras: {
                        autoFocus: true,
                    },
                    initialValue: 'new_blueprint',
                },
                {
                    type: 'path',
                    name: 'documentPath',
                    caption: 'Directory',
                    required: true,
                    initialValue: `${path}/`,
                },
            ]}
            onAdd={handleAdd}
            isApplyDisabled={handleIsApplyDisabled}
        />
    );
};
