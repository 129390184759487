import React, {useMemo} from 'react';
import block from 'bem-cn-lite';
import {type ButtonProps, Flex, Icon} from '@gravity-ui/uikit';
import {
    ArrowDown,
    ArrowsRotateRight,
    CirclePlay,
    CircleQuestion,
    CircleStop,
    LogoPython,
} from '@gravity-ui/icons';

import Button from '@ytsaurus-ui-platform/src/ui/components/Button/Button';

import {JupyterStatusContainer} from 'features/Jupyter/containers/JupyterStatusContainer/JupyterStatusContainer';
import {SaveNotebookButtonContainer} from 'features/Jupyter/containers/SaveNotebookButtonContainer/SaveNotebookButtonContainer';
import {JupyterPathActionsContainer} from 'features/Jupyter/containers/JupyterPathActionsContainer/JupyterPathActionsContainer';
import {JupytSelectContainer} from '../../containers/JupytSelectContainer/JupytSelectContainer';
import {JupyterButtonWithHotkeys} from '../JupyterButtonWithHotkeys/JupyterButtonWithHotkeys';

import './JupytOperationsList.scss';
import {getJupyterNotebookPathWithExtension} from '../../utils/notebook';
import {NotebookPageQa} from '../../../../../shared/qa';

const b = block('jupyt-operations-list');

type JupytOperationsListProps = {
    isSuspendedInFlight: boolean;
    isCurrentJupytSuspended: boolean;
    isNotebookRunning: boolean;
    onRunNotebookClick: () => void;
    onStopNotebookClick: () => void;
    onRestartKernelClick: () => void;
    onResumeKernelClick: () => void;
    onHelpClick: () => void;
    currentJupytAlias: string;
    jupytOperationsLink: string;
    path: string;
    notebookDownloadUrl: string;
};

export const JupytOperationsList: React.FC<JupytOperationsListProps> = ({
    isNotebookRunning,
    isSuspendedInFlight,
    isCurrentJupytSuspended,
    onRunNotebookClick,
    onStopNotebookClick,
    onRestartKernelClick,
    currentJupytAlias,
    onResumeKernelClick,
    jupytOperationsLink,
    path,
    notebookDownloadUrl,
    onHelpClick,
}) => {
    const downloadButtonExtraProps: ButtonProps['extraProps'] = useMemo(() => {
        const filename = path.split('/').pop();

        return {
            download: filename ? getJupyterNotebookPathWithExtension(filename) : undefined,
        };
    }, [path]);

    return (
        <Flex direction="row" gap={3} className={b()}>
            {isCurrentJupytSuspended ? (
                <Button
                    className={b('suspended_button')}
                    onClick={onResumeKernelClick}
                    loading={isSuspendedInFlight}
                >
                    <Icon data={CirclePlay} size={13} />
                    Resume
                </Button>
            ) : (
                <JupyterStatusContainer />
            )}
            <JupytSelectContainer />
            <Flex gap={2}>
                <Button href={jupytOperationsLink} target="_blank">
                    <Icon data={LogoPython} size={13} />
                    To kernels
                </Button>
                {currentJupytAlias ? (
                    <Button onClick={onRestartKernelClick}>
                        <Icon data={ArrowsRotateRight} size={13} />
                        Restart session
                    </Button>
                ) : null}
                {isNotebookRunning ? (
                    <Button onClick={onRunNotebookClick}>
                        <Icon data={CirclePlay} size={13} />
                        Run all
                    </Button>
                ) : (
                    <Button onClick={onStopNotebookClick} selected>
                        <Icon data={CircleStop} size={13} />
                        Stop execution
                    </Button>
                )}
                <Button
                    qa={NotebookPageQa.DownloadNotebookButton}
                    extraProps={downloadButtonExtraProps}
                    href={notebookDownloadUrl}
                >
                    <Icon data={ArrowDown} size={13} />
                    Download
                </Button>
                <SaveNotebookButtonContainer />
                <JupyterButtonWithHotkeys
                    view="outlined"
                    popupPlacement="top"
                    id="showHelp"
                    onClick={onHelpClick}
                >
                    <Icon data={CircleQuestion} size={13} />
                </JupyterButtonWithHotkeys>
                <JupyterPathActionsContainer />
            </Flex>
        </Flex>
    );
};
