// eslint-disable-next-line lodash/import-scope
import type {TemplateExecutor} from 'lodash';
import type {SQLTemplates} from '../../types/sql/temaplate';
import _template from 'lodash/template';
import type {SQLCellViewType} from '../../types/cell/sql';
import {NotebookCellType} from '../../constants/cell';
import type {TractoNotebookCellSQLVariables} from '../../types/version';
import {sourceToString} from '../cell/source';

export class NotebookSQLHelper {
    private static readonly SQL_VIEW_TYPE_TO_TEMPLATE_KEY: Record<
        SQLCellViewType,
        keyof SQLTemplates
    > = {
        [NotebookCellType.YQL]: 'yql',
        [NotebookCellType.QL]: 'ql',
        [NotebookCellType.SPYT]: 'spyt',
        [NotebookCellType.CHYT]: 'chyt',
    };

    private static templates: Record<string, TemplateExecutor> = {};

    static template(key: keyof SQLTemplates, code: string) {
        this.templates[key] = _template(code, {interpolate: /\${([\s\S]+?)}/g});
    }

    static getQuery(key: keyof SQLTemplates, variables: TractoNotebookCellSQLVariables): string {
        const preparedQuery = sourceToString(variables.query).replace(/\n/g, ' ');

        return this.templates[key]({
            ...variables,
            query: preparedQuery,
        } satisfies TractoNotebookCellSQLVariables);
    }

    static getTemplateKey(viewType: SQLCellViewType): keyof SQLTemplates {
        return this.SQL_VIEW_TYPE_TO_TEMPLATE_KEY[viewType];
    }
}
