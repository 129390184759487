import React from 'react';
import block from 'bem-cn-lite';
import {Icon, Label, Text} from '@gravity-ui/uikit';
import {Eye} from '@gravity-ui/icons';
import {WorkflowStepState, WorkflowStepType} from 'features/Orchestracto/types/workflow';
import Button from '@ytsaurus-ui-platform/src/ui/components/Button/Button';
import {Graph, GraphBlock} from '@gravity-ui/graph';
import './WorkflowStepComponent.scss';
import {WorkflowStepExecutionStatus} from '../WorkflowStepExecutionStatus/WorkflowStepExecutionStatus';

import type {WorkflowTBlock} from '../../libs/graph/canvas/WorkflowBlock';

const b = block('workflow-step-component');

export type WorkflowStepComponentProps = {
    graph: Graph;
    block: WorkflowTBlock;
    step: WorkflowStepType;
    onShowDetailsClick?: () => void;
    workflowState: WorkflowStepState | undefined;
};

export const WorkflowStepComponent: React.FC<WorkflowStepComponentProps> = ({
    graph,
    block,
    step,
    workflowState,
    onShowDetailsClick,
}) => {
    const headerMixins = workflowState
        ? {
              stage: workflowState.stage,
              state: workflowState.final_state,
          }
        : null;

    const fromCacheLabel = workflowState?.from_cache ? (
        <div className={b('label-wrapper')}>
            <Label theme="success">from cache</Label>
        </div>
    ) : null;

    return (
        <GraphBlock graph={graph} block={block} className={b()}>
            <div className={b('header', headerMixins)}>
                {workflowState && <WorkflowStepExecutionStatus {...workflowState} />}
                <span className={b('title')}>
                    <Text variant="subheader-3">ID:</Text>&nbsp;
                    <Text variant="subheader-2">{step.step_id}</Text>
                </span>
            </div>
            <div className={b('content')}>
                <div className={b('label-wrapper')}>
                    <Label theme="info" value={step.task_type}>
                        Type
                    </Label>
                </div>
                {fromCacheLabel}
                <Button className={b('details')} onClick={onShowDetailsClick}>
                    <Icon data={Eye} />
                </Button>
            </div>
        </GraphBlock>
    );
};
