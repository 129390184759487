import React from 'react';
import type {TractoNotebookMarkdownCell} from '../../types/version';
import {
    JupyterMarkdownCell,
    type JupyterMarkdownCellProps,
} from '../../components/JupyterCell/JupyterMarkdownCell/JupyterMarkdownCell';
import {useJupyterCell} from './hooks/use-jupyter-cell';
import {useTractoDispatch} from '../../../../store/tracto-dispatch';
import {notebookSlice} from '../../store/slices/notebook';

const useJupyterMarkdownCellContainer = (
    cell: TractoNotebookMarkdownCell,
    cellIndex: number,
): JupyterMarkdownCellProps => {
    const dispatch = useTractoDispatch();

    const {onSourceChange, onClick, isFocused, isEditable, onEditorFocus, onEditorBlur} =
        useJupyterCell(cell);

    const cellId = cell.id;

    const onImagePaste = React.useCallback(
        ({name, base64, type}: {name: string; base64: string; type: string}) => {
            dispatch(
                notebookSlice.actions.setCellAttachment({
                    cellId,
                    name,
                    base64,
                    type,
                }),
            );
        },
        [cellId],
    );

    const onDoubleClick = React.useCallback(() => {
        dispatch(notebookSlice.actions.makeCellEditable());
    }, [cellId]);

    return {
        cell,
        index: cellIndex,
        onSourceChange,
        onClick,
        isFocused,
        isEditable,
        onEditorFocus,
        onImagePaste,
        onDoubleClick,
        onEditorBlur,
    };
};

type JupyterMarkdownCellContainerProps = {
    cell: TractoNotebookMarkdownCell;
    cellIndex: number;
};

export const JupyterMarkdownCellContainer: React.FC<JupyterMarkdownCellContainerProps> = (
    props: JupyterMarkdownCellContainerProps,
) => {
    const {cell, cellIndex} = props;

    return <JupyterMarkdownCell {...useJupyterMarkdownCellContainer(cell, cellIndex)} />;
};
