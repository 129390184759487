import Link from '@ytsaurus-ui-platform/src/ui/components/Link/Link';
import {
    selectCurrentRun,
    selectCurrentRunHasSelectedSteps,
} from 'features/Orchestracto/store/selectors/runs';
import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Button from '@ytsaurus-ui-platform/src/ui/components/Button/Button';
import {
    userRestartWorkflowRun,
    userStopWorkflowRun,
} from 'features/Orchestracto/store/actions/workflow';
import {getCluster} from '@ytsaurus-ui-platform/src/ui/store/selectors/global';
import block from 'bem-cn-lite';
import './WorkflowExecutionGraphToolbar.scss';
import {isPersistentWorkflowRunStatus} from '../../../utils/workflow';
import {ShowWorkflowRunDetailsButton} from '../WokflowRunDetailsModal/WokflowRunDetailsModal';

const b = block('workflow-execution-graph-toolbar');

export const WorkflowExecutionGraphToolbar: React.FC = () => {
    const dispatch = useDispatch();
    const workflowExecution = useSelector(selectCurrentRun);
    const currentRunHasSelectedSteps = useSelector(selectCurrentRunHasSelectedSteps);
    const cluster = useSelector(getCluster);
    const ytOperationId = workflowExecution?.orcApiRun.yt_operation_id;

    const ytOperationLink = useMemo(() => {
        if (!ytOperationId) {
            return '';
        }

        return `/${cluster}/operations/${ytOperationId}/details`;
    }, [cluster, ytOperationId]);

    const onRestartFailedClick = React.useCallback(() => {
        dispatch(userRestartWorkflowRun({restartAll: false}));
    }, []);

    const onRestartAllClick = React.useCallback(() => {
        dispatch(userRestartWorkflowRun({restartAll: true}));
    }, []);

    const onStopRunClick = React.useCallback(() => {
        dispatch(userStopWorkflowRun());
    }, []);

    if (!workflowExecution) {
        return null;
    }

    const workflowCanBeStopped = workflowExecution.orcApiRun.stage === 'running';

    const workflowCanBeRestarted = isPersistentWorkflowRunStatus(workflowExecution.orcApiRun.stage);

    return (
        <div className={b()}>
            <div className={b('left-section')}>
                {ytOperationLink ? <Link url={ytOperationLink}>Link to operation</Link> : null}
                <ShowWorkflowRunDetailsButton />
            </div>

            <div className={b('right-section')}>
                <Button
                    onClick={onStopRunClick}
                    disabled={!workflowCanBeStopped}
                    view="outlined-danger"
                >
                    Cancel
                </Button>
                <Button onClick={onRestartFailedClick} disabled={!workflowCanBeRestarted}>
                    Restart failed
                </Button>
                <Button
                    onClick={onRestartAllClick}
                    disabled={!workflowCanBeRestarted}
                    className={b('restart-all')}
                >
                    {currentRunHasSelectedSteps ? 'Restart selected' : 'Restart all'}
                </Button>
            </div>
        </div>
    );
};
