/* eslint-disable new-cap */
import type {DialogField} from '@ytsaurus-ui-platform/src/ui/components/Dialog';
import format from '@ytsaurus-ui-platform/src/ui/common/hammer/format';
import type {UnipikaSettings} from '@ytsaurus-ui-platform/src/ui/components/Yson/StructuredYson/StructuredYsonTypes';
import type {ControlField} from '@gravity-ui/dialog-fields';
import type {JupytSpecletUIFormConfig} from '../../api/jupyt';
import type {DockerImageInputValue} from '../../../../components/DockerImageInput/types';
import type {
    Option,
    OptionDescription,
    OptionsGroup,
} from '@ytsaurus-ui-platform/src/ui/components/Dialog/df-dialog-utils';
import type {TractoDialogField} from '../../../../types/dialog-fields/fields';
import type {TractoOptionDescription} from '../../../../types/dialog-fields/description-options';

const getFormattedNumber = (defaultValue: number, type: 'uint64' | 'int64' | 'byte_count') =>
    type === 'byte_count' ? format.Bytes(defaultValue) : format.Number(defaultValue);

export const getCommonDialogField = (option: OptionDescription | Option<'duration', number>) => {
    return {
        name: option.name,
        caption: format.ReadableField(option.name),
        tooltip: option.description,
    };
};

export const getNumberDialogField = (
    option: Option<'uint64' | 'int64' | 'byte_count', number> & {
        max_value?: number;
        min_value?: number;
    },
): DialogField<'number'> => {
    const valueFormat = option.type === 'byte_count' ? 'Bytes' : undefined;

    const placeholder =
        option.default_value === undefined
            ? undefined
            : getFormattedNumber(option.default_value, option.type);

    return {
        ...getCommonDialogField(option),
        type: 'number',
        extras: {
            hidePrettyValue: true,
            placeholder,
            min: option.min_value,
            max: option.max_value,
            format: valueFormat,
            integerOnly: true,
            showHint: true,
        },
    };
};

export const getTumblerDialogField = (option: Option<'bool', boolean>): DialogField<'tumbler'> => {
    return {
        ...getCommonDialogField(option),
        type: 'tumbler',
    };
};

export const getDurationDialogField = (
    option: Option<'duration', number>,
): TractoDialogField<'duration'> => {
    return {
        ...getCommonDialogField(option),
        type: 'duration',
    };
};

export const getDialogFieldFromOption = (
    option: TractoOptionDescription,
): TractoDialogField<any> => {
    switch (option.type) {
        case 'bool':
            return getTumblerDialogField(option);
        case 'duration':
            return getDurationDialogField(option);
        case 'uint64':
        case 'int64':
        case 'byte_count':
            return getNumberDialogField(option);
        default:
            throw new Error(`Unsupported option type: ${option.type}`);
    }
};

export type DescribeOptionsConverter<T> = {
    toFieldValue(value: any): T;
    fromFieldValue(
        value: any,
        oldV?: any,
    ): {value: any; uiConfig?: Partial<JupytSpecletUIFormConfig['tracto_ui_form_config']>};
};

function getDockerImageInputItem<T = unknown>(
    item: Record<string, any>,
    options: {value: string; uiConfig: MakeDialogFieldsOptions['uiConfig']},
): DialogField<T> & {initialValue?: unknown; converter: Converter} {
    return {
        ...item,
        required: true,
        converter: CONVERTER.jupyter_docker_image,
        //@ts-expect-error
        // Docker image input is not defined in opensource list of DialogField.
        // Since we have not copied the dialog and types, we expect this error.
        type: 'docker-image-input',
        initialValue: {
            image: options.value,
            option: options.uiConfig?.docker_image?.option ?? 'registry',
            path: options.uiConfig?.docker_image?.path,
        },
    };
}

export function tractoDescriptionToDialogField<T = unknown>(
    item: OptionDescription,
    {unipikaSettings, allowEdit, defaultPoolTree, uiConfig}: MakeDialogFieldsOptions,
): DialogField<T> & {initialValue?: unknown; converter: Converter} {
    const common = {
        name: item.name,
        caption: format.ReadableField(item.name),
        tooltip: item.description,
        converter: tractoMakeConverter<any>(),
    };
    const {default_value} = item;
    const extras = {
        disabled: !allowEdit,
        placeholder:
            default_value !== null && default_value !== undefined
                ? String(item.default_value)
                : undefined,
    };

    switch (item.type) {
        case 'string': {
            if (!item.choices?.length) {
                return item.name === 'jupyter_docker_image'
                    ? getDockerImageInputItem(
                          {...common, extras},
                          {value: item.current_value ?? '', uiConfig},
                      )
                    : {
                          ...common,
                          type: 'text',
                          extras,
                      };
            } else {
                return {
                    ...common,
                    type: 'select',
                    extras: {
                        ...extras,
                        width: 'max',
                        options: item.choices.map((value) => {
                            return {value, content: value};
                        }),
                        hasClear: true,
                    },
                    converter: CONVERTER.string_with_choices,
                };
            }
        }
        case 'bool':
            return {
                ...common,
                type: 'tumbler',
                extras,
                initialValue: item.current_value ?? item.default_value,
            };
        case 'int64':
        case 'uint64':
        case 'byte_count': {
            const valueFormat = item.type === 'byte_count' ? 'Bytes' : undefined;
            const defaultFormatted =
                valueFormat === 'Bytes'
                    ? format.Bytes(item.default_value)
                    : format.Number(item.default_value);
            return {
                ...common,
                type: 'number',
                extras: {
                    ...extras,
                    hidePrettyValue: true,
                    placeholder: item.default_value !== undefined ? defaultFormatted : undefined,
                    min: item.min_value,
                    max: item.max_value,
                    format: valueFormat,
                    integerOnly: true,
                    showHint: true,
                },
                converter: CONVERTER.number,
            };
        }
        case 'yson':
            return {
                ...common,
                type: 'json',
                fullWidth: true,
                extras: {
                    ...extras,
                    unipikaSettings,
                    minHeight: 200,
                    nullPreview: item.default_value,
                },
                converter: CONVERTER.json,
            };
        case 'path':
            return {...common, type: 'path', extras};
        case 'pool':
            return {
                ...common,
                type: 'pool',
                extras: {...extras, allowEmpty: true},
                initialValue: item.current_value ?? item.default_value,
            };
        case 'pool_trees':
            return {
                ...common,
                type: 'pool-tree',
                extras,
                initialValue: item.current_value ?? item.default_value ?? [defaultPoolTree],
            };
        //@ts-expect-error
        case 'duration': {
            //@ts-expect-error
            const value = Number(item.current_value ?? item.default_value);
            return {
                ...common,
                type: 'duration' as any,
                initialValue: isNaN(value)
                    ? undefined
                    : // default value for duration is nanoseconds.
                      value / 1_000_000,
            };
        }
        default:
            return {...common, type: 'plain'};
    }
}

function tractoMakeConverter<T>(): DescribeOptionsConverter<T> {
    return {
        toFieldValue(value: any) {
            return value as T;
        },
        fromFieldValue(value: any, _oldV?: any) {
            return {value};
        },
    };
}

const CONVERTER: Record<string, ReturnType<typeof tractoMakeConverter>> = {
    number: {
        toFieldValue(value: unknown) {
            return {value: value === null ? undefined : (value as number | undefined)};
        },
        fromFieldValue(value: any, _oldV?: any) {
            return {value: value?.value};
        },
    },
    json: {
        toFieldValue(value: unknown) {
            return {value: value !== undefined ? JSON.stringify(value, null, 2) : undefined};
        },
        fromFieldValue(value: any, oldV?: any) {
            try {
                return {value: JSON.parse(value.value)};
            } catch {
                return {value: oldV};
            }
        },
    },
    plain: {
        toFieldValue(value: unknown) {
            return JSON.stringify(value);
        },
        fromFieldValue(value: any, _oldV?: any) {
            return {value: value !== undefined ? JSON.parse(value) : undefined};
        },
    },
    string_with_choices: {
        toFieldValue(value: string) {
            return value ? [value] : [];
        },
        fromFieldValue(value: Array<string>, _oldV?: any) {
            return {value: value?.[0]};
        },
    },
    jupyter_docker_image: {
        toFieldValue(value: string) {
            return value;
        },
        fromFieldValue(value: DockerImageInputValue | string, _oldV?: string) {
            return typeof value === 'string'
                ? {value: value}
                : ({
                      value: value.image,
                      uiConfig: {docker_image: {option: value.option, path: value.path}},
                  } satisfies {
                      value: string;
                      uiConfig?: Partial<JupytSpecletUIFormConfig['tracto_ui_form_config']>;
                  });
        },
    },
};

function tractoConverterByType(item: OptionDescription) {
    return CONVERTER[item.type] ?? tractoMakeConverter<any>();
}

type Converter = ReturnType<typeof tractoConverterByType>;

function tractoMakeDialogField<FormValues = any>(
    item: OptionDescription,
    dstInitialValues: any,
    dstConvertersByName: Record<string, {type: DialogField['type']; converter: Converter}>,
    options: MakeDialogFieldsOptions,
) {
    const {initialValue, converter, ...res} = tractoDescriptionToDialogField<FormValues>(
        item,
        options,
    );
    const {type} = res;

    dstInitialValues[item.name] = initialValue ?? converter.toFieldValue(item.current_value);
    dstConvertersByName[item.name] = {type: type!, converter};

    return res;
}

type MakeDialogFieldsOptions = {
    allowEdit: boolean;
    unipikaSettings: UnipikaSettings;
    defaultPoolTree: string;
    uiConfig?: JupytSpecletUIFormConfig['tracto_ui_form_config'];
};

export function tractoMakeTabbedDialogFieldsFromDescription<
    FormValues extends Record<string, Record<string, unknown>> = Record<
        string,
        Record<string, unknown>
    >,
>(data: Array<OptionsGroup>, options: MakeDialogFieldsOptions) {
    const initialValues: Partial<FormValues> = {};
    const typeByName: Record<
        string,
        {type: DialogField['type']; converter: ReturnType<typeof tractoMakeConverter>}
    > = {};
    return {
        fieldTypeByName: typeByName,
        initialValues: initialValues,
        fields: data?.map((group, index) => {
            const group_name = `group_${index}`;
            const groupInitialValues = ((initialValues as any)[group_name] = {});
            const sectionFields: Array<DialogField<FormValues>> = group.options.map((item) => {
                return tractoMakeDialogField(item, groupInitialValues, typeByName, options);
            });

            return {
                name: group_name,
                title: group.title,
                type: 'tab-vertical' as const,
                fields: sectionFields,
            };
        }),
    };
}

export function tractoLinkPoolWithPoolTree<
    FormValues extends Record<string, Record<string, unknown>> = Record<
        string,
        Record<string, unknown>
    >,
>(data: ReturnType<typeof tractoMakeTabbedDialogFieldsFromDescription>) {
    for (const group of data.fields) {
        type FieldType = (typeof data)['fields'][number]['fields'][number];
        type FieldPoolType = FieldType & {type: 'pool'};
        type FieldPoolTreesType = FieldType & {type: 'pool-tree'};

        let pool: undefined | FieldPoolType;
        let poolTrees: undefined | FieldPoolTreesType;

        group.fields.some((field) => {
            if (field.type === 'pool-tree') {
                poolTrees = field;
            }
            if (field.type === 'pool') {
                pool = field;
            }
            return Boolean(pool && poolTrees);
        });

        if (pool && poolTrees) {
            const extras = pool.extras;
            (pool as ControlField).extras = (values: FormValues) => ({
                ...extras,
                poolTrees: values[group.name].pool_trees,
            });
        }
    }
}
