import React, {useCallback} from 'react';
import {WorkflowEditorComponent} from 'features/Orchestracto/components/WorkflowEditorComponent/WorkflowEditorComponent';
import {
    selectIsWorkflowChanged,
    selectWorkflow,
} from 'features/Orchestracto/store/selectors/workflow';
import {useSelector} from 'react-redux';
import {workflowSlice} from 'features/Orchestracto/store/slices/workflow';
import type {editor} from 'monaco-editor';
import {useTractoDispatch} from '../../../../store/tracto-dispatch';
import {useNavigationBlockerWithTabs} from 'hooks/useNavigationBlockerWithTabs';
import {WorkflowDetailsContainer} from '../WorkflowDetailsContainer/WorkflowDetailsContainer';
import {Flex} from '@gravity-ui/uikit';
import {useMonacoExtensions} from '../../../../hooks/useMonacoExtensions';
import {getWorkflowMonacoExtensions} from '../../../../components/TractoMonacoEditor/extensions';

export const WorkflowEditorContainer: React.FC = () => {
    const dispatch = useTractoDispatch();

    const workflow = useSelector(selectWorkflow);

    const isWorkflowChanged = useSelector(selectIsWorkflowChanged);

    useMonacoExtensions(getWorkflowMonacoExtensions);

    const onChange = useCallback((value: string) => {
        dispatch(
            workflowSlice.actions.setWorkflow({
                workflow: value,
            }),
        );
    }, []);

    const onValidate = useCallback(
        (markers: editor.IMarker[]) => {
            dispatch(
                workflowSlice.actions.setMarkers({
                    markers,
                }),
            );
        },
        [workflow],
    );

    const shouldPreventNavigation = isWorkflowChanged;

    useNavigationBlockerWithTabs(shouldPreventNavigation);

    return (
        <Flex width="100%" direction="column" gap="4">
            <WorkflowDetailsContainer />
            <WorkflowEditorComponent
                initialValue={workflow}
                onChange={onChange}
                onValidate={onValidate}
            />
        </Flex>
    );
};
