import type {
    TractoNotebookContent,
    UnrecognizedTractoNotebookContent,
} from '../../../types/version';
import {mapNotebookBaseToLatestVersion} from './notebook-base';
import {mapNotebookCellToLatestVersion} from './notebook-cells';

export const mapNotebookToLatestVersion = async (
    externalNotebook: UnrecognizedTractoNotebookContent,
): Promise<TractoNotebookContent> => {
    const {cells, ...base} = externalNotebook;

    const latestBase = mapNotebookBaseToLatestVersion(base);
    const latestCells = await Promise.all((cells ?? []).map(mapNotebookCellToLatestVersion));

    return {
        ...latestBase,
        cells: latestCells,
    };
};
