import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

export const fetchImage = createAsyncThunk(
    'tracto-registry/fetchImages',
    async (opts: {cluster: string; repository: string}) => {
        return axios
            .get(`/${opts.cluster}/tracto-registry/v2/${opts.repository}/tags/list?n=50`)
            .then((res) => res.data as unknown as RepositoryTag);
    },
);

export const deleteImage = createAsyncThunk(
    'tracto-registry/DeleteImages',
    async (opts: {cluster: string; repository: string; tag: string}) => {
        return axios
            .delete(`/${opts.cluster}/tracto-registry/v2/${opts.repository}/manifests/${opts.tag}`)
            .then(() => ({tag: opts.tag}));
    },
);

export const getPublicAccessAttr = createAsyncThunk(
    'tracto-registry/GetPublicAccessAttr',
    async (opts: {cluster: string; repository: string}) => {
        return axios
            .get(`/${opts.cluster}/tracto-registry/api/image/${opts.repository}/access/public`)
            .then((value) => {
                return value.data?.['allow_anonymous_pull'];
            });
    },
);

export const setPublicAccessAttr = createAsyncThunk(
    'tracto-registry/SetPublicAccessAttr',
    async (opts: {cluster: string; repository: string; value: boolean}) => {
        return axios
            .post(`/${opts.cluster}/tracto-registry/api/image/${opts.repository}/access/public`, {
                allow_anonymous_pull: opts.value,
            })
            .then(() => {
                return opts.value;
            });
    },
);

type FetchStatus = 'idle' | 'loading' | 'failed';

export interface RepositoryTag {
    name: string;
    tags: string[];
}

interface ImageSliceState {
    searchParams: {
        digest?: string;
    };
    tags: string[];
    repository: string;
    status: FetchStatus;
    deleteStatus: FetchStatus;
    allowAnonymousPull: boolean;
}

const initialState = {
    searchParams: {},
    repository: '',
    tags: [],
    status: 'idle',
    deleteStatus: 'idle',
    allowAnonymousPull: false,
} satisfies ImageSliceState as ImageSliceState;

export const imageSlice = createSlice({
    name: 'image',
    initialState: initialState,
    reducers: {
        toggleTag: (state, action) => {
            if (state.searchParams.digest === action.payload.digest) {
                state.searchParams.digest = undefined;
            } else {
                state.searchParams.digest = action.payload.digest;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchImage.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchImage.fulfilled, (state, action) => {
                state.status = 'idle';
                state.tags = action.payload.tags;
                state.repository = action.payload.name;
            })
            .addCase(fetchImage.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(deleteImage.pending, (state) => {
                state.deleteStatus = 'loading';
            })
            .addCase(deleteImage.fulfilled, (state, action) => {
                state.deleteStatus = 'idle';
                state.tags = state.tags.filter((tag) => tag !== action.payload.tag);
            })
            .addCase(deleteImage.rejected, (state) => {
                state.deleteStatus = 'failed';
            })
            .addCase(getPublicAccessAttr.fulfilled, (state, action) => {
                state.allowAnonymousPull = action.payload;
            })
            .addCase(setPublicAccessAttr.fulfilled, (state, action) => {
                state.allowAnonymousPull = action.payload;
            });
    },
});
