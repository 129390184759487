import {createTractoAsyncThunk} from '../../../../../store/tracto-async-thunk';
import {WorkflowRunData} from '../../../types/runs';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';
import {OrchestractoApi} from '../../../api';
import type {WorkflowExecutionStage, WorkflowStepState} from '../../../types/workflow';
import {runsSlice} from '../../slices/runs';
import CancelHelper from '@ytsaurus-ui-platform/src/ui/utils/cancel-helper';

const cancelHelper = new CancelHelper();

export const loadWorkflowRunState = createTractoAsyncThunk<WorkflowRunData, {runId: string}>(
    'orchestracto.actions.load-workflow-state',
    async ({runId}, thunkAPI): Promise<WorkflowRunData> => {
        const path = getPath(thunkAPI.getState());

        const response = await OrchestractoApi.api.runs.getRun({
            runId,
            workflowPath: path,
            cancelToken: cancelHelper.removeAllAndGenerateNextToken(),
        });

        const workflowStepsStateMap: Record<string, WorkflowStepState> = {};

        // eslint-disable-next-line guard-for-in
        for (const key in response.workflow_execution_state.exec_stages) {
            const stage = key as WorkflowExecutionStage;

            // eslint-disable-next-line guard-for-in
            for (const task in response.workflow_execution_state.exec_stages[stage]) {
                const execStage = response.workflow_execution_state.exec_stages[stage][task];

                workflowStepsStateMap[task] = {
                    stage,
                    ...execStage,
                };
            }
        }

        const currentRun: WorkflowRunData = {
            orcApiRun: response,
            workflowStepsStateMap,
        };

        thunkAPI.dispatch(runsSlice.actions.setCurrentRun({currentRun}));

        return currentRun;
    },
);
