import React from 'react';
import block from 'bem-cn-lite';

import './WorkflowEditorComponent.scss';
import TractoMonacoEditor from 'components/TractoMonacoEditor/TractoMonacoEditor';
import type {editor} from 'monaco-editor';

const b = block('workflow-editor');

type WorkflowEditorComponentProps = {
    initialValue: string;
    onChange: (value: string) => void;
    onValidate: (markers: editor.IMarker[]) => void;
};

export const WorkflowEditorComponent: React.FC<WorkflowEditorComponentProps> = ({
    initialValue,
    onChange,
    onValidate,
}) => {
    return (
        <div className={b()}>
            <TractoMonacoEditor
                className={b('editor')}
                value={initialValue}
                onChange={onChange}
                language="json"
                adjustToContent={false}
                onValidate={onValidate}
            />
        </div>
    );
};
