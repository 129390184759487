import React from 'react';
import {CellDropdown} from '../controls/CellDropdown/CellDropdown';
import {dropdownButtonProps} from './constants';
import type {BoundDropdownWithHotkeysItem} from '../../../../../libs/hotkeys';
import type {JUPYTER_HOTKEYS} from '../../../constants/hotkeys';

type JupyterCellDropdownProps = {
    items: BoundDropdownWithHotkeysItem<typeof JUPYTER_HOTKEYS>[];
    onDropdownButtonClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

export const JupyterCellDropdown: React.FC<JupyterCellDropdownProps> = (
    props: JupyterCellDropdownProps,
) => {
    const {items, onDropdownButtonClick} = props;

    const buttonProps = React.useMemo(
        () => ({
            ...dropdownButtonProps,
            onClick: onDropdownButtonClick,
        }),
        [onDropdownButtonClick],
    );

    return <CellDropdown items={items} button={buttonProps} />;
};
