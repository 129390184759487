import {Page} from '@ytsaurus-ui-platform/src/ui/constants';
import {TractoYT} from 'config/ui-config';

export const getWorkflowRunUrl = ({path, runId}: {path: string; runId: string}) => {
    const searchParams = new URLSearchParams();

    searchParams.set('path', path);
    searchParams.set('navmode', 'extra_workflow_runs');
    searchParams.set('runId', runId);

    const url = `/${TractoYT.cluster}/${Page.NAVIGATION}?${searchParams.toString()}`;

    return url;
};
