import {type PayloadAction, createSlice} from '@reduxjs/toolkit';
import type {JupytSecretsMapType} from '../../../../../shared/types/jupyt/secrets';

type InitialState = {
    secrets: JupytSecretsMapType | null;
};

const initialState: InitialState = {
    secrets: null,
};

export const secretsSlice = createSlice({
    name: 'secrets',
    initialState,
    reducers: {
        setSecrets(state, action: PayloadAction<{secrets: JupytSecretsMapType | null}>) {
            state.secrets = action.payload.secrets;
        },
        resetSecretsState() {
            return initialState;
        },
    },
});
