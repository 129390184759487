import {createTractoAsyncThunk} from '../../../../../store/tracto-async-thunk';
import {selectModalJupytSpecletState} from '../../selectors/modals';
import {modalJupytSpecletSlice} from '../../slices/modals/modal-jupyt-speclet';

const SHOW_ASYNC_CREATE_JUPYT_MODAL = 'SHOW_ASYNC_CREATE_JUPYT_MODAL';

export const asyncOpenCreateJupytDialog = createTractoAsyncThunk(
    SHOW_ASYNC_CREATE_JUPYT_MODAL,
    (_, thunkAPI) => {
        thunkAPI.dispatch(modalJupytSpecletSlice.actions.open({type: 'create'}));
        const store = window.store;

        return new Promise<boolean>((resolve) => {
            const unsubscribe = store.subscribe(() => {
                const state = store.getState();
                const modalState = selectModalJupytSpecletState(state);
                if (!modalState.visible) {
                    unsubscribe();
                    resolve(!modalState.isCanceled);
                }
            });
        });
    },
);
