import type {StrawberryDescribeOptionsType} from '@ytsaurus-ui-platform/src/ui/utils/strawberryControllerApi';
import type {JupytSpecletUIFormConfig} from '../../../../api/jupyt';
import type {DockerImageInputValue} from '../../../../../../components/DockerImageInput/types';

const PARAMS_INDEX = 1;

export const selectDockerImageInitialValue = (
    describeOptions: StrawberryDescribeOptionsType,
    uiConfig: JupytSpecletUIFormConfig['tracto_ui_form_config'] | undefined,
): DockerImageInputValue => {
    const dockerImage = describeOptions[PARAMS_INDEX].options.find(
        (option) => option.name === 'jupyter_docker_image',
    );

    const image = `${dockerImage?.current_value ?? dockerImage?.default_value}`;

    return {
        image,
        option: uiConfig?.docker_image?.option ?? 'registry',
        path: uiConfig?.docker_image?.path,
    };
};
