import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import {selectCurrentJupytAlias} from 'features/Jupyter/store/selectors/notebook';
import {getCluster} from '@ytsaurus-ui-platform/src/ui/store/selectors/global';
import {JupyterApi} from 'features/Jupyter/api/kernel';
import {prepareJupyterSuggestions} from 'components/TractoMonacoEditor/helpers/suggestions';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';

export const setupMonacoAutocompleteExtension = () => {
    return monaco.languages.registerCompletionItemProvider('python', {
        triggerCharacters: ['.'],
        async provideCompletionItems(model, position) {
            const code = model.getValue();
            const cursor = model.getOffsetAt(position);

            const state = window.store.getState();

            const alias = selectCurrentJupytAlias(state);
            const cluster = getCluster(state);
            const path = getPath(state);

            if (!alias) {
                return Promise.resolve({suggestions: []});
            }

            const word = model.getWordUntilPosition(position);
            const range: monaco.IRange = {
                startLineNumber: position.lineNumber,
                startColumn: word.startColumn,
                endLineNumber: position.lineNumber,
                endColumn: word.endColumn,
            };

            const result = await JupyterApi.getCompletion(code, cursor, {alias, cluster, path});
            if (result.content.status === 'ok') {
                const content = result.content;
                const matches = content.matches;
                const experimentMatches: any = content.metadata
                    ? content.metadata._jupyter_types_experimental ?? []
                    : [];

                return Promise.resolve({
                    suggestions: prepareJupyterSuggestions(model, {
                        matches,
                        range,
                        experimentMatches,
                    }),
                });
            }

            return Promise.resolve({suggestions: []});
        },
    });
};
