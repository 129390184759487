import React, {useCallback} from 'react';
import {useTractoDispatch} from '../../../../store/tracto-dispatch';
import {useSelector} from 'react-redux';
import {TractoRootState} from '../../../../store/reducers';
import type {TractoNotebookCell} from '../../types/version';
import {selectNotebookCells} from '../../store/selectors/notebook';
import {getCellView} from '../../utils/cell/view';
import {withFocusBeforeCellAction} from '../../utils/notebook';
import {notebookSlice} from '../../store/slices/notebook';
import {NotebookCellType} from '../../constants/cell';
import {addNotebookCell, changeCellType} from '../../store/actions/notebook/cell';
import {extractCellId} from '../../utils/cell/common';
import type {BoundDropdownWithHotkeysItem} from '../../../../libs/hotkeys';
import type {JUPYTER_HOTKEYS} from '../../constants/hotkeys';
import {JupyterCellCommonControls} from '../../components/JupyterCellControls/JupyterCellCommonControls/JupyterCellCommonControls';
import {useCellControls} from '../../hooks/use-cell-controls';
const useJupyterCellCommonControlsContainer = (cellIndex: number) => {
    const dispatch = useTractoDispatch();

    const cell = useSelector<TractoRootState, TractoNotebookCell>(
        (state) => selectNotebookCells(state)[cellIndex],
    );

    const cellId = extractCellId(cell);

    const cellView = getCellView(cell, 'common');
    const cellViewType = cellView.view_cell_type;

    const {onControlClick} = useCellControls({cellId});

    const onTrashBinClick = useCallback(
        withFocusBeforeCellAction(dispatch, cellId, (event: React.MouseEvent<HTMLElement>) => {
            onControlClick(event);
            dispatch(notebookSlice.actions.deleteCell({currentIndex: cellIndex}));
        }),
        [cellIndex, cellId, onControlClick],
    );

    const onArrowUpClick = useCallback(
        withFocusBeforeCellAction(dispatch, cellId, (event: React.MouseEvent<HTMLElement>) => {
            onControlClick(event);
            dispatch(notebookSlice.actions.moveCellUp({currentIndex: cellIndex}));
        }),
        [cellIndex, cellId, onControlClick],
    );

    const onArrowDownClick = useCallback(
        withFocusBeforeCellAction(dispatch, cellId, (event: React.MouseEvent<HTMLElement>) => {
            onControlClick(event);
            dispatch(notebookSlice.actions.moveCellDown({currentIndex: cellIndex}));
        }),
        [cellIndex, cellId, onControlClick],
    );

    const onChangeCellType = useCallback(
        withFocusBeforeCellAction(dispatch, cellId, (options: string[]) => {
            const [type] = options as NotebookCellType[];

            dispatch(
                changeCellType({
                    cellId,
                    type,
                }),
            );
        }),
        [cellId],
    );

    const onAddCellBelowClick = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            onControlClick(event);
            dispatch(
                addNotebookCell({
                    currentIndex: cellIndex,
                    type: NotebookCellType.CODE,
                }),
            );
        },
        [cellIndex, onControlClick],
    );

    const onAddCellAboveClick = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            onControlClick(event);
            dispatch(
                addNotebookCell({
                    currentIndex: cellIndex - 1,
                    type: NotebookCellType.CODE,
                }),
            );
        },
        [cellIndex, onControlClick],
    );

    const onChangeCellTypeControlClick = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            onControlClick(event);
        },
        [onControlClick],
    );

    const onDropdownButtonClick = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            onControlClick(event);
        },
        [onControlClick],
    );

    return {
        onAddCellAboveClick,
        onAddCellBelowClick,
        cellViewType,
        onChangeCellType,
        onArrowUpClick,
        onArrowDownClick,
        onTrashBinClick,
        onChangeCellTypeControlClick,
        onDropdownButtonClick,
    };
};

type JupyterCellCommonControlsContainerProps = {
    cellIndex: number;
    dropdownItems: BoundDropdownWithHotkeysItem<typeof JUPYTER_HOTKEYS>[];
};

export const JupyterCellCommonControlsContainer: React.FC<JupyterCellCommonControlsContainerProps> =
    React.memo((props: JupyterCellCommonControlsContainerProps) => {
        return (
            <JupyterCellCommonControls
                {...useJupyterCellCommonControlsContainer(props.cellIndex)}
                dropdownItems={props.dropdownItems}
            />
        );
    });
