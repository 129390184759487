import type {Kernel} from '@jupyterlab/services';
import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import {JupytListResponseItem} from 'features/Jupyter/api/jupyt';

export type JupytDefaultsType = {
    base_kernel_image: string;
    default_kernel_image: string;
};

export type JupytState = {
    jupytDefaults: JupytDefaultsType;
    currentJupytAlias: string;
    operations: JupytListResponseItem[];
    usersOperations: JupytListResponseItem[];
    jupyterKernelStatus: Kernel.Status;
    searchParams: Record<string, string>;
    sessionId: string;
};

export const initialState: JupytState = {
    jupytDefaults: {
        default_kernel_image: '',
        base_kernel_image: '',
    },
    jupyterKernelStatus: 'unknown',
    currentJupytAlias: '',
    operations: [],
    usersOperations: [],
    searchParams: {
        alias: '',
        creator: '',
        health: '',
        state: '',
    },
    sessionId: '',
};

export const jupytSlice = createSlice({
    name: 'jupyter.jupyt',
    initialState,
    reducers: {
        setJupytList: (state, action: PayloadAction<{operations: JupytListResponseItem[]}>) => {
            state.operations = action.payload.operations;
        },
        setUsersJupytList: (
            state,
            action: PayloadAction<{operations: JupytListResponseItem[]}>,
        ) => {
            state.usersOperations = action.payload.operations;
        },
        setCurrentJupytAlias(state, action: PayloadAction<{alias: string}>) {
            state.currentJupytAlias = action.payload.alias;
        },
        setJupytDefaults(state, action: PayloadAction<{jupytDefaults: JupytDefaultsType}>) {
            state.jupytDefaults = action.payload.jupytDefaults;
        },
        setJupyterKernelStatus(state, action: PayloadAction<{status: Kernel.Status}>) {
            state.jupyterKernelStatus = action.payload.status;
        },
        clearJupytState: () => initialState,
        setSearchParams: (state, action: PayloadAction<{searchParams: Record<string, string>}>) => {
            state.searchParams = action.payload.searchParams;
        },
        setSessionId: (state, action: PayloadAction<{sessionId: string}>) => {
            state.sessionId = action.payload.sessionId;
        },
    },
});
