import {useLayoutEffect} from 'react';
import type {Graph} from '@gravity-ui/graph';

import type {GraphCustomizableEvents} from '../type';

export const useCustomizableEvents = (
    graph: Graph | null,
    events: GraphCustomizableEvents | undefined,
) => {
    useLayoutEffect(() => {
        if (!graph || !events) {
            return undefined;
        }

        const unsubscribeList: (() => void)[] = [];

        const entries = Object.entries(events);

        entries.forEach(([key, cb]) => {
            const fn = graph.on(key as keyof GraphCustomizableEvents, cb);
            unsubscribeList.push(fn);
        });

        return () => {
            unsubscribeList.forEach((unsubscribe) => unsubscribe());
        };
    }, [graph, events]);
};
