import {NotebookSQLHelper} from '../../../utils/sql/helper';
import {NotebookCellType} from '../../../constants/cell';
import type {TractoThunkDispatch} from '../../../../../store/tracto-dispatch';
import type {TractoRootState} from '../../../../../store/reducers';
import {selectCell} from '../../selectors/notebook';
import {getItemStrict} from '../../../utils/strict-selectors';
import {getCellSQLConfig} from '../../../utils/cell/view';
import _merge from 'lodash/merge';
import {notebookSlice} from '../../slices/notebook';
import {batch} from 'react-redux';

const setCodeCellSource = (cellId: string, source: string) => {
    return (dispatch: TractoThunkDispatch) => {
        batch(() => {
            dispatch(notebookSlice.actions.setCellSource({cellId, source}));
        });
    };
};

const setMarkdownCellSource = (cellId: string, source: string) => {
    return (dispatch: TractoThunkDispatch) => {
        batch(() => {
            dispatch(notebookSlice.actions.setCellSource({cellId, source}));
        });
    };
};

const setSQLCellSource = (cellId: string, source: string) => {
    return (dispatch: TractoThunkDispatch, getState: () => TractoRootState) => {
        const state = getState();

        const cell = getItemStrict(selectCell(state, cellId));
        const oldSql = getItemStrict(getCellSQLConfig(cell));

        const updatedVariables = _merge({}, oldSql.variables, {query: source});

        const sql = _merge({}, oldSql, {variables: updatedVariables});

        const preparedSource = NotebookSQLHelper.getQuery(sql.template_id, updatedVariables);

        batch(() => {
            dispatch(
                notebookSlice.actions.setCellSource({
                    cellId,
                    source: preparedSource,
                }),
            );
            dispatch(
                notebookSlice.actions.setCellView({
                    cellId,
                    update: {
                        type: 'sql',
                        payload: {sql},
                    },
                }),
            );
        });
    };
};

export const setCellSource = (
    source: string,
    options: {type: NotebookCellType; cellId: string},
) => {
    return (dispatch: TractoThunkDispatch, _getState: () => TractoRootState) => {
        const {type, cellId} = options;
        switch (type) {
            case NotebookCellType.CODE:
                dispatch(setCodeCellSource(cellId, source));
                break;
            case NotebookCellType.SPYT:
            case NotebookCellType.CHYT:
            case NotebookCellType.QL:
            case NotebookCellType.YQL:
                dispatch(setSQLCellSource(cellId, source));
                break;
            case NotebookCellType.MARKDOWN:
                dispatch(setMarkdownCellSource(cellId, source));
                break;
            default:
        }
    };
};
