import {setupMonacoAutocompleteExtension} from './autocomplete';
import {setupWorkflowJSONValidation} from './workflow-validation';

export const getJupyterMonacoExtensions = () => {
    return [setupMonacoAutocompleteExtension];
};

export const getWorkflowMonacoExtensions = () => {
    return [setupWorkflowJSONValidation];
};
