import React, {useCallback} from 'react';
import {Icon, Select, SelectRenderControl, SelectRenderControlProps} from '@gravity-ui/uikit';
import {JupyterCellQa} from '../../../../../../shared/qa';
import Button from '@ytsaurus-ui-platform/src/ui/components/Button/Button';
import {ArrowDown, ArrowUp, TrashBin} from '@gravity-ui/icons';
import {JupyterButtonWithHotkeys} from '../../JupyterButtonWithHotkeys/JupyterButtonWithHotkeys';
import AddAboveIcon from '../../../../../assets/img/svg/add-above.svg';
import AddBelowIcon from '../../../../../assets/img/svg/add-below.svg';
import {SUPPORTED_CELL_TYPES} from './constants/cell-types';
import type {NotebookCellType} from '../../../constants/cell';
import {JupyterCellDropdown} from '../JupyterCellDropdown/JupyterCellDropdown';
import type {BoundDropdownWithHotkeysItem} from '../../../../../libs/hotkeys';
import type {JUPYTER_HOTKEYS} from '../../../constants/hotkeys';
import {ACTIONS_CONTAINER_CONTROL_SIZE, ACTIONS_CONTAINER_ICON_SIZE} from '../constants/size';
import block from 'bem-cn-lite';
import './JupyterCellCommonControls.scss';

const b = block('jupyter-cell-common-controls');

type JupyterCellCommonControlsProps = {
    cellViewType: NotebookCellType;
    onChangeCellType: (value: string[]) => void;
    onArrowUpClick: (event: React.MouseEvent<HTMLElement>) => void;
    onArrowDownClick: (event: React.MouseEvent<HTMLElement>) => void;
    onAddCellAboveClick: (event: React.MouseEvent<HTMLElement>) => void;
    onAddCellBelowClick: (event: React.MouseEvent<HTMLElement>) => void;
    onTrashBinClick: (event: React.MouseEvent<HTMLElement>) => void;
    onChangeCellTypeControlClick: (event: React.MouseEvent<HTMLElement>) => void;
    onDropdownButtonClick: (event: React.MouseEvent<HTMLElement>) => void;
    dropdownItems: BoundDropdownWithHotkeysItem<typeof JUPYTER_HOTKEYS>[];
};

export const JupyterCellCommonControls: React.FC<JupyterCellCommonControlsProps> = (
    props: JupyterCellCommonControlsProps,
) => {
    const {
        cellViewType,
        onChangeCellType,
        onAddCellAboveClick,
        onAddCellBelowClick,
        onArrowDownClick,
        onTrashBinClick,
        onArrowUpClick,
        dropdownItems,
        onChangeCellTypeControlClick,
        onDropdownButtonClick,
    } = props;

    const renderControl: SelectRenderControl = useCallback(
        (controlProps: SelectRenderControlProps, options) => {
            const {ref: _ref, triggerProps, ...restProps} = controlProps;

            const onClick = (event: React.MouseEvent<HTMLElement>) => {
                triggerProps.onClick?.(event);
                onChangeCellTypeControlClick(event);
            };

            const cellType = SUPPORTED_CELL_TYPES.find((item) => item.value === options.value?.[0]);
            return (
                <Button
                    {...restProps}
                    {...triggerProps}
                    onClick={onClick}
                    view="flat"
                    size="s"
                    width="max"
                    className={b('cell-type-button')}
                    qa={JupyterCellQa.JupyterCellTypeSelect}
                >
                    {cellType?.content}
                </Button>
            );
        },
        [cellViewType, onChangeCellTypeControlClick],
    );

    return (
        <>
            <Select
                renderControl={renderControl}
                value={[cellViewType]}
                onUpdate={onChangeCellType}
                size="s"
                width={90}
                options={SUPPORTED_CELL_TYPES}
            />
            <Button
                qa={JupyterCellQa.JupyterCellMoveUpButton}
                view="flat-secondary"
                onClick={onArrowUpClick}
                withTooltip
                size={ACTIONS_CONTAINER_CONTROL_SIZE}
                tooltipProps={{
                    content: 'Move this cell up',
                    placement: 'top',
                }}
            >
                <Icon data={ArrowUp} size={ACTIONS_CONTAINER_ICON_SIZE} />
            </Button>
            <Button
                qa={JupyterCellQa.JupyterCellMoveDownButton}
                view="flat-secondary"
                onClick={onArrowDownClick}
                withTooltip
                size={ACTIONS_CONTAINER_CONTROL_SIZE}
                tooltipProps={{
                    content: 'Move this cell down',
                    placement: 'top',
                }}
            >
                <Icon data={ArrowDown} size={ACTIONS_CONTAINER_ICON_SIZE} />
            </Button>
            <JupyterButtonWithHotkeys
                id="insertAbove"
                qa={JupyterCellQa.JupyterCellAddCellBeforeButton}
                view="flat-secondary"
                onClick={onAddCellAboveClick}
                size={ACTIONS_CONTAINER_CONTROL_SIZE}
            >
                <Icon data={AddAboveIcon} size={14} />
            </JupyterButtonWithHotkeys>
            <JupyterButtonWithHotkeys
                id="insertBelow"
                qa={JupyterCellQa.JupyterCellAddCellAfterButton}
                view="flat-secondary"
                onClick={onAddCellBelowClick}
                size={ACTIONS_CONTAINER_CONTROL_SIZE}
            >
                <Icon data={AddBelowIcon} size={14} />
            </JupyterButtonWithHotkeys>
            <JupyterButtonWithHotkeys
                id="delete"
                qa={JupyterCellQa.JupyterCellDeleteButton}
                view="flat-secondary"
                onClick={onTrashBinClick}
                size={ACTIONS_CONTAINER_CONTROL_SIZE}
            >
                <Icon data={TrashBin} size={ACTIONS_CONTAINER_ICON_SIZE} />
            </JupyterButtonWithHotkeys>
            <JupyterCellDropdown
                onDropdownButtonClick={onDropdownButtonClick}
                items={dropdownItems}
            />
        </>
    );
};
