import {WorkflowDocumentType, type WorkflowExecutionStatus} from '../types/workflow';
import {WORKFLOW_RUN_PERSISTENT_STATUS} from '../constants/workflow';

export const getDefaultWorkflowContent = () => {
    return {
        _format_version: '0.1',
        triggers: [
            {
                trigger_type: 'cron',
                params: {
                    cron_expression: '*/30 * * * *',
                },
            },
        ],
        steps: [
            {
                task_type: 'python_code',
                task_params: {
                    code: "print('Hello world!')",
                    docker_image: 'docker.io/library/python:3.11',
                },
                step_id: 'my_first_step',
                secrets: [],
            },
        ],
    } satisfies WorkflowDocumentType;
};

export const isPersistentWorkflowRunStatus = (status: string) => {
    return WORKFLOW_RUN_PERSISTENT_STATUS[status as WorkflowExecutionStatus] || false;
};
