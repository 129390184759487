import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Button from '@ytsaurus-ui-platform/src/ui/components/Button/Button';
import Icon from '@ytsaurus-ui-platform/src/ui/components/Icon/Icon';

import {OPEN_MOVE_OBJECT_POPUP} from '@ytsaurus-ui-platform/src/ui/constants/navigation/modals/move-object';
import {openEditingPopup} from '@ytsaurus-ui-platform/src/ui/store/actions/navigation/modals/path-editing-popup';
import {OPEN_COPY_OBJECT_POPUP} from '@ytsaurus-ui-platform/src/ui/constants/navigation/modals/copy-object';
import {openDeleteModal} from '@ytsaurus-ui-platform/src/ui/store/actions/navigation/modals/delete-object';
import type {DeleteObjectItem} from '@ytsaurus-ui-platform/src/ui/store/reducers/navigation/modals/delete-object';

import {DropdownMenu} from '@gravity-ui/uikit';
import {showLinkToModal} from '@ytsaurus-ui-platform/src/ui/store/actions/navigation/modals/link-to-modal';
import {getNavigationPathAttributes} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation/navigation';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';
import {selectIsNotebookSaved} from 'features/Jupyter/store/selectors/notebook';

type JupyterPathActionsContainerProps = {};

export const JupyterPathActionsContainer: React.FC<JupyterPathActionsContainerProps> = () => {
    const isNotebookSaved = useSelector(selectIsNotebookSaved);
    const attributes = useSelector(getNavigationPathAttributes);
    const path = useSelector(getPath);

    const dispatch = useDispatch();

    const {onMove, onCopy, onLink, onDeleteClick} = React.useMemo(() => {
        return {
            onMove(evt: React.MouseEvent) {
                dispatch(openEditingPopup(path, path, OPEN_MOVE_OBJECT_POPUP));
                evt.stopPropagation();
            },
            onCopy(evt: React.MouseEvent) {
                dispatch(openEditingPopup(path, path, OPEN_COPY_OBJECT_POPUP));
                evt.stopPropagation();
            },
            onDeleteClick(evt: React.MouseEvent) {
                dispatch(
                    openDeleteModal({
                        path,
                        $attributes: attributes,
                    } as DeleteObjectItem),
                );
                evt.stopPropagation();
            },
            onLink(evt: React.MouseEvent) {
                dispatch(showLinkToModal({target: path}));
                evt.stopPropagation();
            },
        };
    }, [dispatch, path]);

    const menuItems: Array<{
        text: string;
        icon?: React.ReactNode;
        action: (evt: any) => void;
    }> = [
        {
            text: 'Move',
            icon: <Icon awesome="file-export" face="solid" />,
            action: onMove,
        },
        {
            text: 'Copy',
            icon: <Icon awesome="copy" face="solid" />,
            action: onCopy,
        },
        {
            text: 'Link',
            icon: <Icon awesome="link" face="solid" />,
            action: onLink,
        },
        {
            text: 'Delete',
            icon: <Icon awesome="trash-alt" />,
            action: onDeleteClick,
        },
    ];

    const disabled = !isNotebookSaved;

    return (
        <DropdownMenu
            disabled={disabled}
            switcher={
                <Button size={'m'} view={'outlined'} disabled={disabled}>
                    <Icon awesome="ellipsis-h" />
                </Button>
            }
            items={menuItems}
        />
    );
};
