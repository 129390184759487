import type {HotkeyDescriptor} from '../../../libs/hotkeys';

export const ORCHESTRACTO_HOTKEYS = {
    run: {
        key: 'Shift+Enter',
        description: 'Run',
        command: 'Run',
    },
    runWithParams: {
        key: 'Shift+p+Enter',
        description: 'Run with params',
        command: 'Run with params',
    },
    save: {
        key: 'mod+S',
        description: 'Save workflow',
        command: 'Save workflow',
    },
} as const satisfies {[key: string]: HotkeyDescriptor};
