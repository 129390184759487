import React from 'react';
import {
    ExtraTab,
    UIFactory,
    UIFactoryClusterPageInfo,
} from '@ytsaurus-ui-platform/src/ui/UIFactory';
import type {Node} from '@ytsaurus-ui-platform/src/ui/utils/navigation/content/map-nodes/node';
import {defaultUIFactory} from '@ytsaurus-ui-platform/src/ui/UIFactory/default-ui-factory';
import type {AppNavigationProps} from '@ytsaurus-ui-platform/src/ui/containers/AppNavigation/AppNavigationPageLayout';
import {TractoSubjectCard} from '../components/TractoSubjectCard/TractoSubjectCard';
import {tractoAppReducers} from '../store/reducers';
import {
    JUPYTER_NAVIGATION_MAP_NODE_SETTINGS,
    JUPYTER_NOTEBOOK_NAVIGATION_TAB,
    JUPYT_OPERATIONS_PAGE,
} from 'features/Jupyter';
import {
    TRACTO_REGISTRY_INSPECTOR_TAB,
    TRACTO_REGISTRY_NAVIGATION_MAP_NODE_SETTINGS,
    TRACTO_REGISTRY_TAB,
} from 'features/TractoRegistry';
import {monitoring, monitoringUrlMapping} from 'features/Monitoring';
import {AppNavigationPageLayout} from '../features/Navigation';
import type {DropdownMenuItem} from '@gravity-ui/uikit';
import {
    getTractoMapNodeToolbarCreateActions,
    tractoRenderModals,
} from '../pages/navigation/MapNode/utils/dropdown-actions';
import {isEnvTrue} from '../../shared/utils/env';
import {getTractoConfigData, isProductionEnvironment} from '../config/ui-config';
import {ResourcesContainer, ResourcesHOC, ResourcesTypeSwitch} from 'features/UniverseResources';
import {Tractorun} from 'features/Tractorun';
import {
    WORKFLOW_NAVIGATION_MAP_NODE_SETTINGS,
    WORKFLOW_NAVIGATION_TAB,
    WORKFLOW_RUNS_NAVIGATION_TAB,
} from 'features/Orchestracto';
import {
    IMAGE_BUILDER_BLUEPRINT_NAVIGATION_TAB,
    IMAGE_BUILDER_NAVIGATION_MAP_NODE_SETTINGS,
} from 'features/ImageBuilder';
import {
    SECRET_STORE_NAVIGATION_MAP_NODE_SETTINGS,
    SECRET_STORE_NAVIGATION_TAB,
} from 'features/SecretStore';
import {ClusterAppearance} from '@ytsaurus-ui-platform/src/ui/appearance';
import YT from '@ytsaurus-ui-platform/src/ui/config/yt-config';
import type {ClusterTheme} from '@ytsaurus-ui-platform/src/shared/yt-types';

const tractoClusterIcon = require('../assets/img/tracto-cluster-icon.png');

const tractoFavicon = require('../assets/img/tracto-favicon.png');

export const favicons: Record<ClusterTheme, string> = {
    grapefruit: require('../assets/img/favicon/grapefruit.png'),
    bittersweet: require('../assets/img/favicon/bittersweet.png'),
    sunflower: require('../assets/img/favicon/sunflower.png'),
    grass: require('../assets/img/favicon/grass.png'),
    mint: require('../assets/img/favicon/mint.png'),
    aqua: require('../assets/img/favicon/aqua.png'),
    bluejeans: require('../assets/img/favicon/bluejeans.png'),
    lavander: require('../assets/img/favicon/lavander.png'),
    pinkrose: require('../assets/img/favicon/pinkrose.png'),
    lightgray: require('../assets/img/favicon/lightgray.png'),
    mediumgray: require('../assets/img/favicon/mediumgray.png'),
    darkgray: require('../assets/img/favicon/darkgray.png'),
    dornyellow: require('../assets/img/favicon/dornyellow.png'),
    rubber: require('../assets/img/favicon/rubber.png'),
};

const TractoResources = {
    ResourcesContainer,
    ResourcesHOC,
    ResourcesTypeSwitch,
};

interface TractoUIFactory extends UIFactory {
    TractoResources: typeof TractoResources;
    Tractorun: typeof Tractorun;
}

const navigationMapNodeSettings = {
    additionalAttributes: [
        ...TRACTO_REGISTRY_NAVIGATION_MAP_NODE_SETTINGS.additionalAttributes,
    ] as string[],
    renderNodeIcon: [TRACTO_REGISTRY_NAVIGATION_MAP_NODE_SETTINGS.renderNodeIcon] as Array<
        (item: Node) => React.ReactNode
    >,
};

const productionOverrides: Partial<TractoUIFactory> = {
    getClusterAppearance(cluster?: string) {
        const {urls, theme} = YT.clusters[cluster!] || {};

        const clusterAppearance: ClusterAppearance = {
            icon2x: tractoClusterIcon,
            icon: tractoClusterIcon,
            iconbig: tractoClusterIcon,
            favicon: favicons[theme] || tractoFavicon,
            ...urls,
        };

        return clusterAppearance;
    },

    renderSubjectCard(props) {
        return <TractoSubjectCard {...props} />;
    },

    getExtraReducersAndUrlMapping() {
        return {
            reducers: tractoAppReducers,
            urlMapping: {
                ...monitoringUrlMapping,
            },
        };
    },
    renderAppNavigation(props: AppNavigationProps): React.ReactElement {
        return <AppNavigationPageLayout {...props} />;
    },

    getMapNodeExtraCreateActions(baseActions: Array<DropdownMenuItem>): {
        menuItems: Array<DropdownMenuItem>;
        renderModals: () => React.ReactNode;
    } {
        return {
            menuItems: getTractoMapNodeToolbarCreateActions(baseActions),
            renderModals: tractoRenderModals,
        };
    },

    TractoResources,
    Tractorun,
};

let developmentOverrides: Partial<TractoUIFactory> = {};

if (!isProductionEnvironment()) {
    developmentOverrides = {
        ...developmentOverrides,
        ...monitoring,
    };
}

if (isEnvTrue(getTractoConfigData().env.SHOW_POOL_DASHBOARD)) {
    developmentOverrides = {
        ...developmentOverrides,
        getSchedulingExtraTabs: monitoring.getSchedulingExtraTabs,
    };
}

const navigationExtraTabs: ExtraTab[] = [TRACTO_REGISTRY_TAB, TRACTO_REGISTRY_INSPECTOR_TAB];
const extraClusterPages: UIFactoryClusterPageInfo[] = [];

const registerJupyterPlugins = () => {
    navigationExtraTabs.push(JUPYTER_NOTEBOOK_NAVIGATION_TAB);
    extraClusterPages.push(JUPYT_OPERATIONS_PAGE);
    navigationMapNodeSettings.renderNodeIcon.push(
        JUPYTER_NAVIGATION_MAP_NODE_SETTINGS.renderNodeIcon,
    );
    navigationMapNodeSettings.additionalAttributes.push(
        ...JUPYTER_NAVIGATION_MAP_NODE_SETTINGS.additionalAttributes,
    );
};

registerJupyterPlugins();

const registerOrchestractoPlugins = () => {
    navigationExtraTabs.push(WORKFLOW_NAVIGATION_TAB);
    navigationExtraTabs.push(WORKFLOW_RUNS_NAVIGATION_TAB);
    navigationMapNodeSettings.renderNodeIcon.push(
        WORKFLOW_NAVIGATION_MAP_NODE_SETTINGS.renderNodeIcon,
    );
    navigationMapNodeSettings.additionalAttributes.push(
        ...WORKFLOW_NAVIGATION_MAP_NODE_SETTINGS.additionalAttributes,
    );
};

registerOrchestractoPlugins();

const registerImageBuilderPlugins = () => {
    navigationExtraTabs.push(IMAGE_BUILDER_BLUEPRINT_NAVIGATION_TAB);
    navigationMapNodeSettings.renderNodeIcon.push(
        IMAGE_BUILDER_NAVIGATION_MAP_NODE_SETTINGS.renderNodeIcon,
    );
    navigationMapNodeSettings.additionalAttributes.push(
        ...IMAGE_BUILDER_NAVIGATION_MAP_NODE_SETTINGS.additionalAttributes,
    );
};

registerImageBuilderPlugins();

const registerSecretStorePlugins = () => {
    navigationExtraTabs.push(SECRET_STORE_NAVIGATION_TAB);
    navigationMapNodeSettings.renderNodeIcon.push(
        SECRET_STORE_NAVIGATION_MAP_NODE_SETTINGS.renderNodeIcon,
    );
    navigationMapNodeSettings.additionalAttributes.push(
        ...SECRET_STORE_NAVIGATION_MAP_NODE_SETTINGS.additionalAttributes,
    );
};

registerSecretStorePlugins();

export const tractoUIFactory: UIFactory = {
    ...defaultUIFactory,
    ...productionOverrides,
    ...developmentOverrides,

    getNavigationExtraTabs(): Array<ExtraTab> {
        return navigationExtraTabs;
    },
    getExtaClusterPages(): Array<UIFactoryClusterPageInfo> {
        return extraClusterPages;
    },
    getNavigationMapNodeSettings() {
        return {
            additionalAttributes: navigationMapNodeSettings.additionalAttributes,
            renderNodeIcon: (item: Node) => {
                let content = null;

                navigationMapNodeSettings.renderNodeIcon.some((renderNodeIcon) => {
                    content = renderNodeIcon(item);

                    return content;
                });

                return content;
            },
        };
    },
};
