import {Dialog} from '@gravity-ui/uikit';
import MetaTable from '@ytsaurus-ui-platform/src/ui/components/MetaTable/MetaTable';
import Yson from '@ytsaurus-ui-platform/src/ui/components/Yson/Yson';
import React, {useCallback, useMemo, useState} from 'react';
import _isEqual from 'lodash/isEqual';
import Button from '@ytsaurus-ui-platform/src/ui/components/Button/Button';
import {useSelector} from 'react-redux';
import {selectCurrentRunDetails} from 'features/Orchestracto/store/selectors/runs';

type WorkflowRunDetailsModalProps = {
    onClose: () => void;
    details: Record<string, any>;
};

const WorkflowRunDetailsModal: React.FC<WorkflowRunDetailsModalProps> = ({details, onClose}) => {
    const caption = 'Run details';

    const items = useMemo(
        () =>
            Object.entries(details).map(([key, value]) => {
                return {
                    key,
                    value: <Yson value={value} />,
                };
            }),
        [details],
    );

    return (
        <Dialog open={true} onClose={onClose} size="m">
            <Dialog.Header caption={caption} />
            <Dialog.Body>
                <MetaTable items={items} rowGap={4} />
            </Dialog.Body>
        </Dialog>
    );
};

type ShowWorkflowRunDetailsButtonProps = {};

export const ShowWorkflowRunDetailsButton: React.FC<ShowWorkflowRunDetailsButtonProps> = () => {
    const [visible, setVisible] = useState(false);

    const details = useSelector(selectCurrentRunDetails);

    const onClick = useCallback(() => {
        setVisible(true);
    }, []);

    const onClose = useCallback(() => {
        setVisible(false);
    }, []);

    if (!details) {
        return null;
    }

    let workflowParamsModal = null;

    if (visible) {
        workflowParamsModal = <WorkflowRunDetailsModal onClose={onClose} details={details} />;
    }

    return (
        <>
            <Button onClick={onClick}>Show params</Button>
            {workflowParamsModal}
        </>
    );
};
