import type {TractoNotebookCodeCell} from '../types/version';
import {useSelector} from 'react-redux';
import type {TractoRootState} from '../../../store/reducers';
import {selectCellsExecutionStatus} from '../store/selectors/notebook';

type UseCellExecutionArgs = {
    cell: TractoNotebookCodeCell;
};

export const useCellExecution = (args: UseCellExecutionArgs) => {
    const {cell} = args;

    const isRunning = useSelector<TractoRootState, boolean>(
        (state) => selectCellsExecutionStatus(state)[cell.id] === 'running',
    );

    const executionNumber = isRunning ? '*' : cell.execution_count;

    const count = executionNumber ? `[${executionNumber}]: ` : null;

    return {count};
};
