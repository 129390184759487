import React, {useCallback} from 'react';
import {handleJupyterCellEditorFocus, updateCellSource} from '../../../store/actions/notebook/cell';
import {useSelector} from 'react-redux';
import {TractoRootState} from '../../../../../store/reducers';
import {selectEditableCell, selectFocusedCellId} from '../../../store/selectors/notebook';
import type {TractoNotebookCell} from '../../../types/version';
import {extractCellId} from '../../../utils/cell/common';
import {useTractoDispatch} from '../../../../../store/tracto-dispatch';
import {notebookSlice} from '../../../store/slices/notebook';

export const useJupyterCell = (cell: TractoNotebookCell) => {
    const dispatch = useTractoDispatch();

    const cellId = extractCellId(cell);

    const isFocused = useSelector<TractoRootState, boolean>(
        (state) => selectFocusedCellId(state) === cellId,
    );

    const isEditable = useSelector<TractoRootState, boolean>(
        (state) => selectEditableCell(state) === cellId,
    );

    const onSourceChange = useCallback(
        (source: string) => {
            dispatch(updateCellSource({cellId, source}));
        },
        [cellId],
    );

    const onClick = useCallback(() => {
        if (!isFocused) {
            dispatch(notebookSlice.actions.setFocusedCellById({cellId}));
        }
    }, [isFocused, cellId]);

    const onEditorFocus = React.useCallback(
        (event: React.FocusEvent<HTMLDivElement>) => {
            dispatch(handleJupyterCellEditorFocus(event, cellId));
        },
        [cellId],
    );

    const onEditorBlur = React.useCallback(() => {
        dispatch(notebookSlice.actions.removeCellEditable());
    }, [cellId, isEditable]);

    return {
        cell,
        isFocused,
        isEditable,
        onSourceChange,
        onClick,
        onEditorFocus,
        onEditorBlur,
    };
};
