import type {JupytSecretsMapType} from '../../../../../../../shared/types/jupyt/secrets';
import {mapListToJupytSecrets} from '../../../../../../../shared/utils/jupyt/secrets';
import type {JupytSpecletUIFormConfig} from '../../../../api/jupyt';
import type {JupytSpecletOptions} from '../../../../components/Dialogs/JupytSpecletDialog/types';

const YT_TOKEN_KEY = 'YT_TOKEN';
const DOCKER_AUTH_KEY = 'docker_auth';

export const prepareFields = (
    values: JupytSpecletOptions,
    options: {registry?: string; username: string},
): {
    alias: string;
    speclet_options: {
        active: boolean;
        pool?: string;
        pool_trees?: string[];
        jupyter_docker_image: string;
        cpu?: number;
        gpu?: number;
        memory?: number;
        idle_timeout?: number;
        enable_idle_suspension?: boolean;
    } & JupytSpecletUIFormConfig;
    secrets: JupytSecretsMapType;
} => {
    const {general, params, resources} = values;
    const {registry, username} = options;

    const secrets = mapListToJupytSecrets(general.secrets);

    const jupyterImage = general.jupyter_docker_image.image.trim();

    if (general.yt_token) {
        secrets[YT_TOKEN_KEY] = general.yt_token;

        if (registry && jupyterImage.startsWith(registry)) {
            secrets[DOCKER_AUTH_KEY] = `{"username"="${username}"; password="${general.yt_token}"}`;
        }
    }

    return {
        alias: values.general.alias,
        speclet_options: {
            active: general.active,
            pool: general.pool,
            pool_trees: general.pool_trees,
            jupyter_docker_image: general.jupyter_docker_image.image,
            cpu: resources.cpu?.value,
            gpu: resources.gpu?.value,
            memory: resources.memory?.value,
            idle_timeout: params.idle_timeout,
            enable_idle_suspension: params.enable_idle_suspension,
            tracto_ui_form_config: {
                docker_image: {
                    path: general.jupyter_docker_image.path,
                    option: general.jupyter_docker_image.option,
                },
            },
        },
        secrets,
    };
};
