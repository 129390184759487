import {NotebookCellType} from '../../../../constants/cell';
import {JupyterCellQa} from '../../../../../../../shared/qa';
import {selectify} from '../../../../../../utils/select';

export const SUPPORTED_CELL_TYPES = [
    {
        value: NotebookCellType.CODE,
        content: 'Code',
        qa: JupyterCellQa.JupyterCellTypeCode,
    },
    {
        value: NotebookCellType.MARKDOWN,
        content: 'Markdown',
        qa: JupyterCellQa.JupyterCellTypeMarkdown,
    },
    {
        value: NotebookCellType.CHYT,
        content: 'CHYT',
        qa: JupyterCellQa.JupyterCellTypeCHYT,
    },
    {
        value: NotebookCellType.SPYT,
        content: 'SPYT',
        qa: JupyterCellQa.JupyterCellTypeSPYT,
    },
    {
        value: NotebookCellType.QL,
        content: 'QL',
        qa: JupyterCellQa.JupyterCellTypeQL,
    },
    {
        value: NotebookCellType.YQL,
        content: 'YQL',
        qa: JupyterCellQa.JupyterCellTypeYQL,
    },
].map(selectify);
