import React, {useCallback} from 'react';
import {Flex, Hotkey, Menu, Text} from '@gravity-ui/uikit';
import type {MenuWithHotkeysSingleItem} from '../../types';
import block from 'bem-cn-lite';
import './MenuWithHotkeysBaseItem.scss';

type MenuWithHotkeysItemProps = MenuWithHotkeysSingleItem & {
    onClick: (action: MenuWithHotkeysSingleItem['action'] | undefined) => void;
};

const b = block('menu-with-hotkeys-base-item');

export const MenuWithHotkeysBaseItem: React.FC<MenuWithHotkeysItemProps> = (
    props: MenuWithHotkeysItemProps,
) => {
    const {text, iconStart, iconEnd, wrapper, onClick, hotkey, platform, action} = props;

    const handleClick = useCallback(() => {
        onClick(action);
    }, [action, onClick]);

    const node = (
        <Menu.Item iconStart={iconStart} iconEnd={iconEnd} onClick={handleClick}>
            <Flex
                className={b('content')}
                alignItems="center"
                justifyContent="space-between"
                gap={10}
            >
                <Text variant="inherit">{text}</Text>
                {hotkey && <Hotkey value={hotkey} platform={platform} />}
            </Flex>
        </Menu.Item>
    );

    return wrapper ? wrapper(node) : node;
};
