import type {AxiosInstance} from 'axios';
import type {GetLogsRequestArgs, GetLogsSuccessfulResponse} from './types';
import type {CommonRequestApiArgs} from '../types';

export class LogsApi {
    private client: AxiosInstance;

    constructor(client: AxiosInstance) {
        this.client = client;
    }

    async getLogs(
        args: GetLogsRequestArgs & CommonRequestApiArgs,
    ): Promise<GetLogsSuccessfulResponse> {
        const {workflowPath, runId, stepId, cancelToken} = args;

        const params: Record<string, any> = {
            workflow_path: workflowPath,
            run_id: runId,
            step_id: stepId,
        };

        const result = await this.client.get<GetLogsSuccessfulResponse>('/api/v1/get_logs', {
            params,
            cancelToken,
        });

        return result.data;
    }
}
