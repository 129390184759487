import React from 'react';

import {type DropdownMenuItem, Icon as UIKitIcon} from '@gravity-ui/uikit';
import {modalCreateDocumentSlice} from '../../../../features/Navigation/store/slices/modals/modal-create-document';
import {getWindowStore} from '@ytsaurus-ui-platform/src/ui/store/window-store';
import {Factory, LogoPython, NodesLeft} from '@gravity-ui/icons';
import Icon from '@ytsaurus-ui-platform/src/ui/components/Icon/Icon';
import {NavigationNotebookSaveDialog} from 'features/Jupyter/containers/Dialogs/NotebookSaveDialog/NotebookSaveDialog';
import {DocumentCreateDialog} from 'features/Navigation/containers/Dialogs/DocumentCreateDialogs';
import {WorkflowCreateModal} from 'features/Orchestracto/containers/Modals/WorkflowCreateModal/WorkflowCreateModal';
import {SecretStoreCreateDialog} from 'features/SecretStore';
import {BlueprintCreateDialog} from 'features/ImageBuilder/containers/BlueprintCreateDialog/BlueprintCreateDialog';
import {getTractoClusterUiConfig} from '../../../../store/selectors/tracto-cluster-ui-config';
import {TractoRootState} from 'store/reducers';

const getMapNodeToolbarCreateActionsExtender = (actions: DropdownMenuItem[]) => {
    // first item on index 0 is always YT Table, so let's start with index 1
    let priorityIndex = 1;

    return (newItem: DropdownMenuItem) => {
        actions.splice(priorityIndex++, 0, newItem);
    };
};

export const getTractoMapNodeToolbarCreateActions = (
    actions: Array<DropdownMenuItem>,
): Array<DropdownMenuItem> => {
    const {dispatch, getState} = getWindowStore();

    const clusterUIConfig = getTractoClusterUiConfig((getState as () => TractoRootState)());

    const copy = [...actions];

    const extendMapNodeToolbarCreateActions = getMapNodeToolbarCreateActionsExtender(copy);

    const createDocumentAction: DropdownMenuItem = {
        text: 'Document',
        iconStart: <Icon awesome="file-alt" />,
        action: () =>
            dispatch(
                modalCreateDocumentSlice.actions.open({
                    documentType: 'document',
                }),
            ),
    };

    extendMapNodeToolbarCreateActions(createDocumentAction);

    if (clusterUIConfig.jupyt_controller_base_url) {
        const createNotebookAction: DropdownMenuItem = {
            action: () =>
                dispatch(
                    modalCreateDocumentSlice.actions.open({
                        documentType: 'notebook',
                    }),
                ),
            text: 'Notebook',
            iconStart: <UIKitIcon data={LogoPython} size={13} />,
        };

        extendMapNodeToolbarCreateActions(createNotebookAction);
    }

    if (clusterUIConfig.orc_base_url) {
        const createWorkflowAction: DropdownMenuItem = {
            action: () =>
                dispatch(
                    modalCreateDocumentSlice.actions.open({
                        documentType: 'workflow',
                    }),
                ),
            text: 'Workflow',
            iconStart: <UIKitIcon data={NodesLeft} size={13} />,
        };

        extendMapNodeToolbarCreateActions(createWorkflowAction);
    }

    const createSecretStoreAction: DropdownMenuItem = {
        text: 'Secret store',
        iconStart: <Icon awesome="key" size={13} />,
        action: () =>
            dispatch(
                modalCreateDocumentSlice.actions.open({
                    documentType: 'secret-store',
                }),
            ),
    };

    extendMapNodeToolbarCreateActions(createSecretStoreAction);

    if (clusterUIConfig.image_builder_base_url) {
        const createBlueprintStoreAction: DropdownMenuItem = {
            text: 'Blueprint',
            iconStart: <UIKitIcon data={Factory} size={13} />,
            action: () =>
                dispatch(
                    modalCreateDocumentSlice.actions.open({
                        documentType: 'blueprint',
                    }),
                ),
        };

        extendMapNodeToolbarCreateActions(createBlueprintStoreAction);
    }

    return copy;
};

export const tractoRenderModals = () => {
    return (
        <>
            <NavigationNotebookSaveDialog />
            <DocumentCreateDialog />
            <WorkflowCreateModal />
            <SecretStoreCreateDialog />
            <BlueprintCreateDialog />
        </>
    );
};
