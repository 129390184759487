import React, {useCallback, useEffect, useRef, useState} from 'react';
import {TractoThunkDispatch, useTractoDispatch} from '../../../../store/tracto-dispatch';
import {
    executeFocusedCell,
    executeNotebookWithRequiredJupyt,
} from 'features/Jupyter/store/actions/execute';
import {CommandPalette} from 'features/Jupyter/components/CommandPalette/CommandPalette';
import {KeyEnum} from 'constants/keys';
import {TractoRootState} from 'store/reducers';
import {
    selectFocusedCellId,
    selectFocusedCellIndex,
} from 'features/Jupyter/store/selectors/notebook';
import {notebookSlice} from 'features/Jupyter/store/slices/notebook';
import {SelectOption} from '@gravity-ui/uikit';
import {useHotkey} from 'hooks/useHotkey';
import hotkeys from 'hotkeys-js';
import {addNotebookCell} from '../../store/actions/notebook/cell';
import {selectify} from '../../../../utils/select';
import {NotebookCellType} from '../../constants/cell';

enum CommandPaletteAction {
    AddCodeCellAbove = 'Add code cell above',
    AddCodeCellBelow = 'Add code cell below',

    RunAllCells = 'Run all cells',
    RunFocusedCell = 'Run focused cell',

    AddMarkdownCellAbove = 'Add markdown cell above',
    AddMarkdownCellBelow = 'Add markdown cell below',

    DeleteCell = 'Delete focused cell',

    ClearFocusedCellOuput = 'Clear focused cell output',
    ClearOuputOfAllCells = 'Clear all cells outputs',

    AddCHYTCellAbove = 'Add CHYT cell above',
    AddCHYTCellBelow = 'Add CHYT cell below',

    AddSPYTCellAbove = 'Add SPYT cell above',
    AddSPYTCellBelow = 'Add SPYT cell below',

    AddQLCellAbove = 'Add QL cell above',
    AddQLCellBelow = 'Add QL cell below',

    AddYQLCellAbove = 'Add YQL cell above',
    AddYQLCellBelow = 'Add YQL cell below',
}

const optionList: Array<{value: CommandPaletteAction; hidden?: boolean}> = [
    {
        value: CommandPaletteAction.AddCodeCellBelow,
    },
    {
        value: CommandPaletteAction.AddCodeCellAbove,
    },

    {
        value: CommandPaletteAction.AddMarkdownCellBelow,
    },
    {
        value: CommandPaletteAction.AddMarkdownCellAbove,
    },

    {
        value: CommandPaletteAction.AddCHYTCellBelow,
    },
    {
        value: CommandPaletteAction.AddCHYTCellAbove,
    },

    {
        value: CommandPaletteAction.AddSPYTCellBelow,
    },
    {
        value: CommandPaletteAction.AddSPYTCellAbove,
    },

    {
        value: CommandPaletteAction.AddQLCellBelow,
    },
    {
        value: CommandPaletteAction.AddQLCellAbove,
    },

    {
        value: CommandPaletteAction.AddYQLCellBelow,
    },
    {
        value: CommandPaletteAction.AddYQLCellAbove,
    },

    {
        value: CommandPaletteAction.DeleteCell,
    },

    {
        value: CommandPaletteAction.ClearFocusedCellOuput,
    },
    {
        value: CommandPaletteAction.ClearOuputOfAllCells,
    },

    {
        value: CommandPaletteAction.RunAllCells,
    },
    {
        value: CommandPaletteAction.RunFocusedCell,
    },
];

const options: SelectOption[] = optionList.filter((v) => !v.hidden).map(selectify);

const processCommand = (command: CommandPaletteAction) => {
    return (dispatch: TractoThunkDispatch, getState: () => TractoRootState) => {
        switch (command) {
            case CommandPaletteAction.RunAllCells: {
                dispatch(executeNotebookWithRequiredJupyt());
                break;
            }
            case CommandPaletteAction.RunFocusedCell: {
                dispatch(executeFocusedCell());
                break;
            }
            case CommandPaletteAction.AddCodeCellAbove: {
                const focusedCellIndex = selectFocusedCellIndex(getState());
                dispatch(
                    addNotebookCell({
                        currentIndex: focusedCellIndex - 1,
                        type: NotebookCellType.CODE,
                    }),
                );
                break;
            }
            case CommandPaletteAction.AddCodeCellBelow: {
                const focusedCellIndex = selectFocusedCellIndex(getState());
                dispatch(
                    addNotebookCell({
                        currentIndex: focusedCellIndex,
                        type: NotebookCellType.CODE,
                    }),
                );
                break;
            }
            case CommandPaletteAction.AddMarkdownCellAbove: {
                const focusedCellIndex = selectFocusedCellIndex(getState());
                dispatch(
                    addNotebookCell({
                        currentIndex: focusedCellIndex - 1,
                        type: NotebookCellType.MARKDOWN,
                    }),
                );
                break;
            }
            case CommandPaletteAction.AddMarkdownCellBelow: {
                const focusedCellIndex = selectFocusedCellIndex(getState());
                dispatch(
                    addNotebookCell({
                        currentIndex: focusedCellIndex,
                        type: NotebookCellType.MARKDOWN,
                    }),
                );
                break;
            }
            case CommandPaletteAction.AddCHYTCellAbove: {
                const focusedCellIndex = selectFocusedCellIndex(getState());
                dispatch(
                    addNotebookCell({
                        currentIndex: focusedCellIndex - 1,
                        type: NotebookCellType.CHYT,
                    }),
                );
                break;
            }
            case CommandPaletteAction.AddCHYTCellBelow: {
                const focusedCellIndex = selectFocusedCellIndex(getState());
                dispatch(
                    addNotebookCell({
                        currentIndex: focusedCellIndex,
                        type: NotebookCellType.CHYT,
                    }),
                );
                break;
            }
            case CommandPaletteAction.AddSPYTCellAbove: {
                const focusedCellIndex = selectFocusedCellIndex(getState());
                dispatch(
                    addNotebookCell({
                        currentIndex: focusedCellIndex - 1,
                        type: NotebookCellType.SPYT,
                    }),
                );
                break;
            }
            case CommandPaletteAction.AddSPYTCellBelow: {
                const focusedCellIndex = selectFocusedCellIndex(getState());
                dispatch(
                    addNotebookCell({
                        currentIndex: focusedCellIndex,
                        type: NotebookCellType.SPYT,
                    }),
                );
                break;
            }
            case CommandPaletteAction.AddQLCellAbove: {
                const focusedCellIndex = selectFocusedCellIndex(getState());
                dispatch(
                    addNotebookCell({
                        currentIndex: focusedCellIndex - 1,
                        type: NotebookCellType.QL,
                    }),
                );
                break;
            }
            case CommandPaletteAction.AddQLCellBelow: {
                const focusedCellIndex = selectFocusedCellIndex(getState());
                dispatch(
                    addNotebookCell({
                        currentIndex: focusedCellIndex,
                        type: NotebookCellType.QL,
                    }),
                );
                break;
            }
            case CommandPaletteAction.AddYQLCellAbove: {
                const focusedCellIndex = selectFocusedCellIndex(getState());
                dispatch(
                    addNotebookCell({
                        currentIndex: focusedCellIndex - 1,
                        type: NotebookCellType.YQL,
                    }),
                );
                break;
            }
            case CommandPaletteAction.AddYQLCellBelow: {
                const focusedCellIndex = selectFocusedCellIndex(getState());
                dispatch(
                    addNotebookCell({
                        currentIndex: focusedCellIndex,
                        type: NotebookCellType.YQL,
                    }),
                );
                break;
            }
            case CommandPaletteAction.DeleteCell: {
                const focusedCellIndex = selectFocusedCellIndex(getState());
                dispatch(notebookSlice.actions.deleteCell({currentIndex: focusedCellIndex}));
                dispatch(notebookSlice.actions.setFocusedCellByIndex({index: focusedCellIndex}));
                break;
            }
            case CommandPaletteAction.ClearOuputOfAllCells: {
                dispatch(notebookSlice.actions.clearCellsOutputs());
                break;
            }
            case CommandPaletteAction.ClearFocusedCellOuput: {
                const focusedCellId = selectFocusedCellId(getState());
                dispatch(
                    notebookSlice.actions.setCellOutputs({
                        cellId: focusedCellId,
                        outputs: [],
                    }),
                );
                break;
            }
        }
    };
};

export const CommandPaletteContainer = () => {
    const [open, setOpen] = useState(false);
    const dispatch = useTractoDispatch();

    const onUpdate = useCallback(([value]: string[]) => {
        setOpen(false);
        dispatch(processCommand(value as CommandPaletteAction));
    }, []);

    const onOpenChange = useCallback((open: boolean) => {
        setOpen(open);
    }, []);

    useHotkey({
        keys: 'command+p,control+p',
        handler: (event) => {
            event.preventDefault();
            setOpen(true);
        },
    });

    useHotkey({
        keys: 'escape',
        scope: 'tracto-command-palette',
        handler: (event) => {
            event.preventDefault();
            setOpen(false);
        },
    });

    const previousScope = useRef('');

    useEffect(() => {
        if (open) {
            previousScope.current = hotkeys.getScope();
            hotkeys.setScope('tracto-command-palette');
        } else {
            hotkeys.setScope(previousScope.current);
        }
    }, [open]);

    const lastShiftPress = useRef(0);

    useHotkey({
        keys: '*',
        handler: (event) => {
            const isShift = event.key === KeyEnum.SHIFT;

            if (!isShift) {
                lastShiftPress.current = 0;
                return;
            }

            const now = Date.now();

            if (now - lastShiftPress.current <= 500) {
                setOpen(true);
                lastShiftPress.current = 0;
            } else {
                lastShiftPress.current = now;
            }
        },
    });

    return (
        <CommandPalette
            options={options}
            onUpdate={onUpdate}
            onOpenChange={onOpenChange}
            open={open}
        />
    );
};
