import {useSelector} from 'react-redux';
import {selectIsWorkflowValid, selectWorkflow} from '../../store/selectors/workflow';
import React, {useMemo} from 'react';
import {WorkflowDocumentType} from '../../types/workflow';
import {GraphContainer} from '../GraphContainer/GraphContainer';
import {buildGraphFromWorkflow} from '../../utils/graph';
import type {GraphCustomizableEvents} from '../GraphContainer/type';
import {WorkflowGraphToolbar} from './WorkflowGraphToolbar/WorkflowGraphToolbar';

export const WorkflowGraphContainer = () => {
    const workflow = useSelector(selectWorkflow);
    const isWorkflowValid = useSelector(selectIsWorkflowValid);

    const events: GraphCustomizableEvents = useMemo(() => {
        return {
            click: (event) => {
                // Preventing these events will prevent delegate this event to the target component.
                // So preventing behavior implemented on the components, in that case we're preventing selection.
                event.preventDefault();
            },
        };
    }, []);

    const getData = React.useCallback(() => {
        if (!isWorkflowValid) {
            return Promise.reject(new Error('Invalid workflow format'));
        }

        let parsedWorkflow;

        try {
            parsedWorkflow = JSON.parse(workflow) as unknown as WorkflowDocumentType;
        } catch (error) {
            return Promise.reject(new Error('Invalid workflow format'));
        }

        return buildGraphFromWorkflow({workflow: parsedWorkflow});
    }, [workflow, isWorkflowValid]);

    return (
        <div>
            <WorkflowGraphToolbar />
            <GraphContainer events={events} getData={getData} />
        </div>
    );
};
