import {SecretListItem, SecretsList} from 'components/SecretsList/SecretsList';
import React, {ComponentProps} from 'react';
import Button from '@ytsaurus-ui-platform/src/ui/components/Button/Button';
import block from 'bem-cn-lite';
import './SecretStoreTabComponent.scss';
import {TractoFlexSplitPane} from 'components/TractoFlexSplitPane/TractoFlexSplitPane';
import {DelegationsListContainer} from 'features/SecretStore/containers/DelegationsListContainer/DelegationsListContainer';
import WithStickyToolbar from '@ytsaurus-ui-platform/src/ui/components/WithStickyToolbar/WithStickyToolbar';
import {NavigationTabLoader} from 'components/NavigationTabLoader/NavigationTabLoader';
import {SecretStoreButtonWithHotkeys} from '../SecretStoreButtonWithHotkeys/SecretStoreButtonWithHotkeys';
import {FloppyDisk} from '@gravity-ui/icons';
import {Icon} from '@gravity-ui/uikit';

const b = block('tracto-secret-store-tab');

type SecretStoreTabComponentProps = {
    value: SecretListItem[];
    onUpdate: (value: SecretListItem[]) => void;
    loading: boolean;
    onSaveClick: () => void;
    isSecretsSavingInProgress: boolean;
    isSaveDisabled: boolean;
    onCreateDelegationClick: () => void;
    isDelegationsAvailable: boolean;
};

export const SecretStoreTabComponent: React.FC<SecretStoreTabComponentProps> = ({
    value,
    onUpdate,
    loading,
    onSaveClick,
    onCreateDelegationClick,
    isDelegationsAvailable,
    isSecretsSavingInProgress,
    isSaveDisabled,
}) => {
    if (loading) {
        return <NavigationTabLoader />;
    }

    const secretsListProps: ComponentProps<typeof SecretsList> = {
        value,
        onUpdate,
    };

    let createDelegationButton: React.ReactNode = null;

    let delegationsContainer: React.ReactNode = null;

    if (isDelegationsAvailable) {
        createDelegationButton = (
            <Button size="m" onClick={onCreateDelegationClick}>
                Create delegation
            </Button>
        );
        delegationsContainer = (
            <div className={b('split-pane-item')}>
                <DelegationsListContainer />
            </div>
        );
    }

    const saveCta = isSaveDisabled ? 'Saved' : 'Save';

    return (
        <WithStickyToolbar
            className={b()}
            toolbar={
                <div className={b('toolbar')}>
                    <SecretStoreButtonWithHotkeys
                        id="save"
                        size="m"
                        onClick={onSaveClick}
                        disabled={isSaveDisabled}
                        loading={isSecretsSavingInProgress}
                        className={b('save-button')}
                    >
                        <Icon data={FloppyDisk} size={13} /> {saveCta}
                    </SecretStoreButtonWithHotkeys>
                    {createDelegationButton}
                </div>
            }
            content={
                <TractoFlexSplitPane
                    className={b('split-pane')}
                    direction={TractoFlexSplitPane.HORIZONTAL}
                >
                    <div className={b('split-pane-item')}>
                        <SecretsList {...secretsListProps} />
                    </div>
                    {delegationsContainer}
                </TractoFlexSplitPane>
            }
        />
    );
};
