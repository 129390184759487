import type {
    ExternalNotebookCell,
    ExternalNotebookCodeCell,
    ExternalNotebookMarkdownCell,
} from '../../../../types/version/external-notebook';
import {isCode, isMarkdown} from '@jupyterlab/nbformat';
import {isSQLCellType} from '../../../../utils/cell/view';
import type {
    V1NotebookCell,
    V1NotebookCodeCell,
    V1NotebookMarkdownCell,
} from '../../../../types/version/v1';
import {NotebookCellType} from '../../../../constants/cell';
import {TractoJupyterNotebookMetadataVersion} from '../../../../constants/version';
import type {SQLCellViewType} from '../../../../types/cell/sql';

const isExternalCodeCell = (cell: ExternalNotebookCell): cell is ExternalNotebookCodeCell =>
    isCode(cell) &&
    !isSQLCellType(cell.metadata.tracto?.type) &&
    !isSQLCellType(cell.metadata.tracto?.view_cell_type);

const isExternalMarkdownCell = (cell: ExternalNotebookCell): cell is ExternalNotebookMarkdownCell =>
    isMarkdown(cell);

const isExternalSQLCell = (cell: ExternalNotebookCell): cell is ExternalNotebookCodeCell =>
    isCode(cell) &&
    isSQLCellType(cell.metadata.tracto?.type) &&
    isSQLCellType(cell.metadata.tracto?.view_cell_type);

const getCodeCell = (cell: ExternalNotebookCodeCell): V1NotebookCodeCell => {
    return {
        ...cell,
        id: cell.metadata.cell_id ?? crypto.randomUUID(),
        metadata: {
            ...cell.metadata,
            tracto: {
                view_cell_type: NotebookCellType.CODE,
                metadata_version: TractoJupyterNotebookMetadataVersion.V1,
                hidden_output: false,
                hidden_input: false,
            },
        },
    };
};

const getMarkdownCell = (cell: ExternalNotebookMarkdownCell): V1NotebookMarkdownCell => {
    return {
        ...cell,
        id: cell.metadata.cell_id ?? crypto.randomUUID(),
        metadata: {
            ...cell.metadata,
            tracto: {
                view_cell_type: NotebookCellType.MARKDOWN,
                metadata_version: TractoJupyterNotebookMetadataVersion.V1,
            },
        },
    };
};

const getSQLCell = (cell: ExternalNotebookCodeCell): V1NotebookCodeCell => {
    return {
        ...cell,
        id: cell.metadata.cell_id ?? crypto.randomUUID(),
        metadata: {
            ...cell.metadata,
            tracto: {
                view_cell_type: (cell.metadata.tracto?.type ??
                    cell.metadata.tracto?.view_cell_type) as SQLCellViewType,
                metadata_version: TractoJupyterNotebookMetadataVersion.V1,
                sql: cell.metadata.tracto?.sql!,
            },
        },
    };
};

export const mapExternalNotebookCellToV1 = (cell: ExternalNotebookCell): V1NotebookCell => {
    if (isExternalCodeCell(cell)) {
        return getCodeCell(cell);
    }

    if (isExternalMarkdownCell(cell)) {
        return getMarkdownCell(cell);
    }

    if (isExternalSQLCell(cell)) {
        return getSQLCell(cell);
    }

    throw new Error('Unknown cell type called');
};
