import {WorkflowRunsListComponent} from 'features/Orchestracto/components/WorkflowRunsListComponent/WorkflowRunsListComponent';
import {
    selectRunId,
    selectRuns,
    selectRunsDateFilter,
    selectRunsLabels,
    selectRunsLimit,
} from 'features/Orchestracto/store/selectors/runs';
import {runsSlice} from 'features/Orchestracto/store/slices/runs';
import React, {useCallback, useEffect, useRef} from 'react';
import {batch, useSelector} from 'react-redux';
import {useTractoDispatch} from '../../../../store/tracto-dispatch';
import {type WorkflowRunData, WorkflowRunListItem} from '../../types/runs';
import {loadWorkflowRunState, loadWorkflowRunsFormCypress} from '../../store/actions/runs';
import {useHistory, useLocation} from 'react-router';
import {Updater} from '@ytsaurus-ui-platform/src/ui/utils/hammer/updater';
import {isPersistentWorkflowRunStatus} from '../../utils/workflow';
import type {RelativeRangeDatePickerValue} from '@gravity-ui/date-components';
import debounce from 'lodash/debounce';

export const WorkflowRunsListContainer: React.FC = () => {
    const dispatch = useTractoDispatch();
    const data = useSelector(selectRuns);
    const history = useHistory();
    const location = useLocation();
    const runId = useSelector(selectRunId);
    const limit = useSelector(selectRunsLimit);
    const filter = useSelector(selectRunsDateFilter);
    const labels = useSelector(selectRunsLabels);

    const updater = useRef<undefined | Updater>();

    useEffect(() => {
        if (runId) {
            updater.current?.destroy();

            dispatch(runsSlice.actions.setCurrentRunLoading({loading: true}));
            dispatch(loadWorkflowRunState({runId: runId}))
                .unwrap()
                .then((currentRun) => {
                    dispatch(runsSlice.actions.setCurrentRunLoading({loading: false}));

                    return currentRun;
                })
                .then((currentRun) => {
                    if (isPersistentWorkflowRunStatus(currentRun.orcApiRun.stage)) {
                        return;
                    }

                    const getPollingFn = () => {
                        let run: WorkflowRunData;

                        return async () => {
                            if (run && isPersistentWorkflowRunStatus(currentRun.orcApiRun.stage)) {
                                updater.current?.destroy();
                                return;
                            }

                            run = await dispatch(loadWorkflowRunState({runId: runId})).unwrap();
                        };
                    };

                    updater.current = new Updater(getPollingFn(), 5000);
                });
        }

        return () => {
            updater.current?.destroy();
        };
    }, [runId]);

    const onRowClick = useCallback((item: WorkflowRunListItem) => {
        const searchParams = new URLSearchParams(location.search);

        searchParams.set('runId', item.key);

        const newUrl = `${location.pathname}?${searchParams.toString()}`;

        history.push(newUrl);
    }, []);

    const handleDatetimeChange = useCallback((v: RelativeRangeDatePickerValue | null) => {
        if (v?.start?.value && v.end?.value) {
            batch(() => {
                dispatch(runsSlice.actions.setFilter(v));
                dispatch(loadWorkflowRunsFormCypress({filter: v}));
            });
        }
    }, []);

    const handleLimitChangeThrottled = React.useMemo(() => {
        return debounce((v: string) => {
            dispatch(loadWorkflowRunsFormCypress({limit: v}));
        }, 300);
    }, []);

    const handleLabelsChangeThrottled = React.useMemo(() => {
        return debounce((v: string[]) => {
            dispatch(loadWorkflowRunsFormCypress({labels: v}));
        }, 300);
    }, []);

    const handleLimitChange = useCallback(
        (v: string) => {
            if (!/(^\d+$|^$)/g.test(v)) {
                return;
            }

            dispatch(runsSlice.actions.setLimit(v));
            handleLimitChangeThrottled(v);
        },
        [handleLimitChangeThrottled],
    );

    const handleLabelsChange = useCallback(
        (v: string[]) => {
            dispatch(runsSlice.actions.setLabels(v));
            handleLabelsChangeThrottled(v);
        },
        [handleLabelsChangeThrottled],
    );

    return (
        <WorkflowRunsListComponent
            limit={limit}
            filter={filter}
            data={data}
            labels={labels}
            onRowClick={onRowClick}
            selectedItemId={runId}
            onLimitChange={handleLimitChange}
            onDatetimeChange={handleDatetimeChange}
            onLabelsChange={handleLabelsChange}
        />
    );
};
