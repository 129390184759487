import React, {useCallback} from 'react';
import block from 'bem-cn-lite';

import './WorkflowGraphToolbar.scss';
import {Flex, Icon} from '@gravity-ui/uikit';
import {CirclePlay, FloppyDisk} from '@gravity-ui/icons';
import {useHotkey} from 'hooks/useHotkey';
import {useTractoDispatch} from 'store/tracto-dispatch';
import {useSelector} from 'react-redux';
import {
    selectIsSavingInProgress,
    selectIsWorkflowChanged,
    selectIsWorkflowRunning,
    selectIsWorkflowValid,
} from 'features/Orchestracto/store/selectors/workflow';
import {
    dispatchWorkflowActionWithValidation,
    saveWorkflowInCypress,
    userRunOneTimeRunWorkflow,
} from 'features/Orchestracto/store/actions/workflow';
import {workflowSlice} from 'features/Orchestracto/store/slices/workflow';
import {OrchestractoButtonWithHotkeys} from 'features/Orchestracto/components/OrchestractoButtonWithHotkeys/OrchestractoButtonWithHotkeys';
import {OneTimeRunDialog} from '../OneTimeRunDialog/OneTimeRunDialog';

const b = block('workflow-graph-toolbar');

export const WorkflowGraphToolbar: React.FC = () => {
    const dispatch = useTractoDispatch();

    const isWorkflowValid = useSelector(selectIsWorkflowValid);
    const isRunLoading = useSelector(selectIsWorkflowRunning);
    const isSaveLoading = useSelector(selectIsSavingInProgress);
    const isWorkflowChanged = useSelector(selectIsWorkflowChanged);

    const isSaveDisabled = !isWorkflowValid || !isWorkflowChanged;
    const isRunDisabled = !isWorkflowValid;

    let saveCta = 'Save';

    if (!isWorkflowChanged) {
        saveCta = 'Saved';
    }

    if (!isWorkflowValid) {
        saveCta = 'Workflow is invalid';
    }

    const onSave = useCallback(() => {
        dispatch(workflowSlice.actions.startSaving());

        const action = () =>
            dispatch(saveWorkflowInCypress()).finally(() => {
                dispatch(workflowSlice.actions.finishSaving());
            });

        dispatch(dispatchWorkflowActionWithValidation(action));
    }, []);

    const onRunWithParams = useCallback(() => {
        dispatch(workflowSlice.actions.showOneTimeRunDialog());
    }, []);

    const onRun = useCallback(() => {
        dispatch(workflowSlice.actions.setRunning({running: true}));

        const action = () =>
            dispatch(userRunOneTimeRunWorkflow({labels: [], params: {}}))
                .unwrap()
                .finally(() => {
                    dispatch(workflowSlice.actions.setRunning({running: false}));
                });

        return dispatch(dispatchWorkflowActionWithValidation(action));
    }, []);

    useHotkey({
        keys: 'control+s,command+s',
        handler: (event) => {
            event.preventDefault();
            if (!isSaveDisabled) {
                onSave();
            }
        },
    });

    useHotkey({
        keys: 'shift+enter',
        handler: (event) => {
            event.preventDefault();
            if (!isRunDisabled) {
                onRun();
            }
        },
    });

    useHotkey({
        keys: 'shift+p+enter',
        handler: (event) => {
            event.preventDefault();
            if (!isRunDisabled) {
                onRunWithParams();
            }
        },
    });

    return (
        <div className={b()}>
            <Flex className={b('controls')} gap="2">
                <OrchestractoButtonWithHotkeys
                    className={b('save-button')}
                    id="save"
                    onClick={onSave}
                    disabled={isSaveDisabled}
                    loading={isSaveLoading}
                >
                    <Icon data={FloppyDisk} size={13} /> {saveCta}
                </OrchestractoButtonWithHotkeys>
                <OrchestractoButtonWithHotkeys
                    className={b('run-button')}
                    id="run"
                    onClick={onRun}
                    disabled={isRunDisabled}
                    loading={isRunLoading}
                >
                    <Icon data={CirclePlay} size={13} /> Run
                </OrchestractoButtonWithHotkeys>
                <OrchestractoButtonWithHotkeys
                    className={b('run-with-params-button')}
                    id="runWithParams"
                    onClick={onRunWithParams}
                    disabled={isRunDisabled}
                >
                    <Icon data={CirclePlay} size={13} /> Run with params
                </OrchestractoButtonWithHotkeys>
                <OneTimeRunDialog />
            </Flex>
        </div>
    );
};
