import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {selectRunId} from 'features/Orchestracto/store/selectors/runs';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';
import {selectModalStepDetails} from 'features/Orchestracto/store/selectors/modals';
import {OrchestractoApi} from 'features/Orchestracto/api';
import {OrchestarctoLogEntry} from 'features/Orchestracto/api/logs/types';
import {OrchestarctoLogComponent} from 'features/Orchestracto/components/OrchestarctoLogsComponent/OrchestarctoLogComponent';

export const OrchestarctoLogContainer = () => {
    const runId = useSelector(selectRunId);
    const workflowPath = useSelector(getPath);
    const step = useSelector(selectModalStepDetails);
    const stepId = step?.step_id;
    const [log, setLog] = useState<OrchestarctoLogEntry[]>([]);

    useEffect(() => {
        if (workflowPath && runId && stepId) {
            OrchestractoApi.api.logs.getLogs({workflowPath, runId, stepId}).then((data) => {
                setLog(data.log);
            });
        }
        return () => {};
    }, [workflowPath, runId, stepId]);

    const props = {
        log,
    };

    return <OrchestarctoLogComponent {...props} />;
};
