import type {TractoRootState} from 'store/reducers';
import _isEqual from 'lodash/isEqual';
import {SecretItem} from '../slices/secretsSlice';

export const selectSecrets = (state: TractoRootState) => state.tracto.secretStore.secrets.secrets;

export const selectIsSaveDisabled = (state: TractoRootState) => {
    const {initialSecrets, secrets} = state.tracto.secretStore.secrets;
    const mappedSecrets: SecretItem[] = secrets.map(({value, name}) => ({value, name}));
    const hasErrors = secrets.some((item) => item.nameError);

    return hasErrors || _isEqual(initialSecrets, mappedSecrets);
};
