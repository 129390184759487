import axios from 'axios';
import type {BlueprintType} from '../../../../../types/blueprint';
import {getBlueprintsBaseUrl} from '../../../../../utils/blueprint';
import {createBlueprint as _createBlueprint} from '../../../../../yt-api/v4/blueprint';

type GetBlueprintPayload = {
    blueprint_id: string;
};

type GetBlueprintResponse = {
    blueprint: BlueprintType;
};

export const getBlueprint = ({blueprint_id}: GetBlueprintPayload) => {
    return () => {
        return axios
            .get<GetBlueprintResponse>(`${getBlueprintsBaseUrl()}/${blueprint_id}`)
            .then((response) => response.data);
    };
};

type CreateBlueprintPayload = {
    blueprint_id: string;
    blueprint?: BlueprintType;
};

export const createBlueprint = ({blueprint_id}: CreateBlueprintPayload) => {
    return () => {
        return _createBlueprint(blueprint_id);
    };
};

type SaveBlueprintPayload = {
    blueprint_id: string;
    blueprint: BlueprintType;
};

export const saveBlueprint = ({blueprint_id, blueprint}: SaveBlueprintPayload) => {
    return () => {
        return axios.put(`${getBlueprintsBaseUrl()}/${blueprint_id}`, {blueprint});
    };
};

type DeleteBlueprintPayload = {
    blueprint_id: string;
};

export const deleteBlueprint = ({blueprint_id}: DeleteBlueprintPayload) => {
    return () => {
        return axios.delete(`${getBlueprintsBaseUrl()}/${blueprint_id}`);
    };
};
