import {Icon, QAProps} from '@gravity-ui/uikit';
import {Play, Stop} from '@gravity-ui/icons';
import React from 'react';
import {ACTIONS_CONTAINER_CONTROL_SIZE, ACTIONS_CONTAINER_ICON_SIZE} from '../../constants/size';
import {JupyterButtonWithHotkeys} from '../../../JupyterButtonWithHotkeys/JupyterButtonWithHotkeys';
import block from 'bem-cn-lite';
import './ExecuteButton.scss';

const b = block('execute-button');

type ExecuteButtonProps = {
    isQueued?: boolean;
    isRunning?: boolean;
    isExecuted?: boolean;
    onRunClick?: (event: React.MouseEvent<HTMLElement>) => void;
    onCancelClick?: (event: React.MouseEvent<HTMLElement>) => void;
    status: string;
};

export function ExecuteButton({
    isRunning,
    isExecuted,
    onRunClick,
    onCancelClick,
    qa,
    status,
}: ExecuteButtonProps & QAProps) {
    const icon = isRunning ? Stop : Play;
    const onClick = isRunning
        ? (event: React.MouseEvent<HTMLElement>) => {
              if (onCancelClick) {
                  onCancelClick(event);
              }
          }
        : onRunClick;

    return (
        <div className={b()}>
            <JupyterButtonWithHotkeys
                id="run"
                qa={qa}
                size={ACTIONS_CONTAINER_CONTROL_SIZE}
                view="flat"
                onClick={onClick}
                selected={isRunning}
            >
                <Icon data={icon} size={ACTIONS_CONTAINER_ICON_SIZE} />
            </JupyterButtonWithHotkeys>
            <div title={status} className={b('status', {executed: isExecuted, running: isRunning})}>
                {status}
            </div>
        </div>
    );
}
