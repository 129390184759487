import * as React from 'react';
import {useCallback, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Button, Flex, Icon} from '@gravity-ui/uikit';
import xmarkSvg from '@gravity-ui/icons/svgs/xmark.svg';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';
import {getCluster} from '@ytsaurus-ui-platform/src/ui/store/selectors/global';
import block from 'bem-cn-lite';

import {
    deleteImage,
    fetchImage,
    getPublicAccessAttr,
    imageSlice,
    setPublicAccessAttr,
} from '../../store/slices/image';
import {RegistryTagsCancelHelper, fetchTags} from '../../store/slices/tags';
import {useTractoDispatch} from '../../../../store/tracto-dispatch';
import {
    getDigestParams,
    getDockerFile,
    getLayersTableData,
    getSelectedTag,
    getTagsTableData,
    getTractoRegistryBlobConfig,
    hasPublicAccess,
    isImageTabLoading,
} from '../../store/selectors/repositories';
import {TractoRegistryTagsList} from '../TractoRegistryTagsList/TractoRegistryTagsList';
import {TractoRegistryRepositoriesTagDescription} from '../TractoRegistryRepositoriesTagDescription/TractoRegistryRepositoriesTagDescription';
import {TractoRegistryImageLayers} from '../TractoRegistryImageLayersTable/TractoRegistryImageLayers';
import {NavigationTabLoader} from 'components/NavigationTabLoader/NavigationTabLoader';
import {getTractoRegistryUrl} from '../../../../store/selectors/tracto-cluster-ui-config';
import {TractoFlexSplitPane} from '../../../../components/TractoFlexSplitPane/TractoFlexSplitPane';

import './TractoRegistryTab.scss';

const b = block('tracto-registry-tab');

export interface TractoRegistryTabProps {}

export const TractoRegistryTab: React.FunctionComponent<TractoRegistryTabProps> = () => {
    const dispatch = useTractoDispatch();
    const path: string = useSelector(getPath);
    const cluster = useSelector(getCluster);
    const selectedDigest = useSelector(getDigestParams);
    const isLoading = useSelector(isImageTabLoading);
    const tags = useSelector(getTagsTableData);
    const layers = useSelector(getLayersTableData);
    const tractoRegistryUrl = useSelector(getTractoRegistryUrl);
    const blobConfig = useSelector(getTractoRegistryBlobConfig);
    const dockerFile = useSelector(getDockerFile);
    const selectedTag = useSelector(getSelectedTag);
    const isPublic = useSelector(hasPublicAccess);

    const repository = path
        .split('/')
        .filter(Boolean)
        .filter((segment) => segment !== '_tags')
        .join('/');

    useEffect(() => {
        dispatch(getPublicAccessAttr({cluster, repository}));
        dispatch(
            fetchImage({
                cluster,
                repository,
            }),
        )
            .unwrap()
            .then((image) => dispatch(fetchTags({cluster, image})));

        return () => {
            RegistryTagsCancelHelper.removeAllRequests();
        };
    }, [path, cluster, repository]);

    const handleDeleteImage = useCallback(
        (tag: string) => {
            dispatch(
                deleteImage({
                    tag,
                    cluster,
                    repository,
                }),
            );
        },
        [cluster, repository],
    );

    if (!tractoRegistryUrl) {
        return <div>Tracto registry is not defined.</div>;
    }

    if (isLoading) {
        return <NavigationTabLoader />;
    }

    const content = [
        <div className={b('split-pane-item')}>
            <TractoRegistryTagsList
                tags={tags}
                repository={repository}
                tractoRegistryUrl={tractoRegistryUrl}
                onDeleteImage={handleDeleteImage}
                onRowClick={(item) => {
                    dispatch(imageSlice.actions.toggleTag(item));
                }}
                isPublic={isPublic}
                onAccessToggle={(value) =>
                    dispatch(
                        setPublicAccessAttr({
                            cluster,
                            repository,
                            value: value,
                        }),
                    )
                }
            />
        </div>,
    ];

    if (selectedDigest && selectedTag) {
        content.push(
            <div className={b('description')}>
                <div className={b('description-inner')}>
                    <Button
                        view="outlined"
                        className={b('close-btn')}
                        onClick={() => dispatch(imageSlice.actions.toggleTag({tag: undefined}))}
                    >
                        <Icon data={xmarkSvg} size="16" />
                    </Button>
                    <Flex width="100%" wrap="wrap" className={b('wrapper')}>
                        <div className={b('section')}>
                            <TractoRegistryRepositoriesTagDescription
                                tag={selectedTag}
                                blobConfig={blobConfig}
                                dockerFile={dockerFile}
                                repository={repository}
                                tractoRegistryUrl={tractoRegistryUrl}
                                buildOperationLink={(value) => {
                                    return `/${cluster}/operations/${value}/details`;
                                }}
                                buildBluePrintLink={(value) => {
                                    return `/${cluster}/navigation?path=//${value}`;
                                }}
                            />
                        </div>
                        <div className={b('section')}>
                            <TractoRegistryImageLayers
                                layers={layers}
                                repository={repository}
                                tractoRegistryUrl={tractoRegistryUrl}
                            />
                        </div>
                    </Flex>
                </div>
            </div>,
        );
    }

    return (
        <div>
            <TractoFlexSplitPane className={b('split')} direction={TractoFlexSplitPane.HORIZONTAL}>
                {content}
            </TractoFlexSplitPane>
        </div>
    );
};
