import React from 'react';
import type * as monaco from 'monaco-editor';
import TractoMonacoEditor from '../../../../../../components/TractoMonacoEditor/TractoMonacoEditor';
import {sourceToString} from '../../../../utils/cell/source';
import {JupyterCellContentLayout} from '../../../Layout/JupyterCellContentLayout/JupyterCellContentLayout';
import type {TractoNotebookCodeCell} from '../../../../types/version';
import {JupyterExecutableCellContent} from '../../../JupyterExecutableCellContent/JupyterExecutableCellContent';
import {useCellExecution} from '../../../../hooks/use-cell-execution';

type PythonCellSourceProps = {
    editorRef: React.MutableRefObject<monaco.editor.IStandaloneCodeEditor | undefined>;
    onChange: (value: string) => void;
    onEditorFocus?: React.FocusEventHandler<HTMLDivElement>;
    onEditorBlur?: () => void;
    cell: TractoNotebookCodeCell;
    isDirty?: boolean;
    isFocused?: boolean;
    isExecuted?: boolean;
    isEditable?: boolean;
};

export const PythonCellSource: React.FC<PythonCellSourceProps> = (props: PythonCellSourceProps) => {
    const {
        onChange,
        editorRef,
        onEditorFocus,
        onEditorBlur,
        cell,
        isDirty,
        isFocused,
        isExecuted,
        isEditable,
    } = props;

    const {count} = useCellExecution({cell});

    const source = cell.source;

    const value = sourceToString(source);

    const renderHeaderContent = React.useCallback(() => {
        return (
            <JupyterExecutableCellContent
                count={count}
                isDirty={isDirty}
                isFocused={isFocused}
                isExecuted={isExecuted}
                isEditable={isEditable}
            />
        );
    }, [count, isFocused, isDirty, isExecuted, isEditable]);

    return (
        <JupyterCellContentLayout renderHeaderContent={renderHeaderContent}>
            <TractoMonacoEditor
                editorRef={editorRef}
                language="python"
                value={value}
                onChange={onChange}
                onEditorFocus={onEditorFocus}
                onBlur={onEditorBlur}
            />
        </JupyterCellContentLayout>
    );
};
