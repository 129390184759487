import React, {useCallback} from 'react';
import {useTractoDispatch} from '../../../../store/tracto-dispatch';
import {JupytListPageTopRow} from '../../components/JupytListPageTopRow/JupytListPageTopRow';
import {loadJupytPageList} from '../../store/actions/jupyt/jupyt';
import {modalCreateDocumentSlice} from 'features/Navigation/store/slices/modals/modal-create-document';
import {DESCRIBE_OPTIONS} from '../../constants/dialog-jupyt-speclet';
import {JupytSpecletCreateDialogContainer} from '../Dialogs/JupytSpecletDialogContainer/JupytSpecletCreateDialogContainer';
import type {StrawberryDescribeOptionsType} from '@ytsaurus-ui-platform/src/ui/utils/strawberryControllerApi';
import {modalJupytSpecletSlice} from '../../store/slices/modals/modal-jupyt-speclet';

export const JupytListPageTopRowContainer = () => {
    const dispatch = useTractoDispatch();

    const handleCreateKernelClick = useCallback(() => {
        dispatch(modalJupytSpecletSlice.actions.open({type: 'create'}));
    }, []);

    const handleCreateNotebookClick = useCallback(() => {
        dispatch(modalCreateDocumentSlice.actions.open({documentType: 'notebook'}));
    }, []);

    const handleJupytCreateDialogApply = React.useCallback(() => {
        return dispatch(loadJupytPageList());
    }, [dispatch]);

    return (
        <>
            <JupytListPageTopRow
                onCreateKernelClick={handleCreateKernelClick}
                onCreateNotebookClick={handleCreateNotebookClick}
            />
            <JupytSpecletCreateDialogContainer
                dialogConfig={DESCRIBE_OPTIONS.result as StrawberryDescribeOptionsType}
                onApply={handleJupytCreateDialogApply}
            />
        </>
    );
};
