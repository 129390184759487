import React from 'react';

import {useSelector} from 'react-redux';
import {YTDFDialog} from '@ytsaurus-ui-platform/src/ui/components/Dialog';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';
import {updateView} from '@ytsaurus-ui-platform/src/ui/store/actions/navigation';
import {useTractoDispatch} from '../../../../store/tracto-dispatch';
import {modalCreateDocumentSlice} from 'features/Navigation/store/slices/modals/modal-create-document';
import {selectModalCreateDocumentState} from 'features/Navigation/store/selectors/modals/modal-create-document';
import {createSecretStoreInCypress} from 'features/SecretStore/store/actions/secrets';
import {getCurrentUserName} from '@ytsaurus-ui-platform/src/ui/store/selectors/global';
import {normalizePath} from 'utils/path';

type SecretStoreType = 'empty' | 'docker-credentials' | 'docker-auth' | 'tracto-docker-credentials';

type FormValues = {
    documentName: string;
    documentPath: string;
    storeType: SecretStoreType;
    username?: string;
    password?: string;
    auth?: string;
    yt_token?: string;
    yt_username?: string;
};

const isDockerCredSelected = (value: SecretStoreType) => value === 'docker-credentials';
const isTractoDockerCredSelected = (value: SecretStoreType) =>
    value === 'tracto-docker-credentials';

export const SecretStoreCreateDialog = () => {
    const dispatch = useTractoDispatch();
    const state = useSelector(selectModalCreateDocumentState);
    const userName = useSelector(getCurrentUserName);
    const path = useSelector(getPath);

    const getSecretsFromFormValues = (values: FormValues) => {
        switch (values.storeType) {
            case 'tracto-docker-credentials':
                return {
                    username: {
                        value: values.yt_username,
                    },
                    password: {
                        value: values.yt_token,
                    },
                };
            case 'docker-credentials':
                return {
                    username: {
                        value: values.username,
                    },
                    password: {
                        value: values.password,
                    },
                };

            case 'docker-auth':
                return {
                    auth: {
                        value: values.auth,
                    },
                };
            default:
                return {};
        }
    };

    return (
        <YTDFDialog<FormValues>
            initialValues={{
                documentName: 'new_secret_store',
                documentPath: `${path}/`,
                storeType: 'empty',
                yt_username: userName,
            }}
            visible={state.documentType === 'secret-store'}
            headerProps={{title: 'Create secret store'}}
            onAdd={(args) => {
                const {values} = args.getState();
                const {documentName, documentPath} = values;

                const path = `${normalizePath(documentPath)}${documentName}`;

                return dispatch(
                    createSecretStoreInCypress({
                        path,
                        secrets: getSecretsFromFormValues(values),
                    }),
                )
                    .then(({payload}) => {
                        return payload
                            ? dispatch(modalCreateDocumentSlice.actions.close())
                            : Promise.reject();
                    })
                    .then(() => dispatch(updateView()));
            }}
            onClose={() => {
                dispatch(modalCreateDocumentSlice.actions.close());
            }}
            pristineSubmittable
            fields={[
                {
                    name: 'documentName',
                    type: 'text',
                    caption: 'Name',
                    required: true,
                    extras: {
                        autoFocus: true,
                    },
                },
                {
                    name: 'documentPath',
                    type: 'path',
                    caption: 'Directory',
                    required: true,
                },
                {
                    name: 'storeType',
                    type: 'yt-select-single',
                    caption: 'Secret store type',
                    extras: {
                        width: 'max',
                        hideFilter: true,
                        items: [
                            {value: 'empty', text: 'Empty (no keys)'},
                            {
                                value: 'docker-credentials',
                                text: 'Docker registry by username and password',
                            },
                            {value: 'docker-auth', text: 'Docker registry by auth data'},
                            {
                                value: 'tracto-docker-credentials',
                                text: 'TractoAI docker registry',
                            },
                        ],
                    },
                },
                {
                    name: 'username',
                    type: 'text',
                    caption: 'Username',
                    tooltip: 'Docker registry username',
                    required: true,
                    visibilityCondition: {
                        when: 'storeType',
                        isActive: isDockerCredSelected,
                    },
                },
                {
                    name: 'password',
                    type: 'text',
                    caption: 'Password',
                    tooltip: 'Docker registry password',
                    required: true,
                    visibilityCondition: {
                        when: 'storeType',
                        isActive: isDockerCredSelected,
                    },
                },
                {
                    name: 'auth',
                    type: 'text',
                    caption: 'Auth Data',
                    tooltip: 'Docker registry auth data',
                    required: true,
                    visibilityCondition: {
                        when: 'storeType',
                        isActive: (value: string) => value === 'docker-auth',
                    },
                },
                {
                    name: 'yt_username',
                    type: 'text',
                    caption: 'Username',
                    required: true,
                    visibilityCondition: {
                        when: 'storeType',
                        isActive: isTractoDockerCredSelected,
                    },
                },
                {
                    name: 'yt_token',
                    type: 'yt-token-input',
                    caption: 'Token',
                    required: true,
                    visibilityCondition: {
                        when: 'storeType',
                        isActive: isTractoDockerCredSelected,
                    },
                } as any,
            ]}
        />
    );
};
