import React from 'react';
import {
    JupyterSQLCell,
    type JupyterSQLCellProps,
} from '../../components/JupyterCell/JupyterSQLCell/JupyterSQLCell';
import {useJupyterCell} from './hooks/use-jupyter-cell';
import {useJupyterExecutableCell} from './hooks/use-jupyter-executable-cell';
import type {TractoNotebookCodeCell} from '../../types/version';

const useJupyterSQLCell = (
    cell: TractoNotebookCodeCell,
    cellIndex: number,
): JupyterSQLCellProps => {
    const {onSourceChange, isEditable, isFocused, onClick, onEditorFocus, onEditorBlur} =
        useJupyterCell(cell);

    const {isDirty, isExecuted} = useJupyterExecutableCell(cell);

    return {
        cell,
        isDirty,
        onEditorFocus,
        onSourceChange,
        onClick,
        isEditable,
        isFocused,
        isExecuted,
        index: cellIndex,
        onEditorBlur,
    };
};

type JupyterSQLCellContainerProps = {
    cell: TractoNotebookCodeCell;
    cellIndex: number;
};

export const JupyterSQLCellContainer: React.FC<JupyterSQLCellContainerProps> = (
    props: JupyterSQLCellContainerProps,
) => {
    const {cell, cellIndex} = props;

    return <JupyterSQLCell {...useJupyterSQLCell(cell, cellIndex)} />;
};
