import {getClusterUiConfig} from '@ytsaurus-ui-platform/src/ui/store/selectors/global';
import type {TractoRootState} from 'store/reducers';
import type {TractoClusterUiConfig} from '../../../shared/types/common/tracto-cluster-ui-config';

export const getTractoClusterUiConfig = (state: TractoRootState) =>
    getClusterUiConfig(state) as TractoClusterUiConfig;

export const getTractoRegistryUrl = (state: TractoRootState) => {
    const {tracto_registry_base_url: tractoRegistryUrl} = getTractoClusterUiConfig(state);

    return tractoRegistryUrl?.replace(/^(https|http):\/\//, '');
};

export const getBlueprintWizardUrl = (state: TractoRootState) => {
    const {image_builder_base_url} = getTractoClusterUiConfig(state);

    return image_builder_base_url;
};
