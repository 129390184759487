import React from 'react';
import type {ExtraTab} from '@ytsaurus-ui-platform/src/ui/UIFactory';
import {Tab as NavigationTab} from '@ytsaurus-ui-platform/src/ui/constants/navigation/index';
import {WorkflowTabContainer} from './containers/WorkflowTabContainer/WorkflowTabContainer';
import {WorkflowRunsTabContainer} from './containers/WorkflowRunsTabContainer/WorkflowRunsTabContainer';
import {IS_WORKFLOW_ATTRIBUTE} from './constants/navigation';
import {Icon} from '@gravity-ui/uikit';
import {NodesLeft} from '@gravity-ui/icons';
import type {Node} from '@ytsaurus-ui-platform/src/ui/utils/navigation/content/map-nodes/node';
import {produce} from 'immer';
import type {TractoRootState} from 'store/reducers';
import type {RootState} from '@ytsaurus-ui-platform/src/ui/store/reducers/index';

const RunsTabParams = {
    runId: {
        stateKey: 'tracto.orchestracto.runs.runId',
        options: {
            shouldPush: true,
        },
    },
};

function getRunsTabPreparedState(_state: RootState, {query: _query}: {query: RootState}) {
    const state = _state as TractoRootState;
    const query = _query as TractoRootState;

    return produce(state, (draft) => {
        if (draft.tracto.orchestracto.runs.runId !== query.tracto.orchestracto.runs.runId) {
            draft.tracto.orchestracto.runs.runId = query.tracto.orchestracto.runs.runId;
        }
    });
}

export const WORKFLOW_NAVIGATION_TAB: ExtraTab = {
    value: `extra_workflow`,
    text: 'Workflow',
    title: 'Go to workflow interface',
    component: WorkflowTabContainer,
    position: {before: NavigationTab.CONTENT},
    isSupported: (attributes) => {
        return attributes[IS_WORKFLOW_ATTRIBUTE];
    },
    additionalAttributes: [IS_WORKFLOW_ATTRIBUTE],
};

export const WORKFLOW_RUNS_NAVIGATION_TAB: ExtraTab = {
    value: `extra_workflow_runs`,
    text: 'Runs',
    title: 'Go to workflow runs interface',
    component: WorkflowRunsTabContainer,
    position: {before: NavigationTab.CONTENT},
    isSupported: (attributes) => {
        return attributes[IS_WORKFLOW_ATTRIBUTE];
    },

    urlMapping: {
        params: RunsTabParams,
        getPreparedState: getRunsTabPreparedState,
    },
    additionalAttributes: [IS_WORKFLOW_ATTRIBUTE],
};

export const WORKFLOW_NAVIGATION_MAP_NODE_SETTINGS = {
    additionalAttributes: [IS_WORKFLOW_ATTRIBUTE],
    renderNodeIcon: (item: Node) => {
        if (item.$attributes?.[IS_WORKFLOW_ATTRIBUTE]) {
            return <Icon data={NodesLeft} size={14} />;
        }

        return null;
    },
};
