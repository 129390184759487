import React from 'react';
import {JupyterCellQa} from '../../../../../../shared/qa';
import {getAnchorHashCellId} from '../../../utils/cell/anchor';
import block from 'bem-cn-lite';
import './JupyterCellLayout.scss';

type JupyterCellLayoutProps = {
    cellId: string;
    layout: 'editable' | 'focused' | 'default' | 'executed';
    renderControls: () => React.JSX.Element | null;
    renderSource: () => React.JSX.Element | null;
    renderOutput: () => React.JSX.Element | null;
} & Omit<
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    'ref' | 'id' | 'data-qa' | 'tabIndex' | 'data-id'
>;

const b = block('jupyter-cell-layout');

export const JupyterCellLayout = React.forwardRef<null | HTMLDivElement, JupyterCellLayoutProps>(
    (props: JupyterCellLayoutProps, ref) => {
        const {
            cellId,
            renderControls,
            renderSource,
            renderOutput,
            layout,
            className,
            ...htmlProps
        } = props;

        return (
            <div
                data-qa={JupyterCellQa.JupyterCell}
                tabIndex={-1}
                data-id={cellId}
                className={b({[layout]: true}, className)}
                ref={ref}
                id={getAnchorHashCellId(cellId)}
                {...htmlProps}
            >
                {renderControls()}
                {renderSource()}
                {renderOutput()}
            </div>
        );
    },
);
