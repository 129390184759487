import React, {useCallback} from 'react';
import {useTractoDispatch} from '../../../../store/tracto-dispatch';
import {JupytSecretsMapType} from '../../../../../shared/types/jupyt/secrets';
import {JupytSecretsTab} from 'features/Jupyter/components/JupytSecretsTab/JupytSecretsTab';
import {SecretsEditorDialogButton} from 'features/Jupyter/components/SecretsEditorDialog/SecretsEditorDialog';
import {loadJupytSecrets, setJupytSecrets} from '../../store/actions/jupyt/secrets';
import {selectJupyterSecrets} from '../../store/selectors/secrets';
import {useSelector} from 'react-redux';

type JupytSecretsTabContainerProps = {
    alias: string;
};

export const JupytSecretsTabContainer: React.FC<JupytSecretsTabContainerProps> = React.memo(
    ({alias}) => {
        const dispatch = useTractoDispatch();
        const secrets = useSelector(selectJupyterSecrets);

        const onAddSecrets = useCallback(
            async (secrets: JupytSecretsMapType) => {
                await dispatch(setJupytSecrets({alias, secrets}));

                await dispatch(loadJupytSecrets({alias}));
            },
            [alias],
        );

        const renderSecretsEditor = useCallback(() => {
            return <SecretsEditorDialogButton secrets={secrets} onAdd={onAddSecrets} />;
        }, [secrets, onAddSecrets]);

        return <JupytSecretsTab secrets={secrets} renderSecretsEditor={renderSecretsEditor} />;
    },
);
