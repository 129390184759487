import React from 'react';
import {Graph, GraphCanvas} from '@gravity-ui/graph';
import ErrorBlock from '@ytsaurus-ui-platform/src/ui/components/Block/Block';
import block from 'bem-cn-lite';
import './GraphComponent.scss';

import type {WorkflowTBlock} from '../../libs/graph/canvas/WorkflowBlock';

const b = block('graph-component');

type GraphComponentProps = {
    graph: Graph;
    error: Error | null;
    renderBlock: (graph: Graph, block: WorkflowTBlock) => React.ReactNode;
};

export const GraphComponent: React.FC<GraphComponentProps> = ({graph, error, renderBlock}) => {
    if (error) {
        return <ErrorBlock className={b('error')} error={error} />;
    }

    return (
        <GraphCanvas
            graph={graph}
            /*@ts-expect-error*/
            renderBlock={renderBlock}
        />
    );
};
