import type {TractoNotebookBase, UnrecognizedTractoNotebookBase} from '../../../../types/version';
import {isExternalNotebookContent} from './guards/is-external-notebook-content';
import {mapExternalContentToV1} from './v1';

export const mapNotebookBaseToLatestVersion = (
    unrecognizedContent: UnrecognizedTractoNotebookBase,
): TractoNotebookBase => {
    let content = unrecognizedContent;

    if (isExternalNotebookContent(content)) {
        content = mapExternalContentToV1(content);
    }

    return content;
};
