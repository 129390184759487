import {withDefaultPoolTree} from 'hoc/withDefaultPoolTree';
import React from 'react';
import {JupytSpecletDialog} from '../../../components/Dialogs/JupytSpecletDialog/JupytSpecletDialog';
import {useJupytSpecletDialogContainer} from './hooks/use-jupyt-speclet-dialog-container';
import type {JupytSpecletDialogContainerProps} from './types';
import {selectJupytDefaults} from '../../../store/selectors/jupyt';
import {useSelector} from 'react-redux';
import type {JupytSpecletOptions} from '../../../components/Dialogs/JupytSpecletDialog/types';
import type {DeepPartial, FormApi} from '@gravity-ui/dialog-fields';
import {prepareFields} from './helpers/fields';
import {useTractoDispatch} from '../../../../../store/tracto-dispatch';
import {createJupyt} from '../../../store/actions/jupyt/jupyt';
import {DEFAULT_JUPYT_POOL} from './constants/dialog-config';
import {applyWithValidation} from './helpers/apply-validator';

type JupytSpecletCreateDialogProps = JupytSpecletDialogContainerProps;

const JupytSpecletCreateDialogComponent: React.FC<JupytSpecletCreateDialogProps> = (
    props: JupytSpecletCreateDialogProps,
) => {
    const {dialogConfig, defaultPoolTree, onApply} = props;

    const dispatch = useTractoDispatch();

    const jupytDefaults = useSelector(selectJupytDefaults);

    const initialGeneralOptions: Pick<
        JupytSpecletOptions['general'],
        'pool_trees' | 'jupyter_docker_image' | 'secrets' | 'yt_token' | 'pool' | 'active'
    > = React.useMemo(() => {
        return {
            pool_trees: [defaultPoolTree],
            jupyter_docker_image: {image: jupytDefaults.default_kernel_image, option: 'registry'},
            secrets: [],
            yt_token: '',
            pool: DEFAULT_JUPYT_POOL,
            active: true,
        };
    }, []);

    const {visible, onClose, fields, initialValues, registry, username} =
        useJupytSpecletDialogContainer({
            dialogConfig,
            initialGeneralOptions,
            type: 'create',
        });

    const handleApply = React.useCallback(
        (form: FormApi<JupytSpecletOptions, DeepPartial<JupytSpecletOptions>>) => {
            const values = prepareFields(form.getState().values, {registry, username});

            return applyWithValidation(values.speclet_options, () =>
                dispatch(createJupyt(values))
                    .then(onClose)
                    .then(() => onApply()),
            );
        },
        [registry, username, onClose, onApply],
    );

    return (
        <JupytSpecletDialog
            title="Create kernel"
            visible={visible}
            onClose={onClose}
            fields={fields}
            onApply={handleApply}
            initialValues={initialValues}
        />
    );
};

export const JupytSpecletCreateDialogContainer = withDefaultPoolTree(
    JupytSpecletCreateDialogComponent,
);
