import Button from '@ytsaurus-ui-platform/src/ui/components/Button/Button';
import {Icon} from '@gravity-ui/uikit';
import {
    selectIsNotebookSaved,
    selectIsSavingInProgress,
    selectIsSolutionNotebook,
} from 'features/Jupyter/store/selectors/notebook';
import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Copy, FloppyDisk} from '@gravity-ui/icons';
import {usePreventUnload} from '@ytsaurus-ui-platform/src/ui/hooks/use-prevent-unload';
import {saveNotebookContent} from '../../store/actions/notebook/content';
import block from 'bem-cn-lite';

import './SaveNotebookButtonContainer.scss';
import {modalCopySolutionNotebookSlice} from 'features/Jupyter/store/slices/modals/modal-copy-solution-notebook';
import {useNavigationBlockerWithTabs} from '../../../../hooks/useNavigationBlockerWithTabs';
import {useHotkey} from 'hooks/useHotkey';
import {NotebookPageQa} from '../../../../../shared/qa';

const b = block('save-notebook-button');

type SaveNotebookButtonContainerProps = {};

export const SaveNotebookButtonContainer: React.FC<SaveNotebookButtonContainerProps> = () => {
    const dispatch = useDispatch();

    const isSolutionNotebook = useSelector(selectIsSolutionNotebook);

    const isNotebookSaved = useSelector(selectIsNotebookSaved);

    const isSavingInProgress = useSelector(selectIsSavingInProgress);

    const onSaveClick = useCallback(() => {
        dispatch(saveNotebookContent());
    }, []);

    const onCopyClick = useCallback(() => {
        dispatch(modalCopySolutionNotebookSlice.actions.open());
    }, []);

    const shouldPreventNavigation = !isNotebookSaved && !isSolutionNotebook;

    usePreventUnload({shouldListen: shouldPreventNavigation});

    useNavigationBlockerWithTabs(shouldPreventNavigation);

    useHotkey({
        keys: 'control+s,command+s',
        handler: (event) => {
            event.preventDefault();

            if (isSolutionNotebook) {
                dispatch(modalCopySolutionNotebookSlice.actions.open());
            } else {
                dispatch(saveNotebookContent());
            }
        },
    });

    if (isSolutionNotebook) {
        return (
            <Button view={'action'} className={b()} onClick={onCopyClick}>
                <Icon data={Copy} size={13} />
                Copy
            </Button>
        );
    }

    return (
        <Button
            disabled={isNotebookSaved}
            loading={isSavingInProgress}
            onClick={onSaveClick}
            className={b()}
            qa={NotebookPageQa.SaveNotebookButton}
        >
            <Icon data={FloppyDisk} size={13} />
            {isNotebookSaved ? 'Saved' : 'Save'}
        </Button>
    );
};
