import {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useTractoDispatch} from '../../../../../../store/tracto-dispatch';
import {modalJupytSpecletSlice} from '../../../../store/slices/modals/modal-jupyt-speclet';
import {selectModalJupytSpecletState} from '../../../../store/selectors/modals';
import {useHotkeysScope} from '../../../../../../../platform/packages/ui/src/ui/hooks/use-hotkeysjs-scope';
import type {StrawberryDescribeOptionsType} from '@ytsaurus-ui-platform/src/ui/utils/strawberryControllerApi';
import type {JupytSpecletOptions} from '../../../../components/Dialogs/JupytSpecletDialog/types';
import {mapDialogConfigToFields} from '../helpers/dialog-config';
import {getTractoRegistryUrl} from '../../../../../../store/selectors/tracto-cluster-ui-config';
import {getCurrentUserName} from '@ytsaurus-ui-platform/src/ui/store/selectors/global';
import type {JupytSpecletDialogContainerFieldFilters} from '../types';

type UseJupytSpecletDialogContainerArgs = {
    dialogConfig: StrawberryDescribeOptionsType;
    initialGeneralOptions: Partial<
        Pick<
            JupytSpecletOptions['general'],
            'pool_trees' | 'jupyter_docker_image' | 'secrets' | 'yt_token' | 'pool' | 'active'
        >
    >;
    type: 'create' | 'edit';
    fieldFilters?: JupytSpecletDialogContainerFieldFilters;
};

export const useJupytSpecletDialogContainer = (args: UseJupytSpecletDialogContainerArgs) => {
    const {dialogConfig, initialGeneralOptions, type, fieldFilters} = args;

    const dispatch = useTractoDispatch();

    const state = useSelector(selectModalJupytSpecletState);
    const registry = useSelector(getTractoRegistryUrl);
    const username = useSelector(getCurrentUserName);

    const {fields, initialValues} = useMemo(() => {
        return mapDialogConfigToFields(dialogConfig, initialGeneralOptions, {fieldFilters});
    }, [dialogConfig, initialGeneralOptions, fieldFilters]);

    useHotkeysScope('jupyt-speclet-dialog', state.visible);

    const handleClose = useCallback(() => {
        dispatch(modalJupytSpecletSlice.actions.close());
    }, []);

    return {
        onClose: handleClose,
        visible: state.visible && state.type === type,
        fields,
        initialValues,
        registry,
        username,
    };
};
