import React from 'react';
import cn from 'bem-cn-lite';
import {Loader} from '@gravity-ui/uikit';
import Yson from '@ytsaurus-ui-platform/src/ui/components/Yson/Yson';
import Icon from '@ytsaurus-ui-platform/src/ui/components/Icon/Icon';
import Error from '@ytsaurus-ui-platform/src/ui/components/Error/Error';
import Button from '@ytsaurus-ui-platform/src/ui/components/Button/Button';
import {UnipikaSettings} from '@ytsaurus-ui-platform/src/ui/components/Yson/StructuredYson/StructuredYsonTypes';
import './JupytPageOperationSpeclet.scss';
import {KernelPageQa} from '../../../../../../shared/qa';
import {JupytOperationState} from '../../../store/slices/jupytOperation';

const block = cn('jupyt-speclet');

interface JupytPageOperationSpecletProps {
    alias: string;
    speclet: JupytOperationState['speclet'];
    unipikaSettings: UnipikaSettings;
    showEditButton: boolean;
    onEditClick: () => void;
}

export const JupytPageOperationSpeclet = (props: JupytPageOperationSpecletProps) => {
    const isLoading = !props.speclet.data && !props.speclet.error;

    return (
        <React.Fragment>
            {isLoading ? (
                <div className={block('loader')}>
                    <Loader size="s" />
                </div>
            ) : (
                <React.Fragment>
                    {props.showEditButton ? (
                        <div className={block('edit')}>
                            <JupytSpecletEditButton onClick={props.onEditClick} />
                        </div>
                    ) : null}
                    {props.speclet.error ? (
                        <Error
                            className={block('raw-speclet-error')}
                            error={props.speclet.error}
                            bottomMargin
                        />
                    ) : (
                        <JupytSpeclet
                            speclet={props.speclet.data}
                            unipikaSettings={props.unipikaSettings}
                        />
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

function JupytSpeclet({
    unipikaSettings,
    speclet,
}: {
    speclet: unknown;
    unipikaSettings: UnipikaSettings;
}) {
    return (
        <div className={block()} data-qa={KernelPageQa.KernelPageSpecletInfo}>
            <Yson
                folding
                value={speclet}
                settings={unipikaSettings}
                className={block('raw-speclet')}
            />
        </div>
    );
}

interface JupytSpecletEditButtonProps {
    onClick: () => void;
}

export function JupytSpecletEditButton({onClick}: JupytSpecletEditButtonProps) {
    return (
        <Button title={'Edit speclet'} onClick={onClick} disabled={false}>
            <Icon awesome={'pencil'} />
            Edit speclet
        </Button>
    );
}
