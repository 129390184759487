import React from 'react';
import {useSelector} from 'react-redux';
import {selectNotebookCells} from '../../store/selectors/notebook';
import {getCellView} from '../../utils/cell/view';
import {NotebookCellType} from '../../constants/cell';
import {JupyterPythonCellContainer} from './JupyterPythonCellContainer';
import type {TractoNotebookCodeCell, TractoNotebookMarkdownCell} from '../../types/version';
import {JupyterMarkdownCellContainer} from './JupyterMarkdownCellContainer';
import {JupyterSQLCellContainer} from './JupyterSQLCellContainer';

const useJupyterCellContainer = (cellIndex: number) => {
    const cell = useSelector(selectNotebookCells)[cellIndex];

    return {cell, cellIndex};
};

type JupyterCellContainerProps = {
    cellIndex: number;
};

export const JupyterCellContainer: React.FC<JupyterCellContainerProps> = (props) => {
    const {cell, cellIndex} = useJupyterCellContainer(props.cellIndex);

    const view = getCellView(cell, 'common');

    switch (view.view_cell_type) {
        case NotebookCellType.CODE:
            return (
                <JupyterPythonCellContainer
                    cell={cell as TractoNotebookCodeCell}
                    cellIndex={cellIndex}
                />
            );
        case NotebookCellType.MARKDOWN:
            return (
                <JupyterMarkdownCellContainer
                    cell={cell as TractoNotebookMarkdownCell}
                    cellIndex={cellIndex}
                />
            );
        case NotebookCellType.CHYT:
        case NotebookCellType.QL:
        case NotebookCellType.SPYT:
        case NotebookCellType.YQL:
            return (
                <JupyterSQLCellContainer
                    cell={cell as TractoNotebookCodeCell}
                    cellIndex={cellIndex}
                />
            );
        default:
            return <div>Cannot render unknown cell</div>;
    }
};
