import {createSelector} from 'reselect';
import {QueryStatus} from '@reduxjs/toolkit/query';
import hammer from '@ytsaurus-ui-platform/src/ui/common/hammer';
import {
    MetricsEntry,
    MetricsEntryLeaf,
    MetricsList,
} from '@ytsaurus-ui-platform/src/ui/components/StatisticTable/types';

import {bytesToSize} from '../../utils';
import {getFlatListManifests} from './repositories';
import {FileNodeSerializable} from '../slices/inspector';
import type {TractoRootState} from '../../../../store/reducers';

export const getSelectedLayerIdx = (state: TractoRootState) =>
    state.tracto.tractoRegistry.inspector.selectedLayerIdx;

export const getSelectedManifestDigest = (state: TractoRootState) =>
    state.tracto.tractoRegistry.inspector.selectedDigest;

export const getSelectedManifest = createSelector(
    getSelectedManifestDigest,
    getFlatListManifests,
    (digest, manifests) => {
        return manifests.find((item) => item?.digest === digest);
    },
);

export const getImageInspectorLayers = createSelector(getSelectedManifest, (manifest) => {
    if (!manifest) {
        return [];
    }

    const layers = [...manifest.manifest.layers].reverse();

    return manifest.config.history.map((history) => {
        if (history.empty_layer) {
            return {
                history,
            };
        }

        const layer = layers.pop()!;

        return {
            history,
            layer,
            size: bytesToSize(layer.size),
        };
    });
});

export const getSelectedLayer = createSelector(
    getSelectedLayerIdx,
    getImageInspectorLayers,
    (idx, layers) => {
        if (layers && typeof idx !== 'undefined') {
            return layers[idx];
        }

        return null;
    },
);

export const getSelectedDigest = createSelector(
    getSelectedLayerIdx,
    getImageInspectorLayers,
    (idx = 0, layers) => {
        const digest = layers?.[idx]?.layer?.digest;

        if (digest) {
            const [_, res] = digest.split(':');

            return res;
        }

        return null;
    },
);

export const isFailedLoadingTree = (state: TractoRootState) => {
    const digest = getSelectedDigest(state);

    if (!digest) {
        return false;
    }

    return (
        state.tracto.tractoRegistry?.inspector?.layers?.[digest]?.status === QueryStatus.rejected
    );
};

export const isLoadingTree = (state: TractoRootState) => {
    const digest = getSelectedDigest(state);

    if (!digest) {
        return false;
    }

    return state.tracto.tractoRegistry?.inspector?.layers?.[digest]?.status === QueryStatus.pending;
};

export const isUncertainTree = (state: TractoRootState) => {
    const digest = getSelectedDigest(state);

    if (!digest) {
        return true;
    }

    return !state.tracto.tractoRegistry?.inspector?.layers?.[digest]?.status;
};

interface MetricsEntryDive extends MetricsEntry {
    value: {
        size: number;
    };
}

export function computeSize(node: FileNodeSerializable) {
    if (!node.data.isDir) {
        return node.data.size;
    }

    let total = 0;
    // eslint-disable-next-line guard-for-in
    for (const key in node.children) {
        total += computeSize(node.children[key]);
    }

    node.data.size = total;

    return total;
}

export function convertFileNodeToMetricsList(
    fileNode: FileNodeSerializable,
    list: MetricsList = {children: {}, leaves: {}},
    prefix = 'root',
): MetricsList {
    const currentPath = `${prefix}/${fileNode.name}`;

    if (Object.keys(fileNode.children).length > 0) {
        const directoryEntry: MetricsEntryDive = {
            name: fileNode.name,
            prefix,
            path: currentPath,
            // @ts-ignore
            value: {
                size: fileNode?.data?.size,
            },
        };
        list.children[currentPath] = directoryEntry;

        // eslint-disable-next-line guard-for-in
        for (const key in fileNode.children) {
            convertFileNodeToMetricsList(fileNode.children[key], list, currentPath);
        }
    } else {
        const metricsLeaf: MetricsEntryLeaf = {
            ...{name: fileNode.name, prefix, path: currentPath},
            // @ts-ignore
            value: {
                // @ts-ignore
                ...fileNode.data,
            },
        };

        list.leaves[currentPath] = metricsLeaf;
    }

    return list;
}

export const getParsedLayerTree = createSelector(
    getSelectedDigest,
    (state: TractoRootState) => {
        return state.tracto.tractoRegistry.inspector.layers;
    },
    (digest, layers) => {
        if (digest && layers) {
            const data = layers[digest]?.data as unknown as string;

            if (data) {
                return JSON.parse(data);
            }
        }

        return null;
    },
);

export const getLayerTree = createSelector(getParsedLayerTree, (nodes) => {
    if (!nodes) {
        return [];
    }

    nodes.data.isDir = true;

    computeSize(nodes);

    const metricsList = convertFileNodeToMetricsList(nodes, {
        children: {},
        leaves: {},
    });

    const metricsTree = hammer.treeList.prepareTree(
        metricsList.children,
        (entry: MetricsEntry) => entry.prefix,
    );

    if (Object.keys(metricsTree).length === 0) {
        return [];
    }

    const res = hammer.treeList.attachTreeLeaves(
        metricsTree,
        metricsList.leaves,
        (leafEntry: MetricsEntryLeaf) => leafEntry.prefix,
    );

    return hammer.treeList.flattenTree(res['root/']);
});
