import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import CancelHelper from '@ytsaurus-ui-platform/src/ui/utils/cancel-helper';

import {deleteImage} from './image';
import {DockerMediaType} from '../../types';

export const RegistryTagsCancelHelper = new CancelHelper();

export const fetchTags = createAsyncThunk(
    'tracto-registry/fetchTags',
    async ({
        cluster,
        image: {name, tags},
    }: {
        cluster: string;
        image: {name: string; tags: string[]};
    }) => {
        const cancelToken = RegistryTagsCancelHelper.removeAllAndGenerateNextToken();

        const getManifest = (tag: string) =>
            axios
                .get(`/${cluster}/tracto-registry/v2/${name}/manifests/${tag}`, {cancelToken})
                .then(async (res) => {
                    const digest = res?.headers?.['docker-content-digest'];

                    const manifest = res.data;

                    return {digest, manifest};
                });
        const getBlob = (digest: string) =>
            axios
                .get(`/${cluster}/tracto-registry/v2/${name}/blobs/${digest}`, {cancelToken})
                .then((res) => res.data);

        return Promise.all(
            tags.map(async (tag) => {
                const manifest = (await getManifest(tag)) as {
                    digest: string;
                    manifest: DockerManifest;
                };
                let manifests;

                if (
                    manifest.manifest.mediaType === DockerMediaType.OCI_INDEX &&
                    manifest.manifest.manifests
                ) {
                    const digests = manifest.manifest.manifests
                        .filter(
                            (item) =>
                                item?.annotations?.['vnd.docker.reference.type'] !==
                                AtterstationManifestAnnotations,
                        )
                        .map((item) => item.digest);

                    manifests = await Promise.all(
                        digests.map(async (digest) => {
                            const blob = await getBlob(digest);
                            const config = (await getBlob(blob.config.digest)) as DockerImageConfig;
                            return {
                                config,
                                manifest: blob,
                                digest: digest,
                            } as ManifestListItem;
                        }),
                    );
                } else {
                    manifests = [
                        {
                            digest: manifest.digest,
                            manifest: manifest.manifest,
                            config: manifest?.manifest.config?.digest
                                ? await getBlob(manifest.manifest.config.digest)
                                : {},
                        },
                    ] as ManifestListItem[];
                }

                return {tag, manifests};
            }),
        );
    },
);

export const AtterstationManifestAnnotations = 'attestation-manifest';

export interface DockerManifest {
    schemaVersion: number;
    mediaType: string;
    config: {
        mediaType: string;
        size: number;
        digest: string;
    };
    layers: {
        mediaType: string;
        size: number;
        digest: string;
    }[];
    manifests?: Array<{
        annotations?: {
            'vnd.docker.reference.digest'?: string;
            'vnd.docker.reference.type'?: 'attestation-manifest';
        };
        digest: string;
        mediaType: string;
        platform: {
            architecture: string;
            os: string;
        };
        size: number;
    }>;
}

export interface DockerImageConfig {
    architecture: string;
    variant?: string;
    config: {
        Env: string[];
        Cmd: string[];
        WorkingDir: string;
        Labels: {
            'org.opencontainers.image.created': string;
            'org.opencontainers.image.description': string;
            'org.opencontainers.image.licenses': string;
            'org.opencontainers.image.ref.name': string;
            'org.opencontainers.image.revision': string;
            'org.opencontainers.image.source': string;
            'org.opencontainers.image.title': string;
            'org.opencontainers.image.url': string;
            'org.opencontainers.image.version': string;
            maintainer: string;
        };
        ArgsEscaped: boolean;
    };
    created: string;
    history: {
        created: string;
        created_by: string;
        empty_layer?: boolean;
        comment?: string;
    }[];
    os: string;
    rootfs: {
        type: string;
        diff_ids: string[];
    };
}

export type ManifestListItem = {
    manifest: DockerManifest;
    config: DockerImageConfig;
    digest: string;
};

export interface TagsSliceState {
    tags: {
        tag: string;
        manifests: ManifestListItem[];
    }[];
    status: 'idle' | 'loading' | 'failed';
}

const initialState = {
    tags: [],
    status: 'idle',
} satisfies TagsSliceState as TagsSliceState;

export const tagsSlice = createSlice({
    name: 'tags',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTags.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchTags.fulfilled, (state, action) => {
                state.status = 'idle';
                state.tags = action.payload;
            })
            .addCase(fetchTags.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(deleteImage.fulfilled, (state, action) => {
                state.tags = state.tags.filter((tag) => tag.tag !== action.payload.tag);
            });
    },
});
