import YT from '@ytsaurus-ui-platform/src/ui/config/yt-config';

export const getBlueprintIdFromPath = (path: string) => {
    if (path.startsWith('//')) {
        path = path.replace('//', '');
    }

    if (path.startsWith('/')) {
        path = path.replace('/', '');
    }

    return path;
};
export const getBlueprintsBaseUrl = () => `/${YT.cluster}/image-builder/api/v1/blueprints`;
