import * as React from 'react';
import {useSelector} from 'react-redux';
import {getCluster} from '@ytsaurus-ui-platform/src/ui/store/selectors/global';

import {useTractoDispatch} from '../../../../store/tracto-dispatch';
import {getRepository, isImageTabLoading} from '../../store/selectors/repositories';
import {getTractoRegistryUrl} from '../../../../store/selectors/tracto-cluster-ui-config';
import {
    getImageInspectorLayers,
    getLayerTree,
    getSelectedDigest,
    getSelectedLayer,
    getSelectedLayerIdx,
    isFailedLoadingTree,
    isLoadingTree,
    isUncertainTree,
} from '../../store/selectors/inspector';
import {
    InspectorCancelHelper,
    fetchTree,
    inspectorSlice,
    retryFetchTree,
} from '../../store/slices/inspector';

const useTractoRegistryInspectorLayers = () => {
    const dispatch = useTractoDispatch();

    const repository = useSelector(getRepository);
    const tractoRegistryUrl = useSelector(getTractoRegistryUrl)!;

    const layers = useSelector(getImageInspectorLayers);
    const currentLayerIdx = useSelector(getSelectedLayerIdx)!;
    const isLoading = useSelector(isImageTabLoading);

    return {
        isLoading,
        layers,
        repository,
        tractoRegistryUrl,
        onClick: (idx: number) => dispatch(inspectorSlice.actions.selectLayerIdx({idx})),
        selectedIdx: currentLayerIdx,
    };
};

const useTractoRegistryInspectorTree = () => {
    const dispatch = useTractoDispatch();

    const repository = useSelector(getRepository);
    const cluster = useSelector(getCluster);

    const layer = useSelector(getSelectedLayer);
    const digest = useSelector(getSelectedDigest);
    const isLoading = useSelector(isLoadingTree);
    const isUncertain = useSelector(isUncertainTree);
    const isFailed = useSelector(isFailedLoadingTree);
    const tree = useSelector(getLayerTree);

    React.useEffect(() => {
        if (digest) {
            dispatch(
                fetchTree({
                    repo: repository,
                    cluster,
                    digest,
                }),
            );
        }

        return () => {
            InspectorCancelHelper.removeAllRequests();
        };
    }, [digest, cluster, repository]);

    const refresh = React.useCallback(() => {
        if (digest) {
            const params = {
                repo: repository,
                cluster,
                digest,
            };

            dispatch(retryFetchTree(params)).then(() => dispatch(fetchTree(params)));
        }
    }, [digest, cluster, repository]);

    const isEmpty = layer?.history?.empty_layer;

    return {
        tree,
        isEmpty,
        refresh,
        isFailed,
        isLoading,
        isUncertain,
    };
};

interface TractoRegistryInspectorLayersContainerProps {
    render: (props: {
        isLoading: boolean;
        layers: ReturnType<typeof getImageInspectorLayers>;
        selectedIdx?: number;
        onClick?: (index: number) => void;
    }) => React.ReactNode;
}

export const TractoRegistryInspectorLayersContainer = (
    props: TractoRegistryInspectorLayersContainerProps,
) => {
    const data = useTractoRegistryInspectorLayers();

    return props.render(data);
};

interface TractoRegistryInspectorTreeContainerProps {
    render: (props: {
        refresh: () => void;
        tree: any[];
        isEmpty?: boolean;
        isLoading: boolean;
        isUncertain: boolean;
        isFailed: boolean;
    }) => React.ReactNode;
}

export const TractoRegistryInspectorTreeContainer = (
    props: TractoRegistryInspectorTreeContainerProps,
) => {
    const data = useTractoRegistryInspectorTree();

    return props.render(data);
};
