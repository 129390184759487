import {createSelector} from '@reduxjs/toolkit';
import type {JupytSecretsMapType} from '../../../../../shared/types/jupyt/secrets';
import type {TractoRootState} from '../../../../store/reducers';
import {mapJupytSecretsToList} from '../../../../../shared/utils/jupyt/secrets';

export const selectJupyterSecrets = (state: TractoRootState): JupytSecretsMapType | null =>
    state.tracto.jupyter.secrets.secrets;

export const selectJupyterSecretsList = createSelector(selectJupyterSecrets, (secrets) =>
    mapJupytSecretsToList(secrets),
);
