import {useEffect, useRef} from 'react';
import type * as monaco from 'monaco-editor';
import {useJupyterCellHotkeys} from './use-jupyter-cell-hotkeys';
import type {TractoNotebookCell} from '../../../types/version';

type UseJupyterCellEffectsArgs = {
    isFocused: boolean;
    isEditable: boolean;
    cell: TractoNotebookCell;
    cellIndex: number;
};

export const useJupyterCellEffects = (args: UseJupyterCellEffectsArgs) => {
    const {isFocused, isEditable, cellIndex, cell} = args;

    const cellRef = useRef<HTMLDivElement>(null);
    const editorRef = useRef<monaco.editor.IStandaloneCodeEditor>();

    useJupyterCellHotkeys({cellRef, isEditable, isFocused, cellIndex, cell});

    useEffect(() => {
        if (isFocused && !isEditable) {
            cellRef.current?.focus({preventScroll: true});
        }
    }, [isFocused, isEditable]);

    useEffect(() => {
        if (isEditable) {
            editorRef?.current?.focus();
        }
    }, [isEditable]);

    return {cellRef, editorRef};
};
