import React from 'react';
import {JupyterCellControlsLayout} from '../../../Layout/JupyterCellControlsLayout/JupyterCellControlsLayout';
import {JupyterCellCommonControlsContainer} from '../../../../containers/JupyterCellCommonControlsContainer/JupyterCellCommonControlsContainer';
import {JupyterCellExecutionButtonContainer} from '../../../../containers/JupyterCellExecutionButtonContainer/JupyterCellExecutionButtonContainer';
import {useJupyterPythonCellDropdown} from '../hooks/useJupyterPythonCellDropdown';

type PythonCellControlsProps = {
    cellId: string;
    cellIndex: number;
    hasOutput: boolean;
    hiddenInput: boolean;
    hiddenOutput: boolean;
};

export const PythonCellControls: React.FC<PythonCellControlsProps> = React.memo(
    (props: PythonCellControlsProps) => {
        const {cellId, cellIndex, hiddenOutput, hasOutput, hiddenInput} = props;

        const dropdownItems = useJupyterPythonCellDropdown({
            cellId,
            options: {hiddenInput, hiddenOutput, hasOutput},
        });

        const renderLeftControls = React.useCallback(() => {
            return <JupyterCellExecutionButtonContainer cellId={cellId} />;
        }, [cellId]);

        const renderRightControls = React.useCallback(() => {
            return (
                <JupyterCellCommonControlsContainer
                    cellIndex={cellIndex}
                    dropdownItems={dropdownItems}
                />
            );
        }, [dropdownItems, cellIndex]);
        return (
            <JupyterCellControlsLayout
                renderLeftControls={renderLeftControls}
                renderRightControls={renderRightControls}
            />
        );
    },
);
