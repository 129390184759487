import React, {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getItemStrict} from '../../utils/strict-selectors';
import {selectCell} from '../../store/selectors/notebook';
import type {TractoRootState} from '../../../../store/reducers';
import {selectSQLTemplates} from '../../store/selectors/sql';
import {getCellSQLConfig} from '../../utils/cell/view';
import type {SQLViewTemplate} from '../../types/sql/view';
import {
    prepareChytSQLViewTemplate,
    prepareQLSQLViewTemplate,
    prepareSpytSQLViewTemplate,
    prepareYQLSQLViewTemplate,
} from './helpers/template';
import {updateSQLTemplateVariables} from '../../store/actions/notebook/cell-sql';
import {JupyterCellSQLControls} from '../../components/JupyterCellControls/JupyterCellSQLControls/JupyterCellSQLControls';
import {useCellControls} from '../../hooks/use-cell-controls';

const useSQLControls = (cellId: string) => {
    const dispatch = useDispatch();

    const cell = getItemStrict(useSelector((state: TractoRootState) => selectCell(state, cellId)));

    const templates = useSelector(selectSQLTemplates);

    const {onControlClick} = useCellControls({cellId});

    const sqlConfig = getItemStrict(getCellSQLConfig(cell));

    const template = useMemo((): SQLViewTemplate[] => {
        switch (sqlConfig.template_id) {
            case 'chyt':
                return prepareChytSQLViewTemplate(
                    templates[sqlConfig.template_id].variables,
                    sqlConfig.variables,
                );
            case 'ql':
                return prepareQLSQLViewTemplate(
                    templates[sqlConfig.template_id].variables,
                    sqlConfig.variables,
                );
            case 'spyt':
                return prepareSpytSQLViewTemplate(
                    templates[sqlConfig.template_id].variables,
                    sqlConfig.variables,
                );
            case 'yql':
                return prepareYQLSQLViewTemplate(
                    templates[sqlConfig.template_id].variables,
                    sqlConfig.variables,
                );
        }
    }, [sqlConfig, templates]);

    const onVariableChange = useCallback(
        (variable: string, value: string) => {
            dispatch(updateSQLTemplateVariables({cellId, variables: {[variable]: value}}));
        },
        [cellId],
    );

    return React.useMemo(() => {
        return {template, onVariableChange, onControlClick};
    }, [template, onVariableChange, onControlClick]);
};

type JupyterCellSQLControlContainerProps = {
    cellId: string;
};

export const JupyterCellSQLControlsContainer: React.FC<JupyterCellSQLControlContainerProps> = (
    props: JupyterCellSQLControlContainerProps,
) => {
    const {cellId} = props;

    return <JupyterCellSQLControls {...useSQLControls(cellId)} />;
};
