import React from 'react';
import {WorkflowEditorContainer} from 'features/Orchestracto/containers/WorkflowEditorContainer/WorkflowEditorContainer';
import {TractoFlexSplitPane} from 'components/TractoFlexSplitPane/TractoFlexSplitPane';
import {WorkflowGraphContainer} from '../../containers/WorkflowGraphContainer/WorkflowGraphContainer';

type WorkflowTabComponentProps = {};

export const WorkflowTabComponent: React.FC<WorkflowTabComponentProps> = () => {
    return (
        <TractoFlexSplitPane direction={TractoFlexSplitPane.HORIZONTAL}>
            <WorkflowEditorContainer />
            <WorkflowGraphContainer />
        </TractoFlexSplitPane>
    );
};
