import React from 'react';
import type * as monaco from 'monaco-editor';
import type {SQLTemplates} from '../../../../types/sql/temaplate';
import {MonacoLanguage} from '@ytsaurus-ui-platform/src/ui/constants/monaco';
import type {TractoNotebookCodeCell} from '../../../../types/version';
import {sourceToString} from '../../../../utils/cell/source';
import TractoMonacoEditor from '../../../../../../components/TractoMonacoEditor/TractoMonacoEditor';
import {JupyterCellContentLayout} from '../../../Layout/JupyterCellContentLayout/JupyterCellContentLayout';
import {JupyterExecutableCellContent} from '../../../JupyterExecutableCellContent/JupyterExecutableCellContent';
import {useCellExecution} from '../../../../hooks/use-cell-execution';
import {getCellView} from '../../../../utils/cell/view';

const getLanguageByEngine = (engine: keyof SQLTemplates | undefined): MonacoLanguage => {
    switch (engine) {
        case 'chyt':
            return MonacoLanguage.CHYT;
        case 'spyt':
            return MonacoLanguage.SPYT;
        case 'ql':
            return MonacoLanguage.YTQL;
        case 'yql':
        default:
            return MonacoLanguage.YQL;
    }
};

type SQLCellSourceProps = {
    editorRef: React.MutableRefObject<monaco.editor.IStandaloneCodeEditor | undefined>;
    onChange: (value: string) => void;
    onEditorFocus?: React.FocusEventHandler<HTMLDivElement>;
    cell: TractoNotebookCodeCell;
    isDirty?: boolean;
    isFocused?: boolean;
    isExecuted?: boolean;
    isEditable?: boolean;
    onEditorBlur?: () => void;
};

export const SQLCellSource: React.FC<SQLCellSourceProps> = (props: SQLCellSourceProps) => {
    const {
        editorRef,
        onChange,
        onEditorFocus,
        onEditorBlur,
        cell,
        isDirty,
        isFocused,
        isExecuted,
        isEditable,
    } = props;

    const {count} = useCellExecution({cell});

    const view = getCellView(cell, 'sql');

    const sqlConfig = view.sql;
    const source = sqlConfig.variables.query;

    const value = sourceToString(source);

    const renderHeaderContent = React.useCallback(() => {
        return (
            <JupyterExecutableCellContent
                count={count}
                isDirty={isDirty}
                isFocused={isFocused}
                isExecuted={isExecuted}
                isEditable={isEditable}
            />
        );
    }, [count, isFocused, isDirty, isExecuted, isEditable]);

    return (
        <JupyterCellContentLayout renderHeaderContent={renderHeaderContent}>
            <TractoMonacoEditor
                editorRef={editorRef}
                value={value}
                language={getLanguageByEngine(sqlConfig?.template_id)}
                onChange={onChange}
                onEditorFocus={onEditorFocus}
                onBlur={onEditorBlur}
            />
        </JupyterCellContentLayout>
    );
};
