import type {TractoNotebookCell, TractoNotebookContent} from '../../../types/version';
import {generateHashFromSource} from '../../../utils/cell/hash';
import {sourceToString} from '../../../utils/cell/source';
import cloneDeep from 'lodash/cloneDeep';

export const mapNotebookToSave = async (
    notebook: TractoNotebookContent,
): Promise<TractoNotebookContent> => {
    return {
        ...notebook,
        cells: await Promise.all(
            notebook.cells.map((cell: TractoNotebookCell) => {
                return generateHashFromSource(sourceToString(cell.source)).then((hash) => {
                    const copy = cloneDeep(cell);

                    if (copy.metadata.tracto) {
                        copy.metadata.tracto.source_hash = hash;
                    }

                    return copy;
                });
            }),
        ),
    };
};
