import {TractoThunkDispatch} from '../../../../../store/tracto-dispatch';
import {TractoRootState} from '../../../../../store/reducers';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';
import {WorkflowRunListItem} from '../../../types/runs';
import {OrchestractoApi} from '../../../api';
import type {WorkflowExecutionStatus} from '../../../types/workflow';
import {runsSlice} from '../../slices/runs';
import CancelHelper from '@ytsaurus-ui-platform/src/ui/utils/cancel-helper';
import type {RelativeRangeDatePickerValue} from '@gravity-ui/date-components';
import {selectRunsDateFilter, selectRunsLabels, selectRunsLimit} from '../../selectors/runs';
import {prepareRunsDateFilter} from '../../../utils/runs/filter';

const cancelHelper = new CancelHelper();

export const loadWorkflowRunsFormCypress = (params?: {
    limit?: string;
    filter?: RelativeRangeDatePickerValue;
    labels?: string[];
}) => {
    return async (dispatch: TractoThunkDispatch, getState: () => TractoRootState) => {
        const path = getPath(getState());
        const limit = params?.limit ?? selectRunsLimit(getState());
        const filter = params?.filter ?? selectRunsDateFilter(getState());
        const labels = params?.labels ?? selectRunsLabels(getState());

        const response = await OrchestractoApi.api.runs.listRuns({
            workflowPath: path,
            cancelToken: cancelHelper.removeAllAndGenerateNextToken(),
            limit,
            filter: prepareRunsDateFilter(filter),
            labels,
        });

        const runs: WorkflowRunListItem[] = response.runs.map((run): WorkflowRunListItem => {
            return {
                key: run.run_id,
                startTime: run.created_at,
                triggerType: run.trigger_type,
                status: run.stage as WorkflowExecutionStatus,
                labels: run.labels,
            };
        });

        dispatch(
            runsSlice.actions.setRuns({
                runs: runs.sort((a, b) => b.startTime.localeCompare(a.startTime)),
            }),
        );
    };
};
