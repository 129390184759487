import {WorkflowStepType} from 'features/Orchestracto/types/workflow';
import {createSelector} from 'reselect';
import {TractoRootState} from 'store/reducers';
import {isPersistentWorkflowRunStatus} from '../../utils/workflow';

export const selectCurrentRun = (state: TractoRootState) =>
    state.tracto.orchestracto.runs.currentRun;

export const selectCurrentRunWorkflowStepsStateMap = (state: TractoRootState) =>
    state.tracto.orchestracto.runs.currentRun?.workflowStepsStateMap;

export const selectRuns = (state: TractoRootState) => state.tracto.orchestracto.runs.runs;

export const selectCurrentRunLoading = (state: TractoRootState) =>
    state.tracto.orchestracto.runs.currentRunLoading;

export const selectIsRunsLoaded = (state: TractoRootState) =>
    state.tracto.orchestracto.runs.isRunsLoaded;

export const selectHasNonPersistentRunInList = createSelector(selectRuns, (runs) => {
    return runs.some((run) => !isPersistentWorkflowRunStatus(run.status));
});

export const selectCurrentRunStepsMap = createSelector([selectCurrentRun], (currentRun) => {
    let stepsMap: Record<string, WorkflowStepType> = {};

    const workflow = currentRun?.orcApiRun.workflow;

    if (workflow) {
        stepsMap = workflow.steps.reduce((acc: Record<string, WorkflowStepType>, step) => {
            acc[step.step_id] = step;
            return acc;
        }, {});
    }

    return stepsMap;
});

export const selectRunId = (state: TractoRootState) => state.tracto.orchestracto.runs.runId;

export const selectRunsDateFilter = (state: TractoRootState) =>
    state.tracto.orchestracto.runs.filter;

export const selectRunsLimit = (state: TractoRootState) => state.tracto.orchestracto.runs.limit;

export const selectRunsLabels = (state: TractoRootState) => state.tracto.orchestracto.runs.labels;

export const selectCurrentRunSelectedSteps = (state: TractoRootState) =>
    state.tracto.orchestracto.runs.currentRunSelectedSteps;

export const selectCurrentRunHasSelectedSteps = createSelector(
    selectCurrentRunSelectedSteps,
    (steps) => steps.length > 0,
);

export const selectCurrentRunDetails = (state: TractoRootState) => {
    const currentRun = selectCurrentRun(state);

    if (!currentRun) {
        return null;
    }

    const {labels, params} = currentRun.orcApiRun;

    return {labels, params};
};
