import React from 'react';
import {JupyterCellControlsLayout} from '../../../../Layout/JupyterCellControlsLayout/JupyterCellControlsLayout';
import {JupyterCellCommonControlsContainer} from '../../../../../containers/JupyterCellCommonControlsContainer/JupyterCellCommonControlsContainer';
import {useJupyterCellCommonDropdown} from '../../../../JupyterCellControls/JupyterCellDropdown/use-common-dropdown';
import block from 'bem-cn-lite';
import './MarkdownCellControls.scss';

type MarkdownCellControlsProps = {
    cellId: string;
    cellIndex: number;
    isFocused: boolean;
    className?: string;
};

const b = block('markdown-cell-controls');

export const MarkdownCellControls: React.FC<MarkdownCellControlsProps> = (
    props: MarkdownCellControlsProps,
) => {
    const {cellIndex, cellId, className, isFocused} = props;

    const dropdownItems = useJupyterCellCommonDropdown({cellId});

    const renderLeftControls = React.useCallback(() => null, []);

    const renderRightControls = React.useCallback(() => {
        return (
            <JupyterCellCommonControlsContainer
                cellIndex={cellIndex}
                dropdownItems={dropdownItems}
            />
        );
    }, [cellIndex, dropdownItems]);

    return (
        <JupyterCellControlsLayout
            className={b({focused: isFocused}, className)}
            renderLeftControls={renderLeftControls}
            renderRightControls={renderRightControls}
        />
    );
};
