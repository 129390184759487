import type {StrawberryDescribeOptionsType} from '@ytsaurus-ui-platform/src/ui/utils/strawberryControllerApi';
import type {JupytSpecletDialogField} from '../../../../components/Dialogs/JupytSpecletDialog/JupytSpecletDialog';
import type {JupytSpecletOptions} from '../../../../components/Dialogs/JupytSpecletDialog/types';

import type {Option} from '@ytsaurus-ui-platform/src/ui/components/Dialog/df-dialog-utils';
import {getDialogFieldFromOption} from '../../../../utils/df-dialog/prepare-describe-options';
import type {TractoDialogField} from '../../../../../../types/dialog-fields/fields';
import type {TractoOptionDescription} from '../../../../../../types/dialog-fields/description-options';
import type {DeepPartial} from '@gravity-ui/dialog-fields';
import {GENERAL_FIELDS} from '../constants/dialog-config';
import type {PartialInner} from '../../../../../../../shared/types/utility-types';
import {TractoLogger} from '../../../../../../utils/logging';
import type {JupytSpecletDialogContainerFieldFilters} from '../types';

const enum JupytSpecletDialogTab {
    General,
    Params,
    Resources,
}

const NANO_SECONDS = 1_000_000;

const ALLOWED_RESOURCE_NAMES = new Set(['gpu', 'cpu', 'memory']);
const ALLOWED_PARAM_NAMES = new Set(['enable_idle_suspension', 'idle_timeout']);

const extendGeneralInitialValues = (
    initialValues: Partial<JupytSpecletOptions['general']>,
    option: Option<any, any>,
) => {
    initialValues[option.name as keyof JupytSpecletOptions['general']] =
        option.current_value ?? option.default_value;
};

const extendParamsInitialValues = (
    initialValues: Partial<JupytSpecletOptions['params']>,
    option: Option<'duration', number> | Option<'bool', boolean>,
) => {
    switch (option.name) {
        case 'idle_timeout': {
            const value = Number(option.current_value ?? option.default_value);

            initialValues.idle_timeout = isNaN(value) ? undefined : value / NANO_SECONDS;
            break;
        }
        case 'enable_idle_suspension':
            initialValues.enable_idle_suspension = Boolean(
                option.current_value ?? option.default_value,
            );
            break;
        default:
            throw new Error(`Unsupported option name: ${option.name}`);
    }
};

const extendResourcesInitialValues = (
    initialValues: Partial<JupytSpecletOptions['resources']>,
    option: Option<'uint64' | 'int64' | 'byte_count', number>,
) => {
    initialValues[option.name as keyof JupytSpecletOptions['resources']] = {
        value: option.current_value ?? undefined,
    };
};

const extendInitialValues = (
    initialValues: PartialInner<JupytSpecletOptions>,
    option: Option<any, any>,
) => {
    switch (option.name) {
        case 'alias':
        case 'tree':
        case 'pool':
            extendGeneralInitialValues(initialValues.general, option);
            break;
        case 'pool_trees': {
            const optionValue = option.current_value || option.default_value;
            if (optionValue) {
                initialValues.general.pool_trees = optionValue;
            }
            break;
        }
        case 'idle_timeout':
        case 'enable_idle_suspension':
            extendParamsInitialValues(initialValues.params, option);
            break;
        case 'gpu':
        case 'cpu':
        case 'memory':
            extendResourcesInitialValues(initialValues.resources, option);
            break;
        default:
            TractoLogger.log(`Unsupported option name: ${option.name}`);
    }
};

const mapResourcesOptionsToFields = (
    options: TractoOptionDescription[],
    fieldFilters?: JupytSpecletDialogContainerFieldFilters['resources'],
): TractoDialogField<any>[] => {
    return options
        .filter((option): option is Option<'uint64' | 'int64' | 'byte_count', number> =>
            ALLOWED_RESOURCE_NAMES.has(option.name),
        )
        .filter((option) => !fieldFilters?.[option.name as keyof JupytSpecletOptions['resources']])
        .map(getDialogFieldFromOption);
};

const mapParamsOptionsToFields = (
    options: TractoOptionDescription[],
    fieldFilters?: JupytSpecletDialogContainerFieldFilters['params'],
): TractoDialogField<any>[] => {
    return options
        .filter((option): option is Option<'bool', boolean> | Option<'duration', number> =>
            ALLOWED_PARAM_NAMES.has(option.name),
        )
        .filter((option) => !fieldFilters?.[option.name as keyof JupytSpecletOptions['params']])
        .map(getDialogFieldFromOption);
};

const mapGeneralOptionsToFields = (
    _options: TractoOptionDescription[],
    fieldFilters?: JupytSpecletDialogContainerFieldFilters['general'],
): TractoDialogField<any>[] => {
    return GENERAL_FIELDS.filter(
        (field) => !fieldFilters?.[field.name as keyof JupytSpecletOptions['general']],
    );
};

const mapOptionsToFields = (
    options: TractoOptionDescription[],
    tab: number,
    fieldFilters?: JupytSpecletDialogContainerFieldFilters,
): TractoDialogField<any>[] => {
    switch (tab) {
        case JupytSpecletDialogTab.General:
            return mapGeneralOptionsToFields(options, fieldFilters?.general);
        case JupytSpecletDialogTab.Params:
            return mapParamsOptionsToFields(options, fieldFilters?.params);
        case JupytSpecletDialogTab.Resources:
            return mapResourcesOptionsToFields(options, fieldFilters?.resources);
        default:
            return [{type: 'block', name: `unknown_tab_${tab}`, extras: {children: 'Unknown tab'}}];
    }
};

const ORDER_TO_KEYS: (keyof JupytSpecletOptions)[] = ['general', 'params', 'resources'];

export const mapDialogConfigToFields = (
    config: StrawberryDescribeOptionsType,
    initialGeneralOptions: Partial<
        Pick<
            JupytSpecletOptions['general'],
            'pool_trees' | 'jupyter_docker_image' | 'secrets' | 'yt_token' | 'pool' | 'active'
        >
    >,
    {fieldFilters}: {fieldFilters?: JupytSpecletDialogContainerFieldFilters},
): {fields: JupytSpecletDialogField[]; initialValues: DeepPartial<JupytSpecletOptions>} => {
    const initialValues: PartialInner<JupytSpecletOptions> = {
        general: {...initialGeneralOptions},
        params: {},
        resources: {},
    };

    const fields = config.map((tab, index): JupytSpecletDialogField => {
        const {title, options} = tab;

        options.forEach((option) => {
            extendInitialValues(initialValues, option);
        });

        return {
            name: ORDER_TO_KEYS[index],
            title,
            type: 'tab-vertical',
            fields: mapOptionsToFields(
                options,
                index,
                fieldFilters,
            ) as JupytSpecletDialogField['fields'],
        };
    });

    return {fields, initialValues};
};
