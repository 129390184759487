import {useHotkey} from '../../../../../hooks/useHotkey';
import {notebookSlice} from '../../../store/slices/notebook';
import {addNotebookCell} from '../../../store/actions/notebook/cell';
import {NotebookCellType} from '../../../constants/cell';
import {executeFocusedCell} from '../../../store/actions/execute';
import {useTractoDispatch} from '../../../../../store/tracto-dispatch';
import type {TractoNotebookCell} from '../../../types/version';
import {type RefObject, useRef} from 'react';

type UseJupyterCellHotkeysArgs = {
    isFocused: boolean;
    isEditable: boolean;
    cellIndex: number;
    cell: TractoNotebookCell;
    cellRef: RefObject<HTMLDivElement>;
};

export const useJupyterCellHotkeys = (args: UseJupyterCellHotkeysArgs) => {
    const dispatch = useTractoDispatch();

    const {isFocused, isEditable, cellIndex, cell, cellRef} = args;

    useHotkey({
        keys: 'enter',
        handler: (event) => {
            if (isEditable || !isFocused) {
                return;
            }

            event.preventDefault();

            dispatch(notebookSlice.actions.makeCellEditable());
        },
    });

    //above
    useHotkey({
        keys: 'a',
        handler: (event) => {
            if (!isEditable && isFocused) {
                event.preventDefault();
                dispatch(
                    addNotebookCell({
                        currentIndex: cellIndex - 1,
                        type: NotebookCellType.CODE,
                        editable: false,
                        focus: false,
                    }),
                );
            }
        },
    });

    //below
    useHotkey({
        keys: 'b',
        handler: (event) => {
            if (!isEditable && isFocused) {
                event.preventDefault();
                dispatch(
                    addNotebookCell({
                        currentIndex: cellIndex,
                        type: NotebookCellType.CODE,
                        editable: false,
                        focus: false,
                    }),
                );
            }
        },
    });

    useHotkey({
        keys: 'c',
        handler: () => {
            if (isEditable || !isFocused) {
                return;
            }

            dispatch(
                notebookSlice.actions.setBufferCell({
                    cell,
                }),
            );
        },
    });

    useHotkey({
        keys: 'x',
        handler: () => {
            if (isEditable || !isFocused) {
                return;
            }

            dispatch(
                notebookSlice.actions.setBufferCell({
                    cell,
                }),
            );

            dispatch(notebookSlice.actions.deleteCell({currentIndex: cellIndex}));
        },
    });

    useHotkey({
        keys: 'v',
        handler: () => {
            if (isEditable || !isFocused) {
                return;
            }

            dispatch(
                notebookSlice.actions.pasteBufferCell({
                    currentIndex: cellIndex,
                }),
            );
        },
    });

    const lastDPress = useRef(0);

    useHotkey({
        keys: '*',
        handler: (event) => {
            if (isEditable || !isFocused) {
                return;
            }
            const isDKey = event.key === 'd' || event.key === 'D';

            if (!isDKey) {
                lastDPress.current = 0;
                return;
            }

            const now = Date.now();

            if (now - lastDPress.current <= 500) {
                dispatch(notebookSlice.actions.deleteCell({currentIndex: cellIndex}));
                lastDPress.current = 0;
            } else {
                lastDPress.current = now;
            }
        },
    });

    useHotkey({
        keys: 'shift+enter,control+enter,command+enter',
        handler: (event) => {
            if (!isFocused) {
                return;
            }

            event.preventDefault();

            dispatch(executeFocusedCell());
        },
    });

    useHotkey({
        keys: 'escape',
        handler: () => {
            if (!(isEditable && isFocused)) {
                return;
            }

            cellRef.current?.focus();

            dispatch(notebookSlice.actions.removeCellEditable());
        },
    });
};
