import React, {useCallback} from 'react';
import type {TractoNotebookCodeCell} from '../../types/version';
import {
    JupyterPythonCell,
    type JupyterPythonCellProps,
} from '../../components/JupyterCell/JupyterPythonCell/JupyterPythonCell';
import {useJupyterCell} from './hooks/use-jupyter-cell';
import {useJupyterExecutableCell} from './hooks/use-jupyter-executable-cell';
import {notebookSlice} from '../../store/slices/notebook';
import {useTractoDispatch} from '../../../../store/tracto-dispatch';
import type {OverlayType} from '../../components/JupyterCellOverlay/types';

const useJupyterPythonCellContainer = (
    cell: TractoNotebookCodeCell,
    cellIndex: number,
): JupyterPythonCellProps => {
    const dispatch = useTractoDispatch();

    const cellId = cell.id;

    const {isFocused, onSourceChange, isEditable, onClick, onEditorFocus, onEditorBlur} =
        useJupyterCell(cell);

    const {isDirty, isExecuted} = useJupyterExecutableCell(cell);

    const onShowClick = useCallback(
        (type: OverlayType) => {
            dispatch(
                notebookSlice.actions.setCellView({
                    cellId,
                    update:
                        type === 'code'
                            ? {type: 'python', payload: {hidden_input: false}}
                            : {type: 'python', payload: {hidden_output: false}},
                }),
            );
        },
        [cellId],
    );

    return {
        cell,
        index: cellIndex,
        isDirty,
        isEditable,
        isFocused,
        isExecuted,
        onClick,
        onEditorFocus,
        onSourceChange,
        onShowClick,
        onEditorBlur,
    };
};

type JupyterPythonCellContainerProps = {
    cell: TractoNotebookCodeCell;
    cellIndex: number;
};

export const JupyterPythonCellContainer: React.FC<JupyterPythonCellContainerProps> = (
    props: JupyterPythonCellContainerProps,
) => {
    const {cell, cellIndex} = props;

    return <JupyterPythonCell {...useJupyterPythonCellContainer(cell, cellIndex)} />;
};
