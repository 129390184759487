import * as React from 'react';
import block from 'bem-cn-lite';
import {Button, Flex, Loader, Select, Text} from '@gravity-ui/uikit';

import {useRegistryInspector} from '../../containers/useRegistryInspector';
import {TractoRegistryInspectorTree} from '../TractoRegistryInspectorTree/TractoRegistryInspectorTree';
import {
    TractoRegistryInspectorLayersContainer,
    TractoRegistryInspectorTreeContainer,
} from '../../containers/TractoRegistryInspectorContainer/TractoRegistryInspectorContainer';
import {TractoRegistryInspectorLayers} from '../TractoRegistryInspectorLayers/TractoRegistryInspectorLayers';

import './TractoRegistryInspectorTab.scss';

const b = block('tracto-registry-inspector-tab');

const LocalLoader = () => (
    <div className={b('loader')}>
        <Loader />
    </div>
);

export interface TractoRegistryTabProps {}

export const TractoRegistryInspectorTab: React.FunctionComponent<TractoRegistryTabProps> = () => {
    const registryInspector = useRegistryInspector();

    if (registryInspector.isLoading) {
        return (
            <div className={b()}>
                <LocalLoader />
            </div>
        );
    }

    return (
        <div className={b()}>
            <Select
                width="auto"
                label="Image tag"
                placeholder="Image tag"
                value={registryInspector.imageTagSelector.value}
                onUpdate={registryInspector.imageTagSelector.onChange}
                options={registryInspector.imageTagSelector.options}
            />
            <Flex direction="row" width="100%" gap={8}>
                <Flex grow="1" shrink="1" direction="column">
                    <TractoRegistryInspectorLayersContainer
                        render={({layers, selectedIdx, onClick, isLoading}) =>
                            isLoading ? (
                                <LocalLoader />
                            ) : (
                                <TractoRegistryInspectorLayers
                                    layers={layers}
                                    onClick={onClick}
                                    selectedIdx={selectedIdx}
                                />
                            )
                        }
                    />
                </Flex>
                <Flex grow="2" shrink="1" direction="column" className={b('tree-wrapper')}>
                    <TractoRegistryInspectorTreeContainer
                        render={(data) => {
                            if (data.isEmpty) {
                                return (
                                    <div className={b('refresh')}>
                                        This layer is empty. Please select a different one
                                    </div>
                                );
                            }

                            if (data.isUncertain) {
                                return null;
                            }

                            if (data.isLoading) {
                                return <LocalLoader />;
                            }

                            if (data.isFailed) {
                                return (
                                    <div className={b('refresh')}>
                                        <p>
                                            <Text>Something went wrong</Text>
                                        </p>
                                        <Button onClick={data.refresh}>Retry</Button>
                                    </div>
                                );
                            }

                            return <TractoRegistryInspectorTree tree={data.tree} />;
                        }}
                    />
                </Flex>
            </Flex>
        </div>
    );
};
