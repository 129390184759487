import * as React from 'react';
import block from 'bem-cn-lite';
import {Table, TableColumnConfig} from '@gravity-ui/uikit';

import './TractoRegistryInspectorLayers.scss';

const b = block('tracto-registry-inspector-layers');

type Col = {
    history: {
        created: string;
        created_by: string;
        empty_layer?: boolean | undefined;
        comment?: string | undefined;
    };
    layer?: {mediaType: string; size: number; digest: string};
    size?: number;
};

interface TractoRegistryImageLayersProps {
    layers: Col[];
    selectedIdx?: number;
    onClick?: (index: number) => void;
}

export const TractoRegistryInspectorLayers: React.FC<TractoRegistryImageLayersProps> = ({
    layers,
    onClick,
    selectedIdx,
}) => {
    const columns: TableColumnConfig<Col>[] = [
        {
            id: '№',
            template(_, idx) {
                return idx + 1;
            },
        },
        {
            id: 'Layer',
            name: 'Layers',
            template(col) {
                const hasHistory = Boolean(col.history?.created_by);

                return (
                    <span className={b('table-item-digest', {history: hasHistory})}>
                        <div className={b('table-item-command')}>{col.history.created_by}</div>
                    </span>
                );
            },
        },
        {
            id: 'size',
            name: 'Size',
        },
    ];

    return (
        <div className={b()}>
            <h3>Layers {layers ? `(${layers.length})` : ''}</h3>
            <div>
                <Table
                    getRowClassNames={(_, idx) => [
                        b('table-item-row', {selected: selectedIdx === idx}),
                    ]}
                    data={layers}
                    columns={columns}
                    onRowClick={(_, idx) => onClick?.(idx)}
                />
            </div>
        </div>
    );
};
