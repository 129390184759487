export const DESCRIBE_OPTIONS = {
    result: [
        {
            title: 'General',
            options: [],
            hidden: false,
        },
        {
            title: 'Jupyt params',
            options: [
                {
                    title: 'Enable idle timeout suspension',
                    name: 'enable_idle_suspension',
                    type: 'bool',
                    current_value: false,
                    default_value: false,
                    description:
                        'Jupyt operation will be suspended in case of no activity if enabled.',
                },
                {
                    title: 'Idle timeout',
                    name: 'idle_timeout',
                    type: 'duration',
                    current_value: null,
                    default_value: 86400000000000,
                    min_value: 0,
                    description:
                        'Jupyt operation will be suspended in case of no activity for the specified time.',
                },
            ],
            hidden: false,
        },
        {
            title: 'Resources',
            options: [
                {
                    title: 'CPU',
                    name: 'cpu',
                    type: 'int64',
                    current_value: null,
                    default_value: 2,
                    min_value: 1,
                    max_value: 100,
                    description: 'Number of CPU cores.',
                },
                {
                    title: 'Total memory',
                    name: 'memory',
                    type: 'byte_count',
                    current_value: null,
                    default_value: 8589934592,
                    min_value: 2147483648,
                    max_value: 322122547200,
                    description: 'Amount of RAM in bytes.',
                },
                {
                    title: 'GPU',
                    name: 'gpu',
                    type: 'int64',
                    current_value: null,
                    default_value: 0,
                    min_value: 0,
                    max_value: 100,
                    description: 'Number of GPU cards.',
                },
            ],
            hidden: false,
        },
    ],
};
