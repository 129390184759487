import {type PayloadAction, createSlice} from '@reduxjs/toolkit';

type ModalJupytSpecletInitialState = {
    visible: boolean;
    isCanceled: boolean;
    type: 'edit' | 'create' | undefined;
};

const initialState: ModalJupytSpecletInitialState = {
    visible: false,
    isCanceled: false,
    type: undefined,
};

export const modalJupytSpecletSlice = createSlice({
    name: 'jupyter.modal.jupyt-speclet',
    initialState,
    reducers: {
        open: (state, action: PayloadAction<{type: 'edit' | 'create'}>) => {
            state.visible = true;
            state.isCanceled = false;
            state.type = action.payload.type;
        },
        cancel: (state) => {
            state.visible = false;
            state.isCanceled = true;
            state.type = undefined;
        },
        close: (state) => {
            state.visible = false;
            state.type = undefined;
        },
    },
});
