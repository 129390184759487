import {createAsyncThunk} from '@reduxjs/toolkit';
import {getCluster} from '@ytsaurus-ui-platform/src/ui/store/selectors/global';
import {RootState} from '@ytsaurus-ui-platform/src/ui/store/reducers';
import {jupytApiAction} from '../../../api/jupyt';
import {isCancelled} from '@ytsaurus-ui-platform/src/ui/utils/cancel-helper';
import {isDeveloper} from '@ytsaurus-ui-platform/src/ui/store/selectors/global/is-developer';

export const jupytOperationLoad = createAsyncThunk(
    'jupyter.jupytOperation/JupytLoad',
    async (alias: string, thunkAPI) => {
        const state = thunkAPI.getState();
        const cluster = getCluster(state as RootState);
        const isAdmin = isDeveloper(state);

        return jupytApiAction<'get_brief_info'>(
            'get_brief_info',
            cluster,
            {alias},
            {
                isAdmin,
            },
        ).catch((error) => {
            if (!isCancelled(error)) {
                console.log('error', error);
                return thunkAPI.rejectWithValue(error);
            }

            return null;
        });
    },
);
