import {useSelector} from 'react-redux';
import {TractoRootState} from '../../../../../store/reducers';
import {selectDirtyCells} from '../../../store/selectors/notebook';
import {TractoNotebookCodeCell} from 'features/Jupyter/types/version';
import {selectSessionId} from 'features/Jupyter/store/selectors/jupyt';

export const useJupyterExecutableCell = (cell: TractoNotebookCodeCell) => {
    const isDirty = useSelector<TractoRootState, boolean>((state) =>
        Boolean(selectDirtyCells(state)[cell.id]),
    );

    const isExecuted = useSelector((state: TractoRootState) => {
        const sessionId = selectSessionId(state);

        return sessionId === cell.metadata.tracto.execution_session_id;
    });

    return {
        isDirty,
        isExecuted,
    };
};
