import axios from 'axios';
import {ytApiV4} from '@ytsaurus-ui-platform/src/ui/rum/rum-wrap-api';
import type {BlueprintType} from '../../types/blueprint';

import CancelHelper from '@ytsaurus-ui-platform/src/ui/utils/cancel-helper';
import {getBlueprintsBaseUrl} from '../../utils/blueprint';
import {DEFAULT_BLUEPRINT} from '../../constants/blueprint';

const cancelHelper = new CancelHelper();

export const getBlueprintNodeContent = (path: string) => {
    return ytApiV4.get<BlueprintType>({
        path,
        cancellation: cancelHelper.removeAllAndSave,
    });
};

export const getIsBlueprintCypressNode = (path: string) => {
    return ytApiV4.get<boolean>({
        path: `${path}/@is_blueprint`,
        cancellation: cancelHelper.removeAllAndSave,
    });
};

export const createBlueprint = (blueprint_id: string) => {
    return axios.put(
        `${getBlueprintsBaseUrl()}/${blueprint_id}`,
        {
            blueprint: DEFAULT_BLUEPRINT,
        },
        {cancelToken: cancelHelper.removeAllAndGenerateNextToken()},
    );
};
