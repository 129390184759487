import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import type {TractoRootState} from '../../../store/reducers';
import {selectEditableCell, selectFocusedCellId} from '../store/selectors/notebook';

type UseCellControlsArgs = {
    cellId: string;
};

export const useCellControls = (args: UseCellControlsArgs) => {
    const isEditable = useSelector<TractoRootState, boolean>(
        (state) => selectEditableCell(state) === args.cellId,
    );

    const isFocused = useSelector<TractoRootState, boolean>(
        (state) => selectFocusedCellId(state) === args.cellId,
    );

    const onControlClick = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            if (!isEditable && isFocused) {
                return;
            }

            event.stopPropagation();
        },
        [isEditable, isFocused],
    );

    return {onControlClick};
};
