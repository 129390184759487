import React from 'react';
import type {JupyterCellCommonProps} from '../types';
import {JupyterCellLayout} from '../../Layout/JupyterCellLayout/JupyterCellLayout';
import {getLayoutType} from '../../Layout/JupyterCellLayout/helpers';
import {MarkdownCellSource} from './elements/MarkdownCellSource/MarkdownCellSource';
import type {TractoNotebookMarkdownCell} from '../../../types/version';
import {MarkdownCellControls} from './elements/MarkdownCellControls/MarkdownCellControls';
import block from 'bem-cn-lite';
import {useJupyterCellEffects} from '../../../containers/JupyterCellContainer/hooks/use-jupyter-cell-effects';

import './JupyterMarkdownCell.scss';

export type JupyterMarkdownCellProps = JupyterCellCommonProps<TractoNotebookMarkdownCell> & {
    onDoubleClick: () => void;
    onImagePaste: (value: {name: string; type: string; base64: string}) => void;
};

const b = block('jupyter-markdown-cell');

export const JupyterMarkdownCell: React.FC<JupyterMarkdownCellProps> = (
    props: JupyterMarkdownCellProps,
) => {
    const {
        onDoubleClick,
        cell,
        index,
        onClick,
        isFocused,
        onEditorFocus,
        onSourceChange,
        onEditorBlur,
        onImagePaste,
        isEditable,
    } = props;

    const {cellRef, editorRef} = useJupyterCellEffects({
        isFocused,
        isEditable,
        cell,
        cellIndex: index,
    });

    const renderControls = React.useCallback(() => {
        return (
            <MarkdownCellControls
                cellId={cell.id}
                cellIndex={index}
                isFocused={isFocused}
                className={b('controls')}
            />
        );
    }, [cell.id, index, isFocused]);

    const renderSource = React.useCallback(() => {
        return (
            <MarkdownCellSource
                cell={cell}
                editorRef={editorRef}
                isEditable={isEditable}
                onMarkdownBlur={onEditorBlur}
                onSourceChange={onSourceChange}
                onEditorFocus={onEditorFocus}
                onImagePaste={onImagePaste}
            />
        );
    }, [cell, onEditorBlur, onSourceChange, onEditorFocus, onImagePaste, isEditable]);

    const renderOutput = React.useCallback(() => null, []);

    return (
        <JupyterCellLayout
            cellId={cell.id}
            layout={getLayoutType({isFocused, isEditable})}
            renderControls={renderControls}
            renderSource={renderSource}
            renderOutput={renderOutput}
            onClick={onClick}
            onDoubleClick={onDoubleClick}
            ref={cellRef}
            className={b({focused: isFocused})}
        />
    );
};
