import type {ExternalNotebookBase} from '../../../../types/version/external-notebook';
import type {V1NotebookMetadata} from '../../../../types/version/v1';
import {TractoJupyterNotebookMetadataVersion} from '../../../../constants/version';
import type {TractoNotebookBase} from '../../../../types/version';

export const mapExternalContentToV1 = (notebook: ExternalNotebookBase): TractoNotebookBase => {
    const tractoMetadata: V1NotebookMetadata['tracto'] = {
        is_solution_notebook:
            notebook.metadata.is_solution_notebook ??
            notebook.metadata.tracto?.is_solution_notebook ??
            false,
        notebook_cypress_id:
            notebook.metadata.notebook_cypress_id ??
            notebook.metadata.tracto?.notebook_cypress_id ??
            crypto.randomUUID(),
        metadata_version: TractoJupyterNotebookMetadataVersion.V1,
    };

    delete notebook.metadata.is_solution_notebook;

    delete notebook.metadata.notebook_cypress_id;

    delete notebook.tracto_notebook_content_version;

    return {
        ...notebook,
        metadata: {
            ...notebook.metadata,
            tracto: tractoMetadata,
        },
    };
};
