import {createAsyncThunk} from '@reduxjs/toolkit';
import {getCluster} from '@ytsaurus-ui-platform/src/ui/store/selectors/global';
import {RootState} from '@ytsaurus-ui-platform/src/ui/store/reducers';
import {
    type JupytListAttributes,
    type JupytListResponseItem,
    jupytApiAction,
} from '../../../api/jupyt';
import CancelHelper, {isCancelled} from '@ytsaurus-ui-platform/src/ui/utils/cancel-helper';
import {jupytSpecletLoad} from './speclet';
import {jupytOperationLoad} from './operation';
import {isDeveloper} from '@ytsaurus-ui-platform/src/ui/store/selectors/global/is-developer';

const cancelHelper = new CancelHelper();

export const jupytOptionsLoad = createAsyncThunk(
    'jupyter.jupytOperation/OptionsLoad',
    async (alias: string, thunkAPI) => {
        const state = thunkAPI.getState();
        const cluster = getCluster(state as RootState);
        const isAdmin = isDeveloper(state);

        return jupytApiAction(
            'describe_options',
            cluster,
            {alias},
            {
                isAdmin,
                cancelToken: cancelHelper.removeAllAndGenerateNextToken(),
            },
        )
            .then((data) => {
                return data;
            })
            .catch((error) => {
                if (!isCancelled(error)) {
                    console.log('error', error);

                    throw error;
                }
            });
    },
);
export const jupytOptionsEdit = createAsyncThunk<
    unknown,
    {
        alias: string;
        options: Required<JupytListResponseItem>['$attributes'];
    }
>('jupyter.jupytOperation/optionsEdit', async ({alias, options}, thunkAPI) => {
    const state = thunkAPI.getState();
    const cluster = getCluster(state as RootState);
    const isAdmin = isDeveloper(state);

    const options_to_remove: Array<JupytListAttributes> = [];
    const options_to_set: typeof options = {};

    Object.keys(options).forEach((k) => {
        const key = k as JupytListAttributes;
        if (options[key] === undefined) {
            options_to_remove.push(key);
        } else {
            options_to_set[key] = options[key] as any;
        }
    });

    return jupytApiAction(
        'edit_options',
        cluster,
        {alias, options_to_set, options_to_remove},
        {
            isAdmin,
        },
    ).then(() => {
        return Promise.all([
            thunkAPI.dispatch(jupytOptionsLoad(alias)),
            thunkAPI.dispatch(jupytSpecletLoad(alias)),
            thunkAPI.dispatch(jupytOperationLoad(alias)),
        ]);
    });
});
