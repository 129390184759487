import * as React from 'react';
import {useSelector} from 'react-redux';
import {getEditJsonYsonSettings} from '@ytsaurus-ui-platform/src/ui/store/selectors/thor/unipika';
import {UnipikaSettings} from '@ytsaurus-ui-platform/src/ui/components/Yson/StructuredYson/StructuredYsonTypes';

import {
    getJupytOperationOptions,
    getJupytOperationSpeclet,
} from '../../store/selectors/jupytOperation';
import {useTractoDispatch} from '../../../../store/tracto-dispatch';
import {JupytPageOperationSpeclet} from '../../components/JupytOperationPage/JupytPageOperationTabs/JupytPageOperationSpeclet';
import {jupytOptionsLoad} from '../../store/actions/jupyt/options';
import {jupytSpecletLoad} from '../../store/actions/jupyt/speclet';
import {modalJupytSpecletSlice} from '../../store/slices/modals/modal-jupyt-speclet';
import {JupytSpecletEditDialogContainer} from '../Dialogs/JupytSpecletDialogContainer/JupytSpecletEditDialogContainer';

const useSpecletData = ({alias}: {alias: string}) => {
    const dispatch = useTractoDispatch();

    React.useEffect(() => {
        dispatch(jupytSpecletLoad(alias)).then(() => {
            dispatch(jupytOptionsLoad(alias));
        });
    }, [alias]);

    const speclet = useSelector(getJupytOperationSpeclet);
    const options = useSelector(getJupytOperationOptions);
    const unipikaSettings = useSelector(getEditJsonYsonSettings) as UnipikaSettings;

    const onEditClick = React.useCallback(() => {
        dispatch(modalJupytSpecletSlice.actions.open({type: 'edit'}));
    }, []);

    return {
        speclet,
        options,
        unipikaSettings,
        onEditClick,
    };
};

export const JupytPageOperationSpecletContainer = ({alias}: {alias: string}) => {
    const {speclet, unipikaSettings, options, onEditClick} = useSpecletData({
        alias,
    });

    return (
        <>
            <JupytPageOperationSpeclet
                alias={alias}
                showEditButton={Boolean(options.data)}
                speclet={speclet}
                onEditClick={onEditClick}
                unipikaSettings={unipikaSettings}
            />
            {speclet.data && options.data && (
                <JupytSpecletEditDialogContainer
                    alias={alias}
                    speclet={speclet.data}
                    dialogConfig={options.data}
                    onApply={() => {}}
                />
            )}
        </>
    );
};
