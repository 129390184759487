import {combineSlices} from '@reduxjs/toolkit';
import {tagsSlice} from './tags';
import {imageSlice} from './image';
import {inspectorSlice} from './inspector';

export const tractoRegistryRoot = combineSlices({
    image: imageSlice.reducer,
    tags: tagsSlice.reducer,
    inspector: inspectorSlice.reducer,
});
