import React, {useMemo} from 'react';
import {Copy} from '@gravity-ui/icons';

import type {BoundDropdownWithHotkeysItem} from '../../../../../libs/hotkeys';
import type {JUPYTER_HOTKEYS} from '../../../constants/hotkeys';
import {CopyToClipboard} from '@gravity-ui/uikit';
import type {TractoNotebookCell} from '../../../types/version';
import {getCellLink} from 'features/Jupyter/utils/cell/anchor';

export const useJupyterCellCommonDropdown = ({
    cellId,
}: {
    cellId: TractoNotebookCell['id'];
}): BoundDropdownWithHotkeysItem<typeof JUPYTER_HOTKEYS>[] => {
    return useMemo((): BoundDropdownWithHotkeysItem<typeof JUPYTER_HOTKEYS>[] => {
        return [
            {
                group: true,
                label: 'Collaboration',
                items: [
                    {
                        id: 'copy-link',
                        text: 'Copy link to cell',
                        iconStart: <Copy />,
                        wrapper: (node) => (
                            <CopyToClipboard text={getCellLink(cellId)}>
                                {() => node}
                            </CopyToClipboard>
                        ),
                    },
                ],
            },
        ];
    }, [cellId]);
};
