import {TractoRootState} from 'store/reducers';
import {getTractoClusterUiConfig} from 'store/selectors/tracto-cluster-ui-config';

export const selectDelegationsList = (state: TractoRootState) =>
    state.tracto.secretStore.delegations.delegations;

export const selectshowAddDelegationDialog = (state: TractoRootState) =>
    state.tracto.secretStore.delegations.showAddDelegationDialog;

export const selectDelegationToDelete = (state: TractoRootState) =>
    state.tracto.secretStore.delegations.delegationToDelete;

export const selectIsDelegationAvailable = (state: TractoRootState) =>
    Boolean(getTractoClusterUiConfig(state).secret_service_base_url);
