import React, {useCallback, useEffect, useState} from 'react';
import {ExecuteButton} from '../../components/JupyterCellControls/controls/ExecuteButton/ExecuteButton';
import type {TractoRootState} from 'store/reducers';
import {useDispatch, useSelector} from 'react-redux';
import {selectCell, selectCellsExecutionStatus} from 'features/Jupyter/store/selectors/notebook';
import {extractCellId} from 'features/Jupyter/utils/cell/common';
import {withFocusBeforeCellAction} from 'features/Jupyter/utils/notebook';
import * as nbformat from '@jupyterlab/nbformat';
import {executeCellByClick, interruptExecution} from '../../store/actions/execute';
import {getItemStrict} from 'features/Jupyter/utils/strict-selectors';
import {JupyterCellQa} from '../../../../../shared/qa';
import moment from 'moment';
import type {TractoNotebookCodeCell} from 'features/Jupyter/types/version';
import {getCellView} from '../../utils/cell/view';
import {useJupyterExecutableCell} from 'features/Jupyter/containers/JupyterCellContainer/hooks/use-jupyter-executable-cell';
import {useCellControls} from '../../hooks/use-cell-controls';

type JupyterCellExecutionButtonContainerProps = {
    cellId: string;
};

export const JupyterCellExecutionButtonContainer: React.FC<
    JupyterCellExecutionButtonContainerProps
> = ({cellId}) => {
    const dispatch = useDispatch();
    const [currentExecitionTime, setCurrentExecutionTime] = useState(0);

    const cell: TractoNotebookCodeCell = getItemStrict(
        useSelector((state: TractoRootState) => selectCell(state, cellId)),
    ) as TractoNotebookCodeCell;
    const {execution_start, execution_end} = getCellView(cell, 'executable');

    const {onControlClick} = useCellControls({cellId});

    const onRunClick = useCallback(
        withFocusBeforeCellAction(
            dispatch,
            extractCellId(cell),
            (event: React.MouseEvent<HTMLElement>) => {
                onControlClick(event);

                if (nbformat.isCode(cell)) {
                    dispatch(executeCellByClick({cell}));
                }

                return undefined;
            },
        ),
        [cell, onControlClick],
    );

    const onCancelClick = useCallback(
        withFocusBeforeCellAction(dispatch, cellId, (event: React.MouseEvent<HTMLElement>) => {
            onControlClick(event);

            dispatch(interruptExecution());
        }),
        [cellId, onControlClick],
    );

    const isQueued = useSelector<TractoRootState, boolean>(
        (state) => selectCellsExecutionStatus(state)[cellId] === 'queued',
    );

    const isRunning = useSelector<TractoRootState, boolean>(
        (state) => selectCellsExecutionStatus(state)[cellId] === 'running',
    );

    const {isExecuted} = useJupyterExecutableCell(cell);

    useEffect(() => {
        if (!isRunning) {
            return;
        }

        const intervalId = setInterval(() => {
            setCurrentExecutionTime(Date.now());
        }, 100);

        return () => {
            clearInterval(intervalId);
        };
    }, [isRunning]);

    let status = '';

    if (isQueued && !isRunning) {
        status = 'queued';
    } else if (execution_start) {
        const executionEnd = execution_end || currentExecitionTime || execution_start;
        const executionTime = executionEnd - execution_start;
        status = formatExecutionTime(executionTime < 0 ? 0 : executionTime);
    }

    return (
        <ExecuteButton
            qa={JupyterCellQa.JupyterCellExecuteButton}
            onRunClick={onRunClick}
            onCancelClick={onCancelClick}
            isQueued={isQueued}
            isRunning={isRunning}
            isExecuted={isExecuted}
            status={status}
        />
    );
};

function formatExecutionTime(executionTime: number): string {
    const duration = moment.duration(executionTime, 'milliseconds');

    const minutes = Math.floor(duration.asMinutes());
    const seconds = duration.seconds();
    const milliseconds = duration.milliseconds();

    const formattedSeconds = (seconds + milliseconds / 1000).toFixed(1);

    let elapsedTime = '';

    elapsedTime = `${formattedSeconds}s`;

    if (minutes) {
        elapsedTime = `${minutes}m ${elapsedTime}`;
    }

    return elapsedTime;
}
