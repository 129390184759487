import axios, {CancelToken} from 'axios';
import {QueryStatus} from '@reduxjs/toolkit/query';
import {type PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import CancelHelper from '@ytsaurus-ui-platform/src/ui/utils/cancel-helper';
import {delay} from '@ytsaurus-ui-platform/src/ui/packages/delays';

import {TagsSliceState, fetchTags} from './tags';

export const InspectorCancelHelper = new CancelHelper();

type FetchTreeParams = {digest: string; repo: string; cluster: string};

export enum InspectorProcessStatus {
    UNKNOWN = 'UNKNOWN',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
}

class ProcessingFailedError extends Error {}

const getProcessingTreeStatus = async (params: FetchTreeParams, cancelToken: CancelToken) => {
    return axios
        .get<{processing_status: string}>(
            `/${params.cluster}/tracto-registry/api/inspector/${params.repo}/layer/${params.digest}/status`,
            {
                cancelToken,
            },
        )
        .then((response) => response.data)
        .then((data) => data.processing_status);
};

export const retryFetchTree = createAsyncThunk(
    'tracto-registry/retryFetchTree',
    async (params: FetchTreeParams, thunkAPI) => {
        await axios
            .post<{processing_status: string}>(
                `/${params.cluster}/tracto-registry/api/inspector/${params.repo}/layer/${params.digest}/actions/process?force=true`,
            )
            .then((response) => response.data)
            .catch((error) => thunkAPI.rejectWithValue(error));
    },
);

export const fetchTree = createAsyncThunk(
    'tracto-registry/fetchTree',
    async (params: FetchTreeParams, thunkAPI) => {
        const cancelToken = InspectorCancelHelper.removeAllAndGenerateNextToken();
        const status = await getProcessingTreeStatus(params, cancelToken);

        if (status === InspectorProcessStatus.FAILED) {
            return thunkAPI.rejectWithValue(new ProcessingFailedError());
        }

        if (status === InspectorProcessStatus.UNKNOWN) {
            await axios
                .post<{processing_status: string}>(
                    `/${params.cluster}/tracto-registry/api/inspector/${params.repo}/layer/${params.digest}/actions/process`,
                    {
                        cancelToken,
                    },
                )
                .then((response) => response.data);

            let flag = true;

            while (flag) {
                try {
                    const status = await getProcessingTreeStatus(params, cancelToken);

                    if (status === InspectorProcessStatus.COMPLETED) {
                        break;
                    }

                    if (status === InspectorProcessStatus.FAILED) {
                        return thunkAPI.rejectWithValue(new ProcessingFailedError());
                    }

                    await delay(1000);
                } catch (err) {
                    flag = false;

                    return thunkAPI.rejectWithValue(err);
                }
            }
        }

        return axios
            .get<ImageTree>(
                `/${params.cluster}/tracto-registry/api/inspector/${params.repo}/layer/${params.digest}/data`,
                {
                    cancelToken,
                    transformResponse: (data) => data,
                },
            )
            .then((response) => response.data as ImageTree)
            .catch((error) => thunkAPI.rejectWithValue(error));
    },
);

export interface ImageTree {
    layer: Layer[];
    image: Image;
    node: FileNodeSerializable[];
}

interface Layer {
    index: number;
    id: string;
    digestId: string;
    sizeBytes: number;
    command: string;
}

interface Image {
    inefficientFiles: FileReference[];
    sizeBytes: number;
    efficiencyScore: number;
    inefficientBytes: number;
}

interface FileReference {
    references: number;
    sizeBytes: number;
    path: string;
}

export interface FileNodeSerializable {
    size: number;
    name: string;
    data: NodeData;
    children: {[key: string]: FileNodeSerializable};
}

type NodeData = FileInfo & DiffType;

interface FileInfo {
    name: string;
    size: number;
    isDir: boolean;
}

enum DiffType {}

type LayerState = {
    data?: ImageTree;
    status: string;
};

const initialState = {
    layers: undefined,
    selectedDigest: undefined,
    selectedLayerIdx: 0,
} as {
    selectedDigest?: string;
    selectedLayerIdx?: number;
    layers?: Record<string, LayerState>;
};

export const inspectorSlice = createSlice({
    name: 'inspector',
    initialState: initialState,
    reducers: {
        selectDigest(state, action: PayloadAction<{digest: string}>) {
            state.selectedDigest = action.payload.digest;
        },
        selectLayerIdx(state, action: PayloadAction<{idx: number}>) {
            state.selectedLayerIdx = action.payload.idx;
        },
        reset(state) {
            state.selectedLayerIdx = 0;
            state.selectedDigest = undefined;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                fetchTags.fulfilled,
                (state, action: PayloadAction<TagsSliceState['tags']>) => {
                    state.selectedDigest = action.payload?.[0].manifests?.[0]?.digest;
                },
            )
            .addCase(fetchTree.pending, (state, action) => {
                state.layers = {
                    ...state.layers,
                    [action.meta.arg.digest]: {
                        status: QueryStatus.pending,
                    },
                };
            })
            .addCase(fetchTree.fulfilled, (state, action) => {
                state.layers = {
                    ...state.layers,
                    [action.meta.arg.digest]: {
                        status: QueryStatus.fulfilled,
                        data: action.payload,
                    },
                };
            })
            .addCase(fetchTree.rejected, (state, action) => {
                state.layers = {
                    ...state.layers,
                    [action.meta.arg.digest]: {
                        status: QueryStatus.rejected,
                        data: undefined,
                    },
                };
            });
    },
});
