import block from 'bem-cn-lite';
import React from 'react';

import './JupyterExecutableCellContent.scss';
import {JupyterCellQa} from '../../../../../shared/qa';

type JupyterExecutableCellContentProps = {
    count: string | null;
    isDirty?: boolean;
    isFocused?: boolean;
    isExecuted?: boolean;
    isEditable?: boolean;
};

const b = block('jupyter-executable-cell-content');

export const JupyterExecutableCellContent: React.FC<JupyterExecutableCellContentProps> = (
    props: JupyterExecutableCellContentProps,
) => {
    const {isDirty, count, isFocused, isExecuted, isEditable} = props;
    return (
        <pre
            data-qa={JupyterCellQa.JupyterCellExecutableContent}
            className={b({
                focused: isFocused,
                dirty: isDirty,
                executed: isExecuted,
                editable: isEditable,
            })}
        >
            {count}
        </pre>
    );
};
