import React from 'react';
import block from 'bem-cn-lite';
import './JupyterCellControlsLayout.scss';

type JupyterCellControlsLayoutProps = {
    className?: string;
    renderLeftControls: () => React.JSX.Element | null;
    renderRightControls: () => React.JSX.Element | null;
};

const b = block('jupyter-cell-controls-layout');

export const JupyterCellControlsLayout: React.FC<JupyterCellControlsLayoutProps> = (
    props: JupyterCellControlsLayoutProps,
) => {
    const {className, renderLeftControls, renderRightControls} = props;

    return (
        <div className={b(null, className)}>
            <div className={b('left-controls')}>{renderLeftControls()}</div>
            <div className={b('right-controls')}>{renderRightControls()}</div>
        </div>
    );
};
