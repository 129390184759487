import React from 'react';
import {Flex} from '@gravity-ui/uikit';
import {JupyterCellExecutionButtonContainer} from '../../../../containers/JupyterCellExecutionButtonContainer/JupyterCellExecutionButtonContainer';
import {JupyterCellSQLControlsContainer} from '../../../../containers/JupyterCellSQLControlsContainer/JupyterCellSQLControlsContainer';

type SQLCellLeftControlsProps = {
    cellId: string;
};

export const SQLCellLeftControls: React.FC<SQLCellLeftControlsProps> = (
    props: SQLCellLeftControlsProps,
) => {
    const {cellId} = props;

    return (
        <Flex gap="2">
            <JupyterCellExecutionButtonContainer cellId={cellId} />
            <JupyterCellSQLControlsContainer cellId={cellId} />
        </Flex>
    );
};
