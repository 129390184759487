import moment from 'moment/moment';
import {createSelector} from 'reselect';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';
import type {TractoRootState} from '../../../../store/reducers';
import {
    bytesToSize,
    getBlobConfig,
    modifySpecificAttributeTypes,
    parseCreatedBy,
} from '../../utils';
import {ManifestListItem} from '../slices/tags';

export const getFlatListManifests = (state: TractoRootState) => {
    return Object.values(state.tracto.tractoRegistry.tags?.tags).reduce((ret, item) => {
        return ret.concat(item?.manifests ?? []);
    }, [] as ManifestListItem[]);
};

export const getDigestParams = (state: TractoRootState) => {
    return state.tracto.tractoRegistry.image.searchParams.digest ?? '';
};

export const getManifest = createSelector(
    getDigestParams,
    getFlatListManifests,
    (digest, manifests) => {
        return manifests.find((item) => item?.digest === digest);
    },
);

export const getSelectedTag = createSelector(
    getDigestParams,
    (state: TractoRootState) => {
        return state.tracto.tractoRegistry.tags?.tags;
    },
    (digest, tags) => {
        return tags.find((item) => item.manifests.find((item) => item?.digest === digest))?.tag;
    },
);

export const getDockerFile = createSelector(getManifest, (data) => {
    return data?.config?.history?.map((item) => parseCreatedBy(item.created_by));
});

export const getTractoRegistryBlobConfig = createSelector(getManifest, (data) => {
    if (data?.config) {
        return Object.entries(getBlobConfig(data.config)).map(([key, value]) =>
            modifySpecificAttributeTypes(key, value),
        );
    }

    return [];
});

export const getTagsTableData = (state: TractoRootState) => {
    return state.tracto.tractoRegistry.tags.tags
        .map((item) => {
            const manifests = item.manifests.map((item) => {
                const size = item.manifest.layers?.reduce((acc, e) => {
                    return acc + e.size;
                }, 0);

                return {
                    size: bytesToSize(size),
                    shortDigest: item?.digest?.substring(7, 19),
                    digest: item?.digest,
                    osArch: `${item?.config?.os}/${item?.config?.architecture}`,
                };
            });

            return {
                tag: item.tag,
                manifests,
                created: item.manifests[0].config.created,
            };
        })
        .sort((a, b) => moment(b.created).diff(moment(a.created)));
};

export const getLayersTableData = createSelector(getManifest, (data) => {
    if (!data) {
        return [];
    }
    const {config, manifest} = data;

    const history = config.history.filter((item) => !item.empty_layer);

    return manifest.layers.map((layer, idx) => {
        return {
            digest: layer.digest,
            size: bytesToSize(layer.size),
            history: history[idx],
        };
    });
});

export const isImageTabLoading = (state: TractoRootState) =>
    state.tracto.tractoRegistry.tags.status === 'loading' ||
    state.tracto.tractoRegistry.image.status === 'loading';

export const isImageLoading = (state: TractoRootState) =>
    state.tracto.tractoRegistry.image.status === 'loading';

export const hasPublicAccess = (state: TractoRootState) => {
    return state.tracto.tractoRegistry.image.allowAnonymousPull;
};

export const getRepository = (state: TractoRootState) => {
    const path = getPath(state) as string;

    return path
        .split('/')
        .filter(Boolean)
        .filter((segment) => segment !== '_tags')
        .join('/');
};

export const getTags = (state: TractoRootState) => {
    return state.tracto.tractoRegistry?.tags?.tags;
};
