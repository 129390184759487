export const getLayoutType = ({
    isEditable,
    isFocused,
    isExecuted,
}: {
    isEditable?: boolean;
    isFocused?: boolean;
    isExecuted?: boolean;
}): 'editable' | 'focused' | 'default' | 'executed' => {
    if (isEditable) {
        return 'editable';
    }

    if (isFocused) {
        return 'focused';
    }

    if (isExecuted) {
        return 'executed';
    }

    return 'default';
};
