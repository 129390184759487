import type {
    ChytSQLTemplate,
    QLSQLTemplate,
    SpytSQLTemplate,
    YQLSQLTemplate,
} from '../../types/sql/temaplate';
import type {TractoNotebookCellSQLVariables} from '../../types/version';

export const getSQLTemplatesPath = () => {
    return '//sys/jupyt/sql_templates';
};

export const prepareTemplateVariables = <
    T extends
        | ChytSQLTemplate['variables']
        | SpytSQLTemplate['variables']
        | QLSQLTemplate['variables']
        | YQLSQLTemplate['variables'],
>(
    variables: T,
) => {
    return Object.keys(variables).reduce((acc, key: string) => {
        const variable = variables[key as keyof T] as {default_value: string | undefined};

        acc[key] = typeof variable.default_value === 'undefined' ? '' : variable.default_value;

        return acc;
    }, {} as TractoNotebookCellSQLVariables);
};
