import React, {useCallback, useEffect, useState} from 'react';
import {GraphComponent} from 'features/Orchestracto/components/GraphComponent/GraphComponent';
import {
    ECanChangeBlockGeometry,
    Graph,
    GraphBlock,
    GraphState,
    type TBlock,
    type TConnection,
    TGraphSettingsConfig,
    useGraph,
    useGraphEvent,
} from '@gravity-ui/graph';
import {Flex, Loader, useThemeValue} from '@gravity-ui/uikit';
import {getGraphColors} from '../../utils/theme';
import {WorkflowBlock, type WorkflowTBlock} from '../../libs/graph/canvas/WorkflowBlock';
import {workflowConfig} from '../../libs/graph/config';
import type {GraphCustomizableEvents} from './type';
import {useCustomizableEvents} from './hooks/useCustomizableEvents';

type GraphConfig<T extends TBlock> = {
    blocks: T[];
    connections: TConnection[];
};

type GraphContainerProps<T extends TBlock> = {
    getData: () => Promise<GraphConfig<T>>;
    events?: GraphCustomizableEvents;
};

export const GraphContainer = <T extends TBlock>({getData, events}: GraphContainerProps<T>) => {
    const theme = useThemeValue();

    const {graph, setEntities, start, zoomTo} = useGraph(workflowConfig);
    const [error, setError] = useState<Error | null>(null);
    const [inited, setInited] = useState(false);

    useCustomizableEvents(graph, events);

    useGraphEvent(graph, 'mousedown', (_, event) => {
        // Preventing these events will prevent delegate this event to the target component.
        // So preventing behavior implemented on the components, in that case we prevening drag and drop.
        event.preventDefault();
    });

    useGraphEvent(graph, 'state-change', ({state}) => {
        if (state === GraphState.ATTACHED) {
            start();
        }
    });

    useEffect(() => {
        getData()
            .then(({blocks, connections}) => {
                setEntities({blocks, connections});
                setError(null);
                setInited(true);
            })
            .catch((e) => setError(e));
    }, [getData]);

    useEffect(() => {
        zoomTo('center');
    }, [inited]);

    useEffect(() => {
        const settings: Partial<TGraphSettingsConfig> = {
            canChangeBlockGeometry: ECanChangeBlockGeometry.ALL,
        };

        if (graph) {
            graph.updateSettings(settings);
            graph.api.updateGraphColors(getGraphColors());
        }
    }, [graph, theme]);

    const renderBlock = useCallback((graph: Graph, block: WorkflowTBlock) => {
        const view = graph.rootStore.blocksList.getBlockState(block.id)?.getViewComponent();

        if (view instanceof WorkflowBlock) {
            return view.renderHTML();
        }

        return (
            <GraphBlock graph={graph} block={block}>
                Unknown block <>{block.id}</>
            </GraphBlock>
        );
    }, []);

    if (!inited) {
        return (
            <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
                <Loader />
            </Flex>
        );
    }

    return <GraphComponent graph={graph} error={error} renderBlock={renderBlock} />;
};
