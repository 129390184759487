import React from 'react';
import {Text} from '@gravity-ui/uikit';

export const GENERAL_FIELDS = [
    {
        name: 'alias',
        type: 'text',
        caption: 'Kernel alias',
        required: true,
        validator: (name: string): string | undefined => {
            if (/^[A-Za-z][\w-]*$/.test(name)) {
                return undefined;
            }

            return 'Alias does not match regular expression "^[A-Za-z][\\w-]*$"';
        },
    },
    {
        name: 'jupyter_docker_image',
        type: 'docker-image-input',
        caption: 'Docker image',
        required: true,
    },
    {
        name: 'pool_trees',
        type: 'pool-tree',
        caption: 'Pool trees',
    },
    {
        name: 'pool',
        type: 'pool',
        caption: 'Pool',
        required: true,
        extras: (args: {general: {pool_trees: any}}) => {
            return {
                poolTrees: args.general.pool_trees,
                placeholder: 'Pool name...',
            };
        },
    },
    {
        name: 'yt_token',
        type: 'yt-token-input',
        caption: 'YT Token',
        extras: () => {
            return {
                description: `YT token generated for kernel`,
            };
        },
    },
    {
        name: 'secrets',
        type: 'jupyter-secrets',
        caption: 'Secrets',
    } as any,
    {
        name: 'pool_notice',
        type: 'block',
        visibilityCondition: {
            when: 'general.pool',
            isActive(pool: boolean) {
                return !pool;
            },
        },
        extras: {
            children: (
                <Text color="info-heavy" variant="body-2">
                    Select a pool to start the jupyt after creation.
                </Text>
            ),
        },
    },
    {
        name: 'active',
        type: 'tumbler',
        caption: 'Start after creation',
        visibilityCondition: {
            when: 'general.pool',
            isActive(v: any) {
                return Boolean(v);
            },
        },
    },
];

export const DEFAULT_JUPYT_POOL = 'research';
