import React from 'react';
import block from 'bem-cn-lite';
import FlexSplitPane from '@ytsaurus-ui-platform/src/ui/components/FlexSplitPane/FlexSplitPane';
import './TractoFlexSplitPane.scss';

const b = block('tracto-flex-split-pane');

type TractoFlexSplitPaneProps = {
    className?: string;
    direction: string;
    children: React.ReactNode;
};

export const TractoFlexSplitPane: React.FC<TractoFlexSplitPaneProps> & {
    HORIZONTAL: string;
    VERTICAL: string;
} = (props) => {
    const {className, ...restProps} = props;
    return <FlexSplitPane className={b(null, className)} {...restProps} />;
};

TractoFlexSplitPane.HORIZONTAL = FlexSplitPane.HORIZONTAL;

TractoFlexSplitPane.VERTICAL = FlexSplitPane.VERTICAL;
