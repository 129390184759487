const encoder = new TextEncoder();

export async function generateHashFromSource(source: string) {
    const uintSource = encoder.encode(source);
    const hashBuffer = await crypto.subtle.digest('SHA-256', uintSource);

    // Convert the hash to a hexadecimal string
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');

    // Return the first 8 characters of the hash
    return hashHex.slice(0, 8);
}
