import React, {useCallback, useEffect, useState} from 'react';
import {JupytOperationsList} from 'features/Jupyter/components/JupytOperationsList/JupytOperationsList';
import {useTractoDispatch} from '../../../../store/tracto-dispatch';
import {loadNotebookJupytList, resumeJupyt} from '../../store/actions/jupyt/jupyt';
import {useSelector} from 'react-redux';
import {
    checkIfCurrentJupytSuspended,
    selectCellsExecutionStatus,
    selectCurrentJupytAlias,
    selectEditableCell,
} from 'features/Jupyter/store/selectors/notebook';
import {
    executeNotebookWithRequiredJupyt,
    interruptExecution,
    restartKernel,
} from 'features/Jupyter/store/actions/execute';
import {getCluster} from '@ytsaurus-ui-platform/src/ui/store/selectors/global/index';
import isEmpty from 'lodash/isEmpty';
import {TractoRootState} from 'store/reducers';
import {JUPYT_OPERATIONS_PAGE_ID} from 'features/Jupyter/constants';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';
import {getNotebookDownloadUrl} from 'features/Jupyter/api/kernel/content-manager';
import {hotkeysSlice} from '../../../../libs/hotkeys';
import {useHotkey} from '../../../../hooks/useHotkey';

export const JupytOperationsListContainer = () => {
    const [isSuspendedInFlight, setSuspendedFlight] = useState(false);
    const dispatch = useTractoDispatch();

    const cluster = useSelector(getCluster);
    const hasEditableCell = useSelector(selectEditableCell);

    const currentJupytAlias = useSelector(selectCurrentJupytAlias);
    const isCurrentJupytSuspended = useSelector(checkIfCurrentJupytSuspended);

    const path = useSelector(getPath);

    const jupytOperationsLink = `/${cluster}/${JUPYT_OPERATIONS_PAGE_ID}`;

    const onRunNotebookClick = useCallback(() => {
        dispatch(executeNotebookWithRequiredJupyt());
    }, []);

    const onStopNotebookClick = useCallback(() => {
        dispatch(interruptExecution());
    }, []);

    const onResumeKernelClick = useCallback(() => {
        dispatch(
            resumeJupyt({
                alias: currentJupytAlias,
            }),
        ).then(() => {
            setSuspendedFlight(true);
            dispatch(loadNotebookJupytList());
        });
    }, [currentJupytAlias]);

    const isNotebookRunning = useSelector((state: TractoRootState) =>
        isEmpty(selectCellsExecutionStatus(state)),
    );

    const [notebookDownloadUrl, setNotebookDownloadUrl] = useState('');

    useEffect(() => {
        getNotebookDownloadUrl({path, cluster}).then((url) => {
            setNotebookDownloadUrl(url);
        });
    }, [path]);

    const onRestartKernelClick = useCallback(() => {
        dispatch(restartKernel());
    }, []);

    const handleHelpClick = useCallback(() => {
        dispatch(hotkeysSlice.actions.open());
    }, []);

    const handleHelpHotkey = useCallback(() => {
        if (!hasEditableCell) {
            dispatch(hotkeysSlice.actions.open());
        }
    }, [hasEditableCell]);

    useHotkey({
        handler: handleHelpHotkey,
        keys: 'shift+?,shift+/',
    });

    useEffect(() => {
        setSuspendedFlight(!isCurrentJupytSuspended);
    }, [isCurrentJupytSuspended]);

    return (
        <JupytOperationsList
            path={path}
            isSuspendedInFlight={isSuspendedInFlight}
            isCurrentJupytSuspended={isCurrentJupytSuspended}
            notebookDownloadUrl={notebookDownloadUrl}
            isNotebookRunning={isNotebookRunning}
            currentJupytAlias={currentJupytAlias}
            onRunNotebookClick={onRunNotebookClick}
            onStopNotebookClick={onStopNotebookClick}
            onResumeKernelClick={onResumeKernelClick}
            jupytOperationsLink={jupytOperationsLink}
            onRestartKernelClick={onRestartKernelClick}
            onHelpClick={handleHelpClick}
        />
    );
};
