import {WorkflowDocumentType} from 'features/Orchestracto/types/workflow';
import {createSelector} from 'reselect';
import {TractoRootState} from 'store/reducers';

export const selectWorkflow = (state: TractoRootState) =>
    state.tracto.orchestracto.workflow.workflow;

export const selectParsedWorkflow = createSelector([selectWorkflow], (workflow) => {
    if (!workflow.trim()) {
        return null;
    }

    try {
        return JSON.parse(workflow) as unknown as WorkflowDocumentType;
    } catch {
        return null;
    }
});

const selectMarkers = (state: TractoRootState) => state.tracto.orchestracto.workflow.markers;

export const selectIsWorkflowValid = createSelector(
    [selectWorkflow, selectMarkers],
    (workflow, markers) => {
        const isNotEmpty = workflow.trim() !== '';
        const hasNoMarkers = markers.length === 0;

        return isNotEmpty && hasNoMarkers;
    },
);

export const selectIsWorkflowChanged = (state: TractoRootState) =>
    state.tracto.orchestracto.workflow.workflow !==
    state.tracto.orchestracto.workflow.initialWorkflow;

export const selectIsWorkflowLoaded = (state: TractoRootState) =>
    state.tracto.orchestracto.workflow.isWorkflowLoaded;

export const selectIsWorkflowRunning = (state: TractoRootState) =>
    state.tracto.orchestracto.workflow.running;

export const selectIsSavingInProgress = (state: TractoRootState) =>
    state.tracto.orchestracto.workflow.savingInProgress;

export const selectIsOneTimeRunDialogVisible = (state: TractoRootState) =>
    state.tracto.orchestracto.workflow.oneTimeRunDialogVisible;
