import React from 'react';
import {JupyterCellLayout} from '../../Layout/JupyterCellLayout/JupyterCellLayout';
import {SQLCellControls} from './elements/SQLCellControls';
import {getLayoutType} from '../../Layout/JupyterCellLayout/helpers';
import {SQLCellSource} from './elements/SQLCellSource';
import {JupyterCellOutput} from '../../JupyterCellOutput/JupyterCellOutput';
import type {JupyterCellCommonProps} from '../types';
import type {TractoNotebookCodeCell} from '../../../types/version';
import {useJupyterCellEffects} from '../../../containers/JupyterCellContainer/hooks/use-jupyter-cell-effects';

export type JupyterSQLCellProps = JupyterCellCommonProps<TractoNotebookCodeCell> & {
    isDirty: boolean;
    isExecuted: boolean;
};

export const JupyterSQLCell: React.FC<JupyterSQLCellProps> = (props: JupyterSQLCellProps) => {
    const {
        cell,
        index,
        isFocused,
        isDirty,
        isEditable,
        onSourceChange,
        onClick,
        onEditorFocus,
        isExecuted,
        onEditorBlur,
    } = props;

    const {cellRef, editorRef} = useJupyterCellEffects({
        isEditable,
        isFocused,
        cell,
        cellIndex: index,
    });

    const renderControls = React.useCallback(() => {
        return <SQLCellControls cellId={cell.id} cellIndex={index} />;
    }, [cell.id, index]);

    const renderSource = React.useCallback(() => {
        return (
            <SQLCellSource
                isDirty={isDirty}
                isFocused={isFocused}
                isExecuted={isExecuted}
                isEditable={isEditable}
                editorRef={editorRef}
                onEditorFocus={onEditorFocus}
                onChange={onSourceChange}
                cell={cell}
                onEditorBlur={onEditorBlur}
            />
        );
    }, [
        cell,
        onSourceChange,
        isFocused,
        isDirty,
        onEditorFocus,
        isExecuted,
        isEditable,
        onEditorBlur,
    ]);

    const renderOutput = React.useCallback(() => {
        return cell.outputs.length ? <JupyterCellOutput outputs={cell.outputs} /> : null;
    }, [cell.outputs]);

    return (
        <JupyterCellLayout
            cellId={cell.id}
            layout={getLayoutType({isFocused, isEditable, isExecuted})}
            renderControls={renderControls}
            renderSource={renderSource}
            renderOutput={renderOutput}
            ref={cellRef}
            onClick={onClick}
        />
    );
};
