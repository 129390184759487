import {FormApi, YTDFDialog} from '@ytsaurus-ui-platform/src/ui/components/Dialog';
import {
    dispatchWorkflowActionWithValidation,
    userRunOneTimeRunWorkflow,
} from 'features/Orchestracto/store/actions/workflow';
import {selectIsOneTimeRunDialogVisible} from 'features/Orchestracto/store/selectors/workflow';
import {workflowSlice} from 'features/Orchestracto/store/slices/workflow';
import React, {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useTractoDispatch} from 'store/tracto-dispatch';

type FormValues = {
    params: string;
    labels: {name: string}[];
};

export const OneTimeRunDialog: React.FC = () => {
    const dispatch = useTractoDispatch();

    const visible = useSelector(selectIsOneTimeRunDialogVisible);

    const onClose = useCallback(() => {
        dispatch(workflowSlice.actions.hideOneTimeRunDialog());
    }, []);

    const onAdd = async (form: FormApi<FormValues>) => {
        const {params, labels} = form.getState().values;

        const action = () =>
            dispatch(
                userRunOneTimeRunWorkflow({
                    params: params.trim() ? JSON.parse(params) : undefined,
                    labels: labels.map((label) => label.name),
                }),
            )
                .unwrap()
                .finally(() => {
                    onClose();
                    dispatch(workflowSlice.actions.setRunning({running: false}));
                });

        await dispatch(dispatchWorkflowActionWithValidation(action));
    };

    const isApplyDisabled = useMemo(
        () => (form: {values: FormValues}) => {
            const validationError = paramsValidator(form.values.params);
            return Boolean(validationError);
        },
        [],
    );

    return (
        <YTDFDialog<FormValues>
            pristineSubmittable={true}
            visible={visible}
            headerProps={{title: 'Start one-time run'}}
            onClose={onClose}
            fields={[
                {
                    type: 'textarea',
                    name: 'params',
                    caption: 'Params',
                    extras: {
                        placeholder: 'Optional params for one-time run in JSON format',
                    },
                    validator: paramsValidator,
                },
                {
                    //@ts-expect-error
                    // Tracto multi text input is not defined in opensource list of DialogField.
                    // Since we have not copied the dialog and types, we expect this error.
                    type: 'tracto-multi-text',
                    name: 'labels',
                    caption: 'Labels',
                    extras: {
                        placeholder: 'Optional label for one-time run',
                    },
                },
            ]}
            initialValues={{
                labels: [],
                params: '',
            }}
            onAdd={onAdd}
            isApplyDisabled={isApplyDisabled}
        />
    );
};

function paramsValidator(v: string | undefined) {
    if (!v || v.trim() === '') {
        return;
    }

    try {
        JSON.parse(v);
    } catch (error: any) {
        return String(error);
    }

    return;
}
