import moment from 'moment/moment';
import {DockerImageConfig} from './store/slices/tags';
import hammer from '@ytsaurus-ui-platform/src/ui/common/hammer';

export const modifySpecificAttributeTypes = function (key: string, value: any) {
    switch (key) {
        case 'created':
            return {key, value: moment(value).format('DD/MM/YYYY HH:mm:ss')};
        case 'created_by': {
            return {
                key,
                ...parseCreatedBy(value),
            };
        }
        case 'size':
            return {key, value: bytesToSize(value)};
        case 'Entrypoint':
        case 'Cmd':
            return {key, value: (value || []).join(' ')};
        case 'Labels':
            return {
                key,
                value: Object.keys(value || {}).map(function (elt) {
                    return value[elt] ? elt + '=' + value[elt] : '';
                }),
            };
        case 'Volumes':
        case 'ExposedPorts':
            return {key, value: Object.keys(value)};
    }

    return {key, value: value || ''};
};

export const getBlobConfig = function (blobs: DockerImageConfig) {
    const res = [
        'architecture',
        'User',
        'created',
        'docker_version',
        'os',
        'Cmd',
        'Entrypoint',
        'Env',
        'Labels',
        'User',
        'Volumes',
        'WorkingDir',
        'author',
        'id',
        'ExposedPorts',
        'name',
        'appVersion',
        'kubeVersion',
        'keywords',
        'home',
        'sources',
    ].reduce(
        (acc, e) => {
            const value =
                blobs[e as keyof DockerImageConfig] ||
                blobs?.config?.[e as keyof DockerImageConfig['config']];
            if (value && e === 'architecture' && blobs.variant) {
                acc[e] = value + blobs.variant;
            } else if (value) {
                acc[e] = value;
            }
            return acc;
        },
        {} as Record<string, unknown>,
    );

    if (blobs.config?.Labels?.maintainer) {
        res.author = blobs.config?.Labels?.maintainer;
    }

    return res;
};

export const parseCreatedBy = (value: string) => {
    if (value.startsWith('COPY')) {
        return {
            value: 'COPY',
            content: value.replace(/^COPY /, ''),
        };
    }
    const cmd = value.match(/\/bin\/sh *-c *#\(nop\) *([A-Z]+) (.*)/);

    return {
        value: (cmd && cmd[1]) || 'RUN',
        content: (cmd && cmd[2]) || value.replace(/^\/bin\/sh *-c *(#\(nop\))?/, ''),
    };
};

export function bytesToSize(bytes: number) {
    // eslint-disable-next-line new-cap
    return hammer.format['Bytes'](bytes).toString();
}

export function modeToString(mode: number): string {
    const FILE_TYPE = {
        0o040000: 'd', // Directory
        0o100000: '-', // Regular file
        0o120000: 'l', // Symbolic link
        0o020000: 'c', // Character device
        0o060000: 'b', // Block device
        0o010000: 'p', // FIFO pipe
        0o140000: 's', // Socket
    };

    // @ts-ignore
    // eslint-disable-next-line no-bitwise
    const fileTypeMask = mode & 0o170000;
    // @ts-ignore
    const type = FILE_TYPE[fileTypeMask] || '-'; // Default to regular file if unknown

    const PERMISSIONS = ['---', '--x', '-w-', '-wx', 'r--', 'r-x', 'rw-', 'rwx'];

    // @ts-ignore
    // eslint-disable-next-line no-bitwise
    const owner = PERMISSIONS[(mode >> 6) & 0o7];
    // @ts-ignore
    // eslint-disable-next-line no-bitwise
    const group = PERMISSIONS[(mode >> 3) & 0o7];
    // @ts-ignore
    // eslint-disable-next-line no-bitwise
    const others = PERMISSIONS[mode & 0o7];

    return type + owner + group + others;
}
