import React from 'react';

import {useSelector} from 'react-redux';
import {YTDFDialog} from '@ytsaurus-ui-platform/src/ui/components/Dialog';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';
import {updateView} from '@ytsaurus-ui-platform/src/ui/store/actions/navigation';
import {useTractoDispatch} from '../../../../store/tracto-dispatch';
import {modalCreateDocumentSlice} from '../../store/slices/modals/modal-create-document';
import {selectModalCreateDocumentState} from '../../store/selectors/modals/modal-create-document';
import {createDocument} from '../../store/actions/document';
import {normalizePath} from 'utils/path';

type FormValues = {
    documentPath: string;
    documentName: string;
};

export const DocumentCreateDialog = () => {
    const dispatch = useTractoDispatch();
    const state = useSelector(selectModalCreateDocumentState);
    const path = useSelector(getPath);

    const handleIsApplyDisabled = () => {
        return false;
    };

    return (
        <YTDFDialog<FormValues>
            visible={state.documentType === 'document'}
            headerProps={{title: 'Create document'}}
            onAdd={(args) => {
                const {documentPath, documentName} = args.getState().values;

                const path = `${normalizePath(documentPath)}${documentName}`;

                return dispatch(createDocument({path}))
                    .then(({payload}) => {
                        return payload
                            ? dispatch(modalCreateDocumentSlice.actions.close())
                            : Promise.reject();
                    })
                    .then(() => dispatch(updateView()));
            }}
            onClose={() => {
                dispatch(modalCreateDocumentSlice.actions.close());
            }}
            fields={[
                {
                    name: 'documentName',
                    type: 'text',
                    caption: 'Name',
                    initialValue: `new_document`,
                    required: true,
                    extras: {
                        autoFocus: true,
                    },
                },
                {
                    name: 'documentPath',
                    type: 'path',
                    caption: 'Directory',
                    initialValue: `${path}/`,
                    required: true,
                },
            ]}
            isApplyDisabled={handleIsApplyDisabled}
        />
    );
};
