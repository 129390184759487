import React from 'react';
import {TractoFlexSplitPane} from 'components/TractoFlexSplitPane/TractoFlexSplitPane';
import {WorkflowRunsListContainer} from 'features/Orchestracto/containers/WorkflowRunsListContainer/WorkflowRunsListContainer';
import {WorkflowExecutionGraphContainer} from '../../containers/WorkflowExecutionGraphContainer/WorkflowExecutionGraphContainer';

export const WorkflowRunsTabComponent: React.FC = () => {
    return (
        <TractoFlexSplitPane direction={TractoFlexSplitPane.HORIZONTAL}>
            <WorkflowRunsListContainer />
            <WorkflowExecutionGraphContainer />
        </TractoFlexSplitPane>
    );
};
